var _a;
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Drawer, SwipeableDrawer, IconButton, Box } from '@material-ui/core';
import MaximizeIcon from '@material-ui/icons/ChevronRight';
import MinimizeIcon from '@material-ui/icons/ChevronLeft';
import { store } from 'app';
import { useLayout } from 'hooks';
import { getLayout } from 'hooks/useLayout';
import Profile from './Profile';
import NavList from './NavList';
import styles from './styles.module.scss';
let leftbarOpen = (_a = JSON.parse(localStorage.getItem('leftbarOpen'))) !== null && _a !== void 0 ? _a : getLayout(window.innerWidth) === 'desktop';
if (!leftbarOpen && getLayout(window.innerWidth) !== 'mobile')
    document.body.style.setProperty('--leftbar-width', 'var(--minimized-leftbar-width)');
function handleLeftbarMinimized() {
    const layout = getLayout(window.innerWidth);
    if (layout === 'mobile') {
        document.body.style.setProperty('--leftbar-width', 'var(--maximized-leftbar-width)');
        return;
    }
    const { leftbarOpen } = store.getState().layout;
    const leftbarState = leftbarOpen ? 'maximized' : 'minimized';
    document.body.style.setProperty('--leftbar-width', `var(--${leftbarState}-leftbar-width)`);
}
window.addEventListener('resize', handleLeftbarMinimized);
store.subscribe(() => {
    const layoutState = store.getState().layout;
    if (layoutState.leftbarOpen !== leftbarOpen) {
        leftbarOpen = layoutState.leftbarOpen;
        handleLeftbarMinimized();
    }
});
const closeLeftbar = () => store.dispatch({ type: 'CLOSE_LEFTBAR' });
const openLeftbar = () => store.dispatch({ type: 'TOGGLE_LEFTBAR' });
const leftbarSelector = ({ layout }) => layout.leftbarOpen;
function LeftBar() {
    const layout = useLayout();
    const open = useSelector(leftbarSelector, shallowEqual);
    const content = (React.createElement(React.Fragment, null,
        React.createElement(Profile, null),
        React.createElement(NavList, null),
        React.createElement("div", { className: styles.appName },
            React.createElement(Box, { display: "flex", alignItems: "center" },
                layout !== 'mobile' && (open ? (React.createElement(IconButton, { size: "small", color: "inherit", onClick: closeLeftbar },
                    React.createElement(MinimizeIcon, { fontSize: "small" }))) : (React.createElement(IconButton, { size: "small", color: "inherit", onClick: openLeftbar },
                    React.createElement(MaximizeIcon, { fontSize: "small" })))),
                (layout === 'mobile' || open) && React.createElement("span", null, process.env.REACT_APP_NAME)))));
    return (React.createElement(React.Fragment, null,
        layout === 'mobile' && (React.createElement(SwipeableDrawer, { className: styles.drawer, variant: "temporary", open: open, onClose: closeLeftbar, onOpen: openLeftbar, ModalProps: { keepMounted: true } }, content)),
        layout === 'tablet' && (React.createElement(React.Fragment, null,
            open && React.createElement("div", { className: styles.backdrop, onClick: closeLeftbar }),
            React.createElement(Drawer, { className: styles.drawer, variant: "permanent", open: true, style: { position: 'absolute' } },
                React.createElement("div", { style: { width: open ? 'var(--maximized-leftbar-width)' : undefined }, className: styles.drawerPaperInner }, content)))),
        layout === 'desktop' && (React.createElement(Drawer, { className: styles.drawer, variant: "permanent", open: true, style: { position: 'absolute' } },
            React.createElement("div", { style: { width: open ? 'var(--maximized-leftbar-width)' : undefined }, className: styles.drawerPaperInner }, content)))));
}
export default LeftBar;

import React from 'react';
import ReactDOM from 'react-dom';
import { getPortalContainer } from 'utils/contents';
import LinearProgress from '../LinearProgress';
import styles from './styles.module.scss';
const topbarProgressContainer = getPortalContainer('topbar-progress');
function TopbarProgress(props) {
    const indicator = (React.createElement("span", { className: styles.root },
        React.createElement(LinearProgress, Object.assign({}, props))));
    return ReactDOM.createPortal(indicator, topbarProgressContainer);
}
export default TopbarProgress;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Checkbox as MuiCheckbox, FormControlLabel, } from '@material-ui/core';
import { useField, inputSubscription } from 'components/Form';
import useDerivedFieldProps from './useDerivedFieldProps';
import toggleValueInArray from './toggleValueInArray';
function Checkbox(_a) {
    var { name, value: option, label, labelPlacement, onChange } = _a, props = __rest(_a, ["name", "value", "label", "labelPlacement", "onChange"]);
    const _b = useDerivedFieldProps(props), { className } = _b, derivedProps = __rest(_b, ["className"]);
    const { value, change, blur } = useField(name, { subscription: inputSubscription });
    const handleChange = useCallback((event) => {
        if (option != null) {
            change((selectedOptions) => toggleValueInArray(selectedOptions, option));
        }
        else
            change(event.target.checked);
        onChange === null || onChange === void 0 ? void 0 : onChange(event.target.checked);
    }, [option, onChange]);
    const checked = value instanceof Array
        ? value.includes(option)
        : value;
    return (React.createElement(FormControlLabel, { className: className, labelPlacement: labelPlacement !== null && labelPlacement !== void 0 ? labelPlacement : (option != null ? 'end' : 'start'), label: label, control: React.createElement(MuiCheckbox, Object.assign({}, derivedProps, { checked: checked !== null && checked !== void 0 ? checked : false, onChange: handleChange, onBlur: blur })) }));
}
const MemoCheckbox = React.memo(Checkbox);
export default MemoCheckbox;

import ReactDOM from 'react-dom';
import Leaflet from 'leaflet';
import styles from './styles.module.scss';
const workerIcon = document.createElement('div');
function WorkerIcon() {
    return (React.createElement("svg", { viewBox: "0 0 24 24", className: styles.workerMarkerIcon },
        React.createElement("path", { d: "M18.5,10.2c0,2.57-2.1,5.79-6.16,9.51L12,20.01l-0.34-0.31C7.6,15.99,5.5,12.77,5.5,10.2 c0-3.84,2.82-6.7,6.5-6.7S18.5,6.35,18.5,10.2z" }),
        React.createElement("path", { className: styles.inner, d: "M12,11c1.33,0,4,0.67,4,2v0.16C15.03,14.28,13.6,15,12,15s-3.03-0.72-4-1.84V13C8,11.67,10.67,11,12,11zM12,10 c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,10,12,10z" }),
        React.createElement("path", { className: styles.outer, d: "M18,10.2C18,6.57,15.35,4,12,4s-6,2.57-6,6.2c0,2.34,1.95,5.44,6,9.14 C16.05,15.64,18,12.54,18,10.2z M12,2c4.2,0,8,3.22,8,8.2c0,3.32-2.67,7.25-8,11.8c-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2z" })));
}
ReactDOM.render(React.createElement(WorkerIcon, null), workerIcon);
export const MapWorkerIcon = Leaflet.DivIcon.extend({
    options: {
        className: '',
        html: workerIcon.innerHTML,
        iconSize: [24, 36],
        iconAnchor: [12, 36],
    },
});
export const workerAlertIcon = new MapWorkerIcon({
    className: styles.workerAlertIcon,
});
export const workerSuccessIcon = new MapWorkerIcon({
    className: styles.workerSuccessIcon,
});

import React, { useContext } from 'react';
import { Checkbox, ListItemText } from '@material-ui/core';
import SelectContext from './SelectContext';
function SelectItem({ value: optionValue, label, children }) {
    var _a;
    const ctx = useContext(SelectContext);
    children = children !== null && children !== void 0 ? children : React.createElement(ListItemText, { primary: label });
    if (ctx.multiple) {
        const selected = (_a = ctx.value) === null || _a === void 0 ? void 0 : _a.includes(optionValue);
        return (React.createElement(React.Fragment, null,
            React.createElement(Checkbox, { checked: selected !== null && selected !== void 0 ? selected : false }),
            children));
    }
    return React.createElement(React.Fragment, null, children);
}
const MemoSelectItem = React.memo(SelectItem);
export default MemoSelectItem;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useField, fieldSubscription, shouldDisplayFieldError } from 'components/Form';
import MapContainer from 'components/_Map';
import { Typography } from '@material-ui/core';
import useDerivedFieldProps from '../useDerivedFieldProps';
function MapField(_a) {
    var { name, validate, polygons } = _a, props = __rest(_a, ["name", "validate", "polygons"]);
    const _b = useDerivedFieldProps(props), { disabled, readOnly } = _b, derivedProps = __rest(_b, ["disabled", "readOnly"]);
    const field = useField(name, { validate, subscription: fieldSubscription });
    const { value, change, blur, error } = field;
    const _polygons = useMemo(() => (polygons !== null && polygons !== void 0 ? polygons : []).map(p => (Object.assign(Object.assign({}, p), { fillOpacity: 0.1, opacity: 0.4 }))), [polygons]);
    const _editableElements = useMemo(() => (Object.assign(Object.assign({}, value), { polygons: value === null || value === void 0 ? void 0 : value.polygons.map(p => (Object.assign(Object.assign({}, p), { fillOpacity: 0.5, opacity: 0.9 }))) })), [value]);
    const displayError = shouldDisplayFieldError(field);
    return (React.createElement(React.Fragment, null,
        React.createElement(MapContainer, Object.assign({}, derivedProps, { disabled: disabled, polygons: _polygons, editableElements: _editableElements, onEditableElementsChange: change, onBlur: blur })),
        displayError && (React.createElement(Typography, { color: "error", style: { fontSize: '14px' } }, error))));
}
export default MapField;

import { setIn } from 'final-form';
import { ValidationError } from 'yup';
function validateYupSchema(schema, values) {
    try {
        schema.validateSync(values, { abortEarly: false });
    }
    catch (error) {
        if (error instanceof ValidationError) {
            let errors = {};
            for (const { path, message } of error.inner) {
                errors = setIn(errors, path, message);
            }
            return errors;
        }
    }
    return undefined;
}
export default validateYupSchema;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import AddPhotoIcon from '@material-ui/icons/AddAPhotoRounded';
import { openFileDialog } from 'app';
import { useField, shouldDisplayFieldError, fieldSubscription } from 'components/Form';
import useDerivedFieldProps from './useDerivedFieldProps';
import styles from './styles.module.scss';
const personIcon = React.createElement(PersonIcon, null);
const addPhotoIcon = React.createElement(AddPhotoIcon, null);
const localFileURLList = new Map();
function getLocalFileURL(file) {
    let url = localFileURLList.get(file);
    if (url === undefined) {
        url = URL.createObjectURL(file);
        localFileURLList.set(file, url);
    }
    return url;
}
function ImageField(_a) {
    var { name, validate } = _a, props = __rest(_a, ["name", "validate"]);
    const _b = useDerivedFieldProps(props), { readOnly, disabled } = _b, derivedProps = __rest(_b, ["readOnly", "disabled"]);
    const field = useField(name, { validate, subscription: fieldSubscription });
    const { value, change, blur, error } = field;
    const handleUploadPhoto = useCallback(() => {
        openFileDialog(([file]) => change(file), {
            accept: ['image/*'],
        });
    }, [name]);
    const displayError = shouldDisplayFieldError(field);
    const src = value instanceof File ? getLocalFileURL(value) : value;
    const children = readOnly ? personIcon : addPhotoIcon;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.imageField },
            React.createElement("div", { className: "wrapper", onClick: disabled ? undefined : handleUploadPhoto },
                React.createElement(Avatar, Object.assign({ tabIndex: disabled ? -1 : 0 }, (value ? { src } : { children }), derivedProps)),
                value && !disabled && (React.createElement(Avatar, Object.assign({ className: "overlay" }, props), addPhotoIcon))),
            displayError && React.createElement(Typography, { color: "error" }, error))));
}
export default React.memo(ImageField);

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from '@material-ui/core';
import { useForm, useFormState, useFormProps } from 'components/Form';
import { classes } from 'utils';
import styles from './styles.module.scss';
const subscription = {
    pristine: true,
    submitting: true,
};
function ResetButton(_a) {
    var { label = 'Очистить', icon, className, disabled } = _a, props = __rest(_a, ["label", "icon", "className", "disabled"]);
    const formProps = useFormProps();
    const { pristine, submitting } = useFormState(subscription);
    const form = useForm();
    if (formProps.readOnly)
        return null;
    return (React.createElement(Button, Object.assign({ className: classes(styles.button, className), disabled: disabled || pristine || submitting, onClick: () => form.reset() }, props),
        icon,
        label));
}
export default React.memo(ResetButton);

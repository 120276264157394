import React from 'react';
import FilterIcon from '@material-ui/icons/FilterList';
import ToolbarItem from 'components/Toolbar/ToolbarItem';
import { store } from 'app';
import { useLayout } from 'hooks';
const toggleRightBar = () => {
    store.dispatch({ type: 'TOGGLE_RIGHTBAR' });
};
const filterIcon = React.createElement(FilterIcon, null);
// @ts-ignore
const ToolbarFilterItem = React.forwardRef((props, ref) => {
    const layout = useLayout();
    if (layout === 'desktop')
        return null;
    return (React.createElement(ToolbarItem, Object.assign({ ref: ref, onClick: toggleRightBar, icon: filterIcon, text: "\u0424\u0438\u043B\u044C\u0442\u0440\u044B" }, props)));
});
export default React.memo(ToolbarFilterItem);

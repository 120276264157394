/* eslint-disable import/prefer-default-export */
export function getFileNameFromHeaders(headers) {
    var _a;
    const contentDisposition = headers['content-disposition'];
    // const uriEncodedFileName = contentDisposition
    //   ? contentDisposition.slice(contentDisposition.lastIndexOf("'") + 1)
    //   : undefined;
    const uriEncodedFileName = (_a = contentDisposition === null || contentDisposition === void 0 ? void 0 : contentDisposition.match(/filename\*=UTF-8''([^;]+)?/)) === null || _a === void 0 ? void 0 : _a[1];
    return decodeURI(uriEncodedFileName);
}

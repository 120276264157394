import { createForm, FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { isSchema } from 'yup';
import deepEqual from 'utils/deepEqual';
import clone from 'utils/clone';
import { FormProvider } from './FormContext';
import { FormPropsProvider } from './FormPropsContext';
import validateYupSchema from './validateYupSchema';
const mutators = Object.assign({}, arrayMutators);
function Form({ children, onSubmit, initialValues, enableReinitialize, values, validate, disabled, readOnly, onChange, }) {
    const current = useRef(Object()).current;
    if (current.form === undefined) {
        current.form = createForm({
            mutators,
            initialValues,
            // validate,
            onSubmit: values => { var _a; return (_a = current.onSubmit) === null || _a === void 0 ? void 0 : _a.call(current, { values, initialValues: current.initialValues }); },
        });
        if (values)
            current.form.change('', values);
        current.form.subscribe(({ values }) => {
            var _a;
            if (!current.preventChangeCallback)
                (_a = current.onChange) === null || _a === void 0 ? void 0 : _a.call(current, values);
        }, { values: true });
    }
    current.onSubmit = onSubmit;
    current.onChange = onChange;
    current.enableReinitialize = enableReinitialize;
    const { form } = current;
    useEffect(() => {
        current.preventChangeCallback = true;
        // form.pauseValidation();
        if (current.enableReinitialize !== false && !deepEqual(current.initialValues, initialValues)) {
            form.setConfig('initialValues', clone(initialValues));
            current.initialValues = initialValues;
        }
        if (!deepEqual(current.values, values)) {
            form.change('', clone(values));
            current.values = values;
        }
        if (current.validate !== validate) {
            const validateFn = validate instanceof Function
                ? validate
                : isSchema(validate)
                    ? (values) => validateYupSchema(validate, values)
                    : undefined;
            form.setConfig('validate', validateFn);
            current.validate = validate;
        }
        current.preventChangeCallback = false;
        // form.resumeValidation();
    });
    return (React.createElement(FormPropsProvider, Object.assign({}, { disabled, readOnly }),
        React.createElement(FormProvider, { form: form },
            React.createElement("span", { className: "contents", onReset: () => form.reset() }, children))));
}
export default Form;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YaEJ45V2hqoiAep4U8gw{width:36px;height:60px;margin-left:-6px;margin-top:-10px;fill:#fff}.YaEJ45V2hqoiAep4U8gw .tiQbqa3XADd0OPMyZ_Mx{fill:var(--outer-color, dodgerblue)}.YaEJ45V2hqoiAep4U8gw .WKmElyIsgTKGvP1x0Vgu{fill:var(--inner-color, dodgerblue)}.HANLI9zkxUHannd4SdFQ{--inner-color: #f34151;--outer-color: #f34151}.kOSSHUt0ybCAADq8pUhh{--inner-color: hsl(105deg, 45%, 70%);--outer-color: hsl(105deg, 45%, 70%)}", "",{"version":3,"sources":["webpack://./src/components/MapElements/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,gBAAA,CACA,SAAA,CACA,4CACE,mCAAA,CAEF,4CACE,mCAAA,CAIJ,sBACE,sBAAA,CACA,sBAAA,CAGF,sBACE,oCAAA,CACA,oCAAA","sourcesContent":["@import '../../theme';\n\n.worker-marker-icon {\n  width: 36px;\n  height: 60px;\n  margin-left: -6px;\n  margin-top: -10px;\n  fill: white;\n  .outer {\n    fill: var(--outer-color, dodgerblue)\n  }\n  .inner {\n    fill: var(--inner-color, dodgerblue)\n  }\n}\n\n.worker-alert-icon {\n  --inner-color: #{$color-alert};\n  --outer-color: #{$color-alert};\n}\n\n.worker-success-icon {\n  --inner-color: #{$color-success};\n  --outer-color: #{$color-success};\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"worker-marker-icon": "YaEJ45V2hqoiAep4U8gw",
	"workerMarkerIcon": "YaEJ45V2hqoiAep4U8gw",
	"outer": "tiQbqa3XADd0OPMyZ_Mx",
	"inner": "WKmElyIsgTKGvP1x0Vgu",
	"worker-alert-icon": "HANLI9zkxUHannd4SdFQ",
	"workerAlertIcon": "HANLI9zkxUHannd4SdFQ",
	"worker-success-icon": "kOSSHUt0ybCAADq8pUhh",
	"workerSuccessIcon": "kOSSHUt0ybCAADq8pUhh"
};
export default ___CSS_LOADER_EXPORT___;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { store } from 'app';
import qs from 'query-string';
import { getFileNameFromHeaders } from './getFileNameFromHeaders';
import notify from './notify';
function _fetch(url, init, options) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(url, init);
        switch (response.status) {
            case 200: {
                (_a = options === null || options === void 0 ? void 0 : options.onSuccess) === null || _a === void 0 ? void 0 : _a.call(options, response);
                break;
            }
            case 418: {
                const message = yield response.text();
                notify.error(message);
                break;
            }
            case 401: {
                try {
                    store === null || store === void 0 ? void 0 : store.dispatch({ type: 'SIGNOUT' });
                }
                catch (error) {
                    //
                }
                break;
            }
            default: {
                (_b = options === null || options === void 0 ? void 0 : options.onError) === null || _b === void 0 ? void 0 : _b.call(options, response);
            }
        }
        return response;
    });
}
function getContent(response) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!response.ok)
            return undefined;
        const contentType = response.headers.get('Content-Type');
        if (!contentType)
            return response;
        if (contentType.includes('json'))
            return response.json();
        if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            const contentDisposition = response.headers.get('Content-Disposition');
            const filename = getFileNameFromHeaders({ 'content-disposition': contentDisposition });
            const blob = yield response.blob();
            return new File([blob], filename);
        }
        return response;
    });
}
function get(url, query, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const _url = qs.stringifyUrl({ url, query });
        const response = yield _fetch(_url, {}, options);
        return getContent(response);
    });
}
function getRequestInitParam(data, options) {
    if (data instanceof FormData)
        return { body: data };
    if ((options === null || options === void 0 ? void 0 : options.contentType) !== 'FormData') {
        if (data instanceof Object)
            return {
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            };
        return { body: data };
    }
    const formData = new FormData();
    for (const key in data) {
        let value = data[key];
        // eslint-disable-next-line no-continue
        if (value === undefined)
            continue;
        value = value instanceof Object && !(value instanceof File) ? JSON.stringify(value) : value;
        formData.append(key, value);
    }
    return {
        body: formData,
    };
}
function post(url, data, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const { body, headers } = getRequestInitParam(data, options);
        const response = yield _fetch(url, {
            method: 'POST',
            body,
            headers: Object.assign({}, headers),
        }, options);
        return getContent(response);
    });
}
function put(url, data, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const { body, headers } = getRequestInitParam(data, options);
        const response = yield _fetch(url, {
            method: 'PUT',
            body,
            headers: Object.assign({}, headers),
        }, options);
        return getContent(response);
    });
}
function _delete(url, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield _fetch(url, {
            method: 'DELETE',
        }, options);
        return getContent(response);
    });
}
const http = {
    get,
    post,
    put,
    delete: _delete,
};
export default http;

import CreateIcon from '@material-ui/icons/Add';
import CreateList from '@material-ui/icons/PlaylistAdd';
import SaveIcon from '@material-ui/icons/Save';
const templates = {
    create: {
        icon: React.createElement(CreateIcon, null),
        label: 'Добавить',
    },
    createByList: {
        icon: React.createElement(CreateList, null),
        label: 'Добавить списком',
    },
    save: {
        icon: React.createElement(SaveIcon, null),
        label: 'Сохранить',
    },
};
export default templates;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { startOfMonth, addDays } from 'date-fns';
import { http } from 'utils';
import { Chart, addValueLabelsToBars, Form, DatePicker } from 'components';
import PositionSelect from 'pages/workers/PositionSelect';
import styles from './styles.module.scss';
// import mockData from './WorkersArrivalMockData.json';
const barOptions = {
    barThickness: 18,
    barPercentage: 0.8,
    categoryPercentage: 1,
};
const padding = {
    top: 20,
    right: 10,
    bottom: 10,
    left: 10,
};
const chartOptions = {
    plugins: {
        legend: {
            display: false,
        },
    },
    maintainAspectRatio: false,
    aspectRatio: 1,
    layout: {
        padding,
    },
    scales: {
        x: {
            grid: {
                display: false,
                borderColor: 'transparent',
            },
        },
        y: {
            grid: {
                borderColor: 'transparent',
            },
            ticks: {
                display: false,
                stepSize: 1,
                count: 10,
            },
        },
    },
    hover: {
        mode: null,
    },
    animation: {
        onComplete: ({ chart }) => addValueLabelsToBars(chart, true),
    },
};
const getDefaultFilterValues = () => ({
    begin: addDays(startOfMonth(new Date()), 1).toISOString().slice(0, 10),
    end: new Date().toISOString().slice(0, 10),
    positionId: null,
});
const parseWorkersArrivalData = ({ groupName, items }) => ({
    groupName,
    items: items.map(item => parseInt(item)),
});
function fetchWorkersArrivalData(siteId, begin, end) {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/dashboards/workersinprojectgroupdays', {
            zoneGroupId: siteId,
            begin,
            end,
        });
        // fragment for debugging
        // return (mockData as WorkersArrivalData).map(parseWorkersArrivalData) as WorkersArrivalParsedData;
        return result.map(parseWorkersArrivalData);
    });
}
function filterDataByPositionId(data, positionId) {
    return positionId == null
        ? data
        : data.map(({ groupName, items }) => ({
            groupName,
            items: items.filter(item => item === positionId),
        }));
}
function getWorkersArrivalChartData(data) {
    return {
        labels: data.map(tickData => tickData.groupName),
        datasets: [
            Object.assign(Object.assign({ backgroundColor: '#9ac2ff' }, barOptions), { data: data.map(tickData => tickData.items.length) }),
        ],
    };
}
function WorkersArrivalWidget({ siteId }) {
    const [filterValues, setFilterValues] = useState(getDefaultFilterValues);
    const { begin, end, positionId } = filterValues;
    const { data = [] } = useQuery(['WIDGETS/WORKERS_ARRIVAL', siteId, begin, end], () => fetchWorkersArrivalData(siteId, begin, end), { enabled: Boolean(siteId), refetchInterval: 10000 });
    const filteredData = useMemo(() => filterDataByPositionId(data, positionId), [data, positionId]);
    const chartData = useMemo(() => getWorkersArrivalChartData(filteredData), [filteredData]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { onChange: setFilterValues, initialValues: filterValues },
            React.createElement("div", { className: styles.controls },
                React.createElement(DatePicker, { disableKeyboardInput: true, inputVariant: "standard", name: "begin", label: "\u041D\u0430\u0447\u0430\u043B\u043E" }),
                React.createElement(DatePicker, { disableKeyboardInput: true, inputVariant: "standard", name: "end", label: "\u041A\u043E\u043D\u0435\u0446" }),
                React.createElement(PositionSelect, { className: styles.autocomplete, name: "positionId", variant: "standard" }))),
        React.createElement("div", { className: styles.content },
            React.createElement(Chart, { type: "bar", options: chartOptions, data: chartData }))));
}
export default WorkersArrivalWidget;

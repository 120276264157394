var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Box, Button } from '@material-ui/core';
import BiometryIcon from '@material-ui/icons/Fingerprint';
import ProgressBar from 'components/inputs/ProgressBar';
import BiometryRequestDialog from 'pages/admin/Biometry/BiometryRequestDialog';
import React, { useState } from 'react';
import { http } from 'utils';
import styles from './BiometryWidget.module.scss';
const getLastBiometryData = (siteId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield http.get('/api/kbi/biometric/getlastrequestbiometric', { siteId });
    if (response instanceof Response && response.status === 204)
        return null;
    return response;
});
function BiometryWidget({ siteId }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [queryEnabled, setQueryEnabled] = useState(Boolean(siteId));
    const { data } = useQuery(['WIDGET/LAST_BIOMETRY', siteId], () => getLastBiometryData(siteId), {
        refetchInterval: 10 * 1000,
        enabled: queryEnabled,
        keepPreviousData: false,
        initialData: {
            workerCount: 0,
            confirmCount: 0,
            expectCount: 0,
        },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => setQueryEnabled(true), [siteId]);
    useMemo(() => {
        if (data === null)
            setQueryEnabled(false);
    }, [data]);
    const handleRequestBiometry = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);
    const handleSuccess = () => setQueryEnabled(true);
    return (React.createElement(React.Fragment, null,
        React.createElement(BiometryRequestDialog, { open: dialogOpen, onClose: closeDialog, onSuccess: handleSuccess, queryName: "WIDGET/LAST_BIOMETRY", siteId: siteId }),
        React.createElement(Box, { className: styles.container },
            React.createElement(Box, { className: styles.title }, "\u041E\u0431\u0449\u0438\u0439 \u0437\u0430\u043F\u0440\u043E\u0441"),
            React.createElement(Box, { className: styles.text },
                "\u0412\u044B\u0431\u0440\u0430\u043D\u043E \u0440\u0430\u0431\u043E\u0447\u0438\u0445:",
                React.createElement("span", { className: styles.link }, (data === null || data === void 0 ? void 0 : data.workerCount) || 0)),
            React.createElement(Button, { color: "default", className: styles.btn, startIcon: React.createElement(BiometryIcon, null), onClick: handleRequestBiometry }, "\u0417\u0430\u043F\u0440\u043E\u0441\u0438\u0442\u044C \u0431\u0438\u043E\u043C\u0435\u0442\u0440\u0438\u044E"),
            React.createElement(Box, { className: styles.text }, "\u0421\u0442\u0430\u0442\u0443\u0441:"),
            React.createElement(Box, { className: styles.list },
                "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u043B\u0438 ",
                '  ',
                React.createElement("span", { className: styles.link }, (data === null || data === void 0 ? void 0 : data.confirmCount) || 0),
                React.createElement(ProgressBar, { percentage: ((data === null || data === void 0 ? void 0 : data.confirmCount) / (data === null || data === void 0 ? void 0 : data.workerCount)) * 100 || 0, color: "#478DD1" })),
            React.createElement(Box, { className: styles.list },
                "\u041D\u0435 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u043B\u0438 ",
                '  ',
                React.createElement("span", { className: styles.link }, (data === null || data === void 0 ? void 0 : data.expectCount) || 0),
                React.createElement(ProgressBar, { percentage: ((data === null || data === void 0 ? void 0 : data.expectCount) / (data === null || data === void 0 ? void 0 : data.workerCount)) * 100 || 0, color: "#478DD1" })))));
}
export default BiometryWidget;

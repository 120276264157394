import { Avatar } from '@material-ui/core';
import { classes, getDeviceTypeByName } from 'utils';
import DeviceIcon from './DeviceIcon';
import styles from './styles.module.scss';
function DeviceAvatar({ model, attached, archive }) {
    const avatarClasses = classes(styles.avatar, attached ? styles.attached : undefined, archive ? styles.archive : undefined);
    return (React.createElement(Avatar, { className: avatarClasses },
        React.createElement(DeviceIcon, { type: getDeviceTypeByName(model === null || model === void 0 ? void 0 : model.displayName) })));
}
export default React.memo(DeviceAvatar);

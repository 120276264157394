import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ruLocale from 'date-fns/locale/ru';
import DateFnsUtils from '@date-io/date-fns';
import { Provider as StoreProvider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Router } from 'react-router';
import { store, history, queryClient } from '.';
import theme from './theme';
function AppProvider({ children }) {
    return (React.createElement(StylesProvider, { injectFirst: true },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(MuiPickersUtilsProvider, { locale: ruLocale, utils: DateFnsUtils },
                React.createElement(StoreProvider, { store: store },
                    React.createElement(QueryClientProvider, { client: queryClient },
                        React.createElement(Router, { history: history }, children)))))));
}
export default AppProvider;

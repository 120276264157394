import React from 'react';
import { IconButton } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Menu } from 'components';
import { useMenuState } from 'hooks';
import { openMenu } from 'utils';
import ToolbarItemContext from './ToolbarItemContext';
const menuKey = 'TOOLBAR/MORE_ITEMS';
const showMoreItems = (event) => openMenu(menuKey, event.currentTarget);
function ToolbarMenu({ children }) {
    const { open } = useMenuState(menuKey);
    return (React.createElement(ToolbarItemContext.Provider, { value: true },
        React.createElement(IconButton, { onClick: showMoreItems },
            React.createElement(MoreIcon, null)),
        React.createElement(Menu, { open: open }, children)));
}
export default ToolbarMenu;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hQhBIjUnLdo2YRTjgz4d{position:fixed;display:flex;gap:12px;z-index:1101}@media only screen and (min-width: 960px){.hQhBIjUnLdo2YRTjgz4d{left:calc(var(--minimized-leftbar-width) + 24px);flex-direction:row;top:calc(var(--topbar-base-height) - var(--topbar-extra-height))}}@media only screen and (min-width: 1280px){.hQhBIjUnLdo2YRTjgz4d{left:calc(var(--leftbar-width) + 24px)}}@media only screen and (max-width: 960px){.hQhBIjUnLdo2YRTjgz4d{flex-direction:column-reverse;bottom:24px;right:24px}}.hQhBIjUnLdo2YRTjgz4d .MuiFab-root.Mui-disabled{background-color:#e0e0e0}", "",{"version":3,"sources":["webpack://./src/layout/PrimaryActions/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,cAAA,CACA,YAAA,CACA,QAAA,CACA,YAAA,CACA,0CALF,sBAMI,gDAAA,CACA,kBAAA,CACA,gEAAA,CAAA,CAEF,2CAVF,sBAWI,sCAAA,CAAA,CAEF,0CAbF,sBAcI,6BAAA,CACA,WAAA,CACA,UAAA,CAAA,CAEF,gDACE,wBAAA","sourcesContent":["@import '../../theme.scss';\n\n.root {\n  position: fixed;\n  display: flex;\n  gap: 12px;\n  z-index: $topbar-z-index + 1;\n  @media only screen and (min-width: $mobile-width) {\n    left: calc(var(--minimized-leftbar-width) + 24px);\n    flex-direction: row;\n    top: calc(var(--topbar-base-height) - var(--topbar-extra-height));\n  }\n  @media only screen and (min-width: $tablet-width) {\n    left: calc(var(--leftbar-width) + 24px);\n  }\n  @media only screen and (max-width: $mobile-width) {\n    flex-direction: column-reverse;\n    bottom: 24px;\n    right: 24px;\n  }\n  :global(.MuiFab-root.Mui-disabled) {\n    background-color: rgb(224, 224, 224);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "hQhBIjUnLdo2YRTjgz4d"
};
export default ___CSS_LOADER_EXPORT___;

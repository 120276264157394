var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Leaflet from 'leaflet';
const polygonFactory = {
    entityName: 'Polygon',
    createLayer(state) {
        const { latlngs } = state, options = __rest(state, ["latlngs"]);
        return Leaflet.polygon(latlngs, options);
    },
    updateLayer(layer, state, nextState) {
        const { latlngs, color, fillOpacity } = nextState;
        for (const key in latlngs) {
            const latlng = state.latlngs[key];
            const nextLatlng = latlngs[key];
            if (latlng[0] !== nextLatlng[0] || latlng[1] !== nextLatlng[1]) {
                layer.setLatLngs(latlngs);
                break;
            }
            if (state.color !== color)
                layer.setStyle({ color });
            if (state.fillOpacity !== fillOpacity)
                layer.setStyle({ fillOpacity });
        }
    },
};
export default polygonFactory;

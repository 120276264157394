var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Dialog as MuiDialog } from '@material-ui/core';
import { useMutableState } from 'hooks';
import { closeDialog } from 'utils';
import { DialogContext } from './DialogContext';
function Dialog(_a) {
    var { open, disabled, children } = _a, props = __rest(_a, ["open", "disabled", "children"]);
    const current = useMutableState();
    if (open)
        current.children = children;
    const contextValue = useMemo(() => ({ open: Boolean(open), disabled: Boolean(disabled) }), [disabled, open]);
    return (React.createElement(MuiDialog, Object.assign({ open: open, onClose: disabled ? undefined : closeDialog }, props),
        React.createElement(DialogContext.Provider, { value: contextValue }, current.children)));
}
export default Dialog;

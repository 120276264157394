import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles.scss';
function About() {
    return (React.createElement(Carousel, { showArrows: false, showThumbs: false, showStatus: false, autoPlay: true, infiniteLoop: true, className: "carousel-root" },
        React.createElement("div", null,
            React.createElement("div", { className: "slide1_container" },
                React.createElement("img", { src: "/images/slide1.png", alt: "\u041D\u0430\u0441\u0442\u0440\u0430\u0438\u0432\u0430\u0439\u0442\u0435 \u0432\u0438\u0434\u0436\u0435\u0442\u044B" })),
            React.createElement("div", { className: "legend" },
                React.createElement("div", { className: "legend__title" }, "\u041E\u0431\u043B\u0430\u0447\u043D\u0430\u044F \u0441\u0438\u0441\u0442\u0435\u043C\u0430 \u043C\u043E\u043D\u0438\u0442\u043E\u0440\u0438\u043D\u0433\u0430 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u0430"),
                React.createElement("div", { className: "legend__text" }, "\u0410\u043D\u0430\u043B\u0438\u0442\u0438\u043A\u0430 \u0434\u0430\u043D\u043D\u044B\u0445 IoT-\u0441\u0438\u0441\u0442\u0435\u043C\u044B Exon Smart"))),
        React.createElement("div", null,
            React.createElement("img", { src: "/images/slide2.png", alt: "\u041D\u0430\u0441\u0442\u0440\u0430\u0438\u0432\u0430\u0439\u0442\u0435 \u0432\u0438\u0434\u0436\u0435\u0442\u044B" }),
            React.createElement("div", { className: "legend" },
                React.createElement("div", { className: "legend__title" }, "\u0423\u043C\u043D\u044B\u0435 \u0443\u0441\u0442\u0440\u043E\u0439\u0441\u0442\u0432\u0430"),
                React.createElement("div", { className: "legend__text" }, "\u0421\u043C\u0430\u0440\u0442-\u0447\u0430\u0441\u044B \u0438 \u0442\u0443\u0440\u043D\u0438\u043A\u0435\u0442\u044B \u0434\u043B\u044F \u043A\u043E\u043D\u0442\u0440\u043E\u043B\u044F \u044D\u0444\u0444\u0435\u043A\u0442\u0438\u0432\u043D\u043E\u0441\u0442\u0438 \u0438 \u0431\u0435\u0437\u043E\u043F\u0430\u0441\u043D\u043E\u0441\u0442\u0438 \u043D\u0430 \u043F\u0440\u0435\u0434\u043F\u0440\u0438\u044F\u0442\u0438\u044F\u0445.")))));
}
export default About;

import React from 'react';
import { Badge, Typography } from '@material-ui/core';
import AlertsIcon from '@material-ui/icons/Notifications';
import useTotalAlerts from 'hooks/useTotalAlerts';
import { NavItem } from './NavItem';
import useLeftbarMinimized from './useLeftbarMinimized';
import styles from './styles.module.scss';
function AlertsNavItem() {
    const { unresponded } = useTotalAlerts();
    const leftbarMinimized = useLeftbarMinimized();
    if (leftbarMinimized) {
        const icon = (React.createElement(Badge, { classes: { badge: styles.iconBadge }, badgeContent: unresponded },
            React.createElement(AlertsIcon, { color: "inherit" })));
        return React.createElement(NavItem, { icon: icon, path: "/alerts" });
    }
    const text = (React.createElement(Badge, { classes: { badge: styles.badge }, badgeContent: unresponded },
        React.createElement(Typography, null, "\u0422\u0440\u0435\u0432\u043E\u0433\u0438")));
    return React.createElement(NavItem, { icon: React.createElement(AlertsIcon, { color: "inherit" }), text: text, path: "/alerts" });
}
export default AlertsNavItem;

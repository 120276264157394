var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from '@material-ui/core';
import { useForm, useFormState, useFormProps } from 'components/Form';
import { submitButtonTemplates } from './templates';
const subscription = {
    hasValidationErrors: true,
    submitFailed: true,
    dirtySinceLastSubmit: true,
    pristine: true,
    submitting: true,
    validating: true,
};
function SubmitButton(_a) {
    var _b;
    var { disablePristine = false, disabled, template } = _a, props = __rest(_a, ["disablePristine", "disabled", "template"]);
    const _c = Object.assign(Object.assign({}, (_b = submitButtonTemplates[template]) !== null && _b !== void 0 ? _b : { children: 'Отправить' }), props), { component } = _c, derivedProps = __rest(_c, ["component"]);
    const form = useForm();
    const formProps = useFormProps();
    const { hasValidationErrors, submitFailed, dirtySinceLastSubmit, pristine, submitting, validating, } = useFormState(subscription);
    if (formProps.readOnly)
        return null;
    disabled = formProps.disabled || disabled || submitting || validating ||
        hasValidationErrors && (submitFailed || dirtySinceLastSubmit)
        || (disablePristine && pristine);
    const Component = component !== null && component !== void 0 ? component : Button;
    return (React.createElement(Component, Object.assign({}, derivedProps, { onClick: form.submit, disabled: disabled })));
}
const MemoSubmitButton = React.memo(SubmitButton);
export default MemoSubmitButton;

import useField from './useField';
import { errorSubscription } from './subscriptions';
import shouldDisplayFieldError from './shouldDisplayFieldError';
function FieldError({ name, component: Component = 'div' }) {
    const field = useField(name, { subscription: errorSubscription });
    const displayError = shouldDisplayFieldError(field);
    const { error, submitError } = field;
    return displayError
        ? (React.createElement(Component, { style: { color: 'red', fontSize: '14px' } }, error || submitError)) : null;
}
export default React.memo(FieldError);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Tooltip, Grid, Divider, } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import WatchIcon from '@material-ui/icons/Watch';
import PersonIcon from '@material-ui/icons/Person';
import { history } from 'app';
import ReadMoreIcon from 'components/icons/ReadMore';
import IndoorZonesMap from 'components/IndoorZonesMap';
import { MapWorkerIcon } from 'components/MapElements/WorkerMarker';
import fetchPageBeaconList from 'pages/beacons/queries/fetchPageBeaconList';
import { http } from 'utils';
import { ListItemBatteryCharge } from 'pages/event-log/_shared';
import styles from './styles.module.scss';
function getWorkerStateKey({ isChecked, sos, isValid, lag }) {
    if (sos)
        return 'Alert';
    if (!isValid)
        return 'Invalid';
    if (lag < 30)
        return isChecked ? 'RecentChecked' : 'RecentUnchecked';
    if (lag < 60)
        return 'Lagging';
    if (lag > 60)
        return 'VeryLagging';
}
const workerStateIcons = {
    Alert: new MapWorkerIcon({
        className: styles.workerStateMarker + ' ' + styles.workerStateAlert,
    }),
    Invalid: new MapWorkerIcon({
        className: styles.workerStateMarker + ' ' + styles.workerStateInvalid,
    }),
    RecentChecked: new MapWorkerIcon({
        className: styles.workerStateMarker + ' ' + styles.workerStateRecentChecked,
    }),
    RecentUnchecked: new MapWorkerIcon({
        className: styles.workerStateMarker + ' ' + styles.workerStateRecentUnchecked,
    }),
    Lagging: new MapWorkerIcon({
        className: styles.workerStateMarker + ' ' + styles.workerStateLagging,
    }),
    VeryLagging: new MapWorkerIcon({
        className: styles.workerStateMarker + ' ' + styles.workerStateVeryLagging,
    }),
};
function fetchTooltipData(workerId) {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/eventlog', {
            zoneGroupId: workerId,
            fields: `shift[workerId=${workerId},*],*`,
        });
        return result[0];
    });
}
function WorkerStateListItem({ data }) {
    const { fullName, datePoint, workerId } = data;
    const { data: tooltipData = {} } = useQuery(['WIDGETS/MAP_TOOLTIP', workerId], () => fetchTooltipData(workerId), {
        enabled: Boolean(workerId),
        refetchInterval: 10000,
    });
    const { mobject } = tooltipData;
    const workerStateKey = getWorkerStateKey(data);
    return (React.createElement(ListItem, null,
        React.createElement("div", null,
            React.createElement("div", { className: styles.listItemMain },
                React.createElement(ListItemAvatar, { className: styles.workerStateAvatar + ' ' + styles['workerState' + workerStateKey] },
                    React.createElement(Avatar, { alt: "fullName", src: `/api/kbi/workers/${workerId}/photo` },
                        React.createElement(PersonIcon, { fontSize: "small" }))),
                React.createElement(ListItemText, { primary: fullName, secondary: new Date(datePoint).toLocaleString('ru'), secondaryTypographyProps: { title: 'Время последнего пакета' } }),
                React.createElement("div", { className: styles.secondaryAction },
                    React.createElement(Tooltip, { title: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u0443" },
                        React.createElement(IconButton, { onClick: () => history.push('/workers/' + workerId) },
                            React.createElement(ReadMoreIcon, null))))),
            mobject &&
                React.createElement(React.Fragment, null,
                    React.createElement(Divider, null),
                    React.createElement(Grid, { container: true, direction: "column", className: styles.mapTooltipInfo },
                        React.createElement(Grid, { item: true, className: styles.mapTooltipInfoRow },
                            "\u0412\u0440\u0435\u043C\u044F \u043F\u043E\u0441\u043B\u0435\u0434\u043D\u0435\u0439 \u043A\u043E\u043E\u0440\u0434\u0438\u043D\u0430\u0442\u044B: ",
                            new Date(mobject === null || mobject === void 0 ? void 0 : mobject.lastPointTime).toLocaleString('ru')),
                        React.createElement(Grid, { item: true, className: styles.mapTooltipInfoRow },
                            "\u0412\u0440\u0435\u043C\u044F \u043F\u043E\u0441\u043B\u0435\u0434\u043D\u0435\u0439 \u0442\u0440\u0435\u0432\u043E\u0433\u0438:",
                            ' ',
                            (mobject === null || mobject === void 0 ? void 0 : mobject.lastSOSDateTime) ? new Date(mobject === null || mobject === void 0 ? void 0 : mobject.lastSOSDateTime).toLocaleString('ru') : '-'),
                        React.createElement(Grid, { item: true, className: styles.mapTooltipInfoRow },
                            React.createElement(Grid, { container: true, spacing: 1 },
                                React.createElement(Grid, { item: true },
                                    React.createElement(Grid, { container: true, alignItems: "center", wrap: "nowrap" },
                                        React.createElement(Tooltip, { title: (mobject === null || mobject === void 0 ? void 0 : mobject.isPutOn) ? 'Надеты' : 'Сняты' },
                                            React.createElement(WatchIcon, { htmlColor: (mobject === null || mobject === void 0 ? void 0 : mobject.isPutOn) ? 'var(--color-green)' : 'var(--color-secondary)', fontSize: "small" })))),
                                React.createElement(Grid, { item: true },
                                    React.createElement(ListItemBatteryCharge, { value: mobject === null || mobject === void 0 ? void 0 : mobject.batteryCharge })),
                                React.createElement(Grid, { item: true },
                                    React.createElement(Grid, { container: true, alignItems: "center", wrap: "nowrap" },
                                        React.createElement(Tooltip, { title: "\u0412\u0435\u0440\u0441\u0438\u044F \u043F\u0440\u043E\u0448\u0438\u0432\u043A\u0438" },
                                            React.createElement(BuildIcon, { fontSize: "small" })), mobject === null || mobject === void 0 ? void 0 :
                                        mobject.buildVersion)))))))));
}
const renderTooltip = (entityName, data) => {
    let content = null;
    if (entityName === 'Polygon') {
        content = React.createElement("div", { className: styles.polygonTooltip }, data);
    }
    if (entityName === 'Marker') {
        content = (React.createElement(List, { dense: true, disablePadding: true },
            React.createElement(WorkerStateListItem, { data: data })));
    }
    if (entityName === 'MarkerCluster') {
        content = (React.createElement(List, { dense: true, disablePadding: true }, data.map(workerState => (React.createElement(WorkerStateListItem, { key: workerState.workerId, data: workerState })))));
    }
    return (React.createElement(Paper, { className: styles.mapWidgetTooltip, elevation: 4 }, content));
};
function fetchMapWidgetData(siteId, selectedFloor) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        if (selectedFloor) {
            const data = yield http.get('/api/indoor/indoornavigations/getnowbyfloor', {
                constructionSiteId: siteId,
                floor: selectedFloor,
            });
            const workerStates = (_a = data === null || data === void 0 ? void 0 : data.map(({ workerId, workerName, deviceId, coordinates }) => {
                var _a;
                const { dateTime, latitude, longitude, log, sos } = (_a = coordinates === null || coordinates === void 0 ? void 0 : coordinates[0]) !== null && _a !== void 0 ? _a : {};
                return {
                    datePoint: dateTime,
                    fullName: workerName,
                    lag: log,
                    location: [longitude, latitude],
                    serialNumber: deviceId,
                    sos,
                    workerId,
                    isBiomentry: true,
                    isChecked: true,
                    isValid: true,
                };
            })) !== null && _a !== void 0 ? _a : [];
            return { workerStates, zones: [] };
        }
        const { result } = yield http.get('/api/kbi/dashboards/monitoringonmap', {
            zoneGroupId: siteId,
            fields: 'workerStates[*],zones[name,color,states[points]]',
        });
        // fragment for debugging
        // // @ts-expect-error
        // return mockData as MapWidgetData;
        return result;
    });
}
function MapWidget({ siteId }) {
    const [selectedFloor, setSelectedFloor] = useState(null);
    const handleFloorChange = useCallback((zoneId, floor) => setSelectedFloor(floor), []);
    const current = useRef(Object()).current;
    current.selectedFloor = selectedFloor;
    useMemo(() => {
        setSelectedFloor(null);
        current.selectedFloor = null;
    }, [siteId]);
    const { data = {}, isFetched } = useQuery(['WIDGETS/MAP', siteId, current.selectedFloor], () => fetchMapWidgetData(siteId, current.selectedFloor), {
        enabled: Boolean(siteId),
        refetchInterval: 10000,
    });
    const { markers, polygons } = useMemo(() => {
        const { workerStates = [], zones = [] } = data;
        const markers = (workerStates || [])
            .filter(item => item.isBiomentry)
            .map(workerState => {
            const { workerId, location: [lat, lng], } = workerState;
            const workerStateKey = getWorkerStateKey(workerState);
            return {
                id: workerId,
                latlng: [lng, lat],
                icon: workerStateIcons[workerStateKey],
                tooltip: workerState,
                sos: workerState.sos,
            };
        });
        const polygons = (zones || []).map(({ name, states, color: [red, green, blue, alpha] }) => ({
            id: name,
            latlngs: states.points.map(([lat, lng]) => [lng, lat]),
            color: `rgba(${red},${green},${blue},${alpha})`,
            tooltip: name,
        }));
        return { markers, polygons };
    }, [data]);
    const bounds = useMemo(() => {
        if (isFetched)
            return [...markers === null || markers === void 0 ? void 0 : markers.map(m => m.latlng), ...polygons === null || polygons === void 0 ? void 0 : polygons.flatMap(m => m.latlngs)];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteId, isFetched]);
    const { data: beacons = [] } = useQuery(['BEACONS/BEACON_LIST', siteId], () => fetchPageBeaconList({ siteId }));
    return (React.createElement(IndoorZonesMap, { mainMapWidget: true, beacons: beacons, siteId: siteId, markerCluster: true, markers: markers, polygons: polygons, bounds: (bounds === null || bounds === void 0 ? void 0 : bounds.length) === 0 ? undefined : bounds, rootStyle: { width: '100%', height: '100%' }, style: { width: '100%', height: '100%' }, renderTooltip: renderTooltip, editableImageZIndex: 300, selectedFloor: selectedFloor, onFloorChange: handleFloorChange }));
}
export default MapWidget;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Autocomplete } from 'components';
import { http, getDeviceTypeByName } from 'utils';
import { DeviceIcon } from 'templates';
const toDeviceSelectOption = ({ id, gpsAddr, worker, model }) => ({
    id,
    serialNumber: gpsAddr,
    workerId: worker === null || worker === void 0 ? void 0 : worker.id,
    type: getDeviceTypeByName(model.name),
});
const fields = 'id,gpsAddr,worker[id],model[name]';
function getDeviceListForSelect() {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/control-device', {
            fields,
        });
        return result.map(toDeviceSelectOption);
    });
}
const getDeviceOptionValue = ({ id }) => id;
const getDeviceOptionLabel = ({ serialNumber, type }) => serialNumber;
const renderDeviceOptionLabel = ({ serialNumber, type }) => (React.createElement("span", null,
    React.createElement(DeviceIcon, { type: type }),
    React.createElement("span", null, serialNumber)));
function DeviceSelect(_a) {
    var { name, label, multiple, disabled, filter, filterValues } = _a, props = __rest(_a, ["name", "label", "multiple", "disabled", "filter", "filterValues"]);
    const { data = [], isFetching } = useQuery('SELECT/DEVICE', getDeviceListForSelect, {
        cacheTime: 10 * 60 * 1000,
    });
    const deviceList = useMemo(() => (filter == null ? data : data.filter(filter)), [data, filter]);
    return (React.createElement(Autocomplete, Object.assign({ name: name || 'deviceId', multiple: multiple, label: (label !== null && label !== void 0 ? label : multiple) ? 'Устройства' : 'Устройство', getOptionValue: getDeviceOptionValue, getOptionLabel: getDeviceOptionLabel, renderOption: renderDeviceOptionLabel, options: deviceList, disabled: disabled !== null && disabled !== void 0 ? disabled : isFetching, filterValues: filterValues !== null && filterValues !== void 0 ? filterValues : !isFetching }, props)));
}
export default React.memo(DeviceSelect);

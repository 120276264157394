import widgets from './widgets';
export const allBreakpoints = ['xs', 'sm', 'md', 'lg'];
const allItemKeys = Object.keys(widgets);
const defaultLayouts = allItemKeys.reduce((result, key) => (Object.assign(Object.assign({}, result), { [key]: widgets[key].defaultLayouts })), {});
const sizeLimits = allItemKeys.reduce((result, key) => (Object.assign(Object.assign({}, result), { [key]: widgets[key].sizeLimits })), {});
export function getStoreLayouts() {
    const storeItem = localStorage.getItem('dashboard/layouts');
    return storeItem === null ? Object.assign({}, defaultLayouts) : JSON.parse(storeItem);
}
export function saveLayoutsToStore(layouts) {
    localStorage.setItem('dashboard/layouts', JSON.stringify(layouts));
}
function getSize(size, minSize, maxSize) {
    if (size < minSize)
        return minSize;
    if (size > maxSize)
        return maxSize;
    return size !== null && size !== void 0 ? size : minSize;
}
function getResponsiveValue(values, bp) {
    if (values !== undefined) {
        const value = values[bp];
        if (value !== undefined)
            return value;
        const bpIndex = allBreakpoints.indexOf(bp);
        for (let index = bpIndex - 1; index >= 0; index--) {
            const bp = allBreakpoints[index];
            const value = values[bp];
            if (value !== undefined)
                return value;
        }
        for (let index = bpIndex + 1; index < allBreakpoints.length; index++) {
            const bp = allBreakpoints[index];
            const value = values[bp];
            if (value !== undefined)
                return value;
        }
    }
    return {};
}
export function convertToLayoutsProp(layouts) {
    const layoutsProp = {};
    const itemKeys = Object.keys(layouts);
    for (const itemKey of itemKeys) {
        const itemLayout = layouts[itemKey];
        const breakpoints = Object.keys(itemLayout).reverse();
        for (const bp of breakpoints) {
            const { left, top, width, height } = itemLayout[bp] || {};
            const defaultLayout = getResponsiveValue(defaultLayouts[itemKey], bp);
            const sizeLimit = getResponsiveValue(sizeLimits[itemKey], bp);
            const { minWidth, maxWidth, minHeight, maxHeight } = sizeLimit;
            if (layoutsProp[bp] === undefined)
                layoutsProp[bp] = [];
            layoutsProp[bp].push({
                i: itemKey,
                x: left || 0,
                y: top || 0,
                w: getSize(width !== null && width !== void 0 ? width : defaultLayout.width, minWidth, maxWidth),
                h: getSize(height !== null && height !== void 0 ? height : defaultLayout.height, minHeight, maxHeight),
                minW: minWidth,
                minH: minHeight,
                maxW: maxWidth,
                maxH: maxHeight,
            });
        }
    }
    return layoutsProp;
}
export function convertToLayoutsState(layouts) {
    const layoutsState = {};
    const breakpoints = Object.keys(layouts);
    for (const bp of breakpoints) {
        const breakpointLayouts = layouts[bp];
        for (const itemLayout of breakpointLayouts) {
            const { i: itemKey, x, y, w, h } = itemLayout;
            if (layoutsState[itemKey] === undefined)
                layoutsState[itemKey] = {};
            layoutsState[itemKey][bp] = {
                left: x,
                top: y,
                width: w,
                height: h,
            };
        }
    }
    return layoutsState;
}
export function getEndLayoutsState(itemKey, breakpoint) {
    const { width, height } = getResponsiveValue(defaultLayouts[itemKey], breakpoint);
    const { minWidth, minHeight } = getResponsiveValue(sizeLimits[itemKey], breakpoint);
    return {
        [breakpoint]: {
            left: 0,
            top: 0,
            width: width !== null && width !== void 0 ? width : minWidth,
            height: height !== null && height !== void 0 ? height : minHeight,
        },
    };
}

import ReactDOM from 'react-dom';
import AppProvider from 'app/AppProvider';
import styles from './styles.module.scss';
const overlap = 3;
class MapTooltip {
    constructor() {
        this.handleContainerMouseLeave = this.handleContainerMouseLeave.bind(this);
        this.handleLayerMouseLeave = this.handleLayerMouseLeave.bind(this);
        const rootNode = document.createElement('div');
        rootNode.classList.add(styles.tooltipContainer);
        rootNode.style.visibility = 'hidden';
        rootNode.addEventListener('mouseleave', this.handleContainerMouseLeave);
        this.rootNode = rootNode;
    }
    appendToBody() {
        document.body.appendChild(this.rootNode);
    }
    removeFromBody() {
        document.body.removeChild(this.rootNode);
    }
    close() {
        if (this.currentTarget !== undefined) {
            this.currentTarget.removeEventListener('mouseleave', this.handleLayerMouseLeave);
            this.currentTarget = undefined;
        }
        this.rootNode.style.visibility = 'hidden';
    }
    handleContainerMouseLeave(event) {
        if (event.relatedTarget !== this.currentTarget)
            this.close();
    }
    handleLayerMouseLeave(event) {
        if (!this.rootNode.contains(event.relatedTarget))
            this.close();
    }
    move(x, y) {
        const { rootNode } = this;
        rootNode.style.left = (x - overlap).toString() + 'px';
        rootNode.style.top = (y + overlap - rootNode.clientHeight).toString() + 'px';
    }
    open(event, entityName, data) {
        var _a;
        const { target, pageX, pageY } = event;
        this.move(pageX, pageY);
        if (this.currentTarget === target)
            return;
        if (target instanceof Element) {
            // const { clientX, clientY } = event;
            const { rootNode } = this;
            this.currentTarget = target;
            ReactDOM.render(React.createElement(AppProvider, {}, ((_a = this.renderFn) !== null && _a !== void 0 ? _a : MapTooltip.renderFn)(entityName, data)), rootNode);
            // this.move(clientX, clientY);
            rootNode.style.visibility = 'visible';
            target.addEventListener('mouseleave', this.handleLayerMouseLeave);
        }
    }
    show() {
        if (this.currentTarget !== undefined)
            this.rootNode.style.visibility = 'visible';
    }
    hide() {
        this.rootNode.style.visibility = 'hidden';
    }
}
MapTooltip.renderFn = (entityName, data) => data;
export default MapTooltip;

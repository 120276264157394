var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Typography } from '@material-ui/core';
import { http } from 'utils';
import { Chart, Form, DatePicker, getColor, generateLegendLabels } from 'components';
import styles from './styles.module.scss';
const padding = {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10,
};
const chartOptions = {
    plugins: {
        legend: {
            display: true,
            labels: {
                generateLabels: generateLegendLabels,
            },
        },
    },
    maintainAspectRatio: false,
    aspectRatio: 1,
    layout: {
        padding,
    },
    hover: {
        mode: null,
    },
};
const getDefaultFilterValues = () => ({
    date: new Date().toISOString().slice(0, 10),
});
function fetchEnterprisesPresencelData(siteId, date) {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/dashboards/enterprisesinproject', {
            zoneGroupId: siteId,
            dateTime: date,
        });
        // fragment for debugging
        // return [
        //   { groupName: 'ООО "ГАСКАР ИНТЕГРАЦИЯ"', count: 2 },
        //   { groupName: 'ООО "АСК"', count: 10 },
        //   { groupName: 'ООО "АВТОМАТИЗИРОВАННЫЕ СИСТЕМЫ КОСТЫЛЕЙ"', count: 6 },
        //   { groupName: 'ООО "ГАСКАР ИММИГРАЦИЯ"', count: 1 },
        //   { groupName: 'ООО "ГАСКАР ЛЕВИТАЦИЯ"', count: 4 },
        // ];
        return result;
    });
}
function getEnterprisesPresenceChartData(data) {
    return {
        labels: data.map(tickData => tickData.groupName),
        datasets: [
            {
                backgroundColor: data.map((tickData, index) => getColor(index)),
                data: data.map(tickData => tickData.count),
            },
        ],
    };
}
function EnterprisesPresenceWidget({ siteId }) {
    const [filterValues, setFilterValues] = useState(getDefaultFilterValues);
    const { date } = filterValues;
    const { data = [], isLoading } = useQuery(['WIDGETS/ENTERPRISES_PRESENCE', siteId, date], () => fetchEnterprisesPresencelData(siteId, date), { enabled: Boolean(siteId), refetchInterval: 10000 });
    const chartData = useMemo(() => getEnterprisesPresenceChartData(data), [data]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { onChange: setFilterValues, initialValues: filterValues },
            React.createElement("div", { className: styles.controls },
                React.createElement(DatePicker, { disableKeyboardInput: true, inputVariant: "standard", name: "date", label: "\u0414\u0430\u0442\u0430" }))),
        React.createElement("div", { style: { display: data.length === 0 ? 'none' : undefined }, className: styles.content },
            React.createElement(Chart, { type: "pie", options: chartOptions, data: chartData })),
        siteId && data.length === 0 && !isLoading && (React.createElement("div", { className: styles.empty },
            React.createElement(Typography, null, "\u041E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u0438 \u043E\u0442\u0441\u0443\u0442\u0441\u0432\u043E\u0432\u0430\u043B\u0438 \u043D\u0430 \u043E\u0431\u044A\u0435\u043A\u0442\u0435")))));
}
export default EnterprisesPresenceWidget;

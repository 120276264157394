var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import ReactDOM from 'react-dom';
import { Fab as MuiFab } from '@material-ui/core';
import { useLayout } from 'hooks';
import useTopbarUpdater from 'hooks/useTopbarUpdater';
import { classes } from 'utils';
import { getPortalContainer } from 'utils/contents';
import templates from './templates';
import styles from './styles.module.scss';
const primaryActionsContainer = getPortalContainer('primary-actions-container');
function Fab(_a) {
    var { extended, className, template, disabled } = _a, props = __rest(_a, ["extended", "className", "template", "disabled"]);
    // useLayoutEffect(() => {
    //   document.body.style.setProperty(
    //     '--topbar-extra-height',
    //     document.getElementById('fab-container').clientHeight / 2 + 'px'
    //   );
    // });
    useTopbarUpdater();
    const { icon, label, onClick } = Object.assign(Object.assign({}, templates[template]), props);
    const layout = useLayout();
    const _extended = extended !== null && extended !== void 0 ? extended : layout !== 'mobile';
    const content = (React.createElement(MuiFab, { disabled: disabled, className: classes(styles.root, className), variant: _extended ? 'extended' : undefined, color: "secondary", onClick: onClick },
        icon,
        _extended && label));
    return ReactDOM.createPortal(content, primaryActionsContainer);
}
export default Fab;

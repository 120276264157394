var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Radio as MuiRadio, FormControlLabel, } from '@material-ui/core';
import { useField, inputSubscription } from 'components/Form';
import useDerivedFieldProps from './useDerivedFieldProps';
function Radio(_a) {
    var { name, value: option, label, labelPlacement, onChange } = _a, props = __rest(_a, ["name", "value", "label", "labelPlacement", "onChange"]);
    const _b = useDerivedFieldProps(props), { className } = _b, derivedProps = __rest(_b, ["className"]);
    const { value, change, blur } = useField(name, { subscription: inputSubscription });
    const handleChange = useCallback(() => {
        change(option);
        onChange === null || onChange === void 0 ? void 0 : onChange(true);
    }, [onChange]);
    return (React.createElement(FormControlLabel, { className: className, label: label, labelPlacement: labelPlacement !== null && labelPlacement !== void 0 ? labelPlacement : 'end', control: React.createElement(MuiRadio, Object.assign({}, derivedProps, { checked: value === option, onChange: handleChange, onBlur: blur })) }));
}
const MemoRadio = React.memo(Radio);
export default MemoRadio;

import React from 'react';
import ReactDOM from 'react-dom';
import { Tooltip, IconButton, Input, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { store } from 'app';
import { useQueryParams } from 'hooks';
import useTopbarUpdater from 'hooks/useTopbarUpdater';
import { updateQueryParams } from 'utils';
import { getPortalContainer } from 'utils/contents';
import useDebouncedHandler from 'hooks/useDebouncedHandler';
import styles from './styles.module.scss';
const topbarSearchContainer = getPortalContainer('topbar-search');
const searchFieldSelector = ({ layout }) => layout.topbarSearchExpanded;
function TopbarSearch() {
    const { search: searchParam = '' } = useQueryParams();
    const [value, setValue] = useState(searchParam);
    useMemo(() => setValue(searchParam), [searchParam]);
    const setSearchParamDebounced = useDebouncedHandler((search) => updateQueryParams({ search }), 1000);
    const inputRef = useRef();
    const expanded = useSelector(searchFieldSelector, shallowEqual);
    const expand = useCallback(() => {
        store.dispatch({ type: 'EXPAND_TOPBAR_SEARCH' });
        inputRef.current.focus();
    }, []);
    const clear = useCallback(() => {
        setValue('');
        setSearchParamDebounced('');
        inputRef.current.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onChange = useCallback((event) => {
        const { value } = event.target;
        setValue(value);
        setSearchParamDebounced(value);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    const onBlur = useCallback(event => {
        if (!event.target.value)
            store.dispatch({ type: 'COLLAPSE_TOPBAR_SEARCH' });
    }, []);
    const startAdornment = expanded && (React.createElement(InputAdornment, { position: "start" },
        React.createElement(SearchIcon, { color: "action" })));
    const endAdornment = (!expanded && (React.createElement(Tooltip, { title: "\u041F\u043E\u0438\u0441\u043A" },
        React.createElement(IconButton, { onClick: expand },
            React.createElement(SearchIcon, null))))) ||
        (value && (React.createElement(Tooltip, { title: "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C" },
            React.createElement(IconButton, { onClick: clear },
                React.createElement(ClearIcon, null)))));
    const content = (React.createElement(Input, { inputRef: inputRef, className: !expanded ? styles.searchInputCollapsed : styles.searchInputExpanded, startAdornment: startAdornment, endAdornment: endAdornment && React.createElement(InputAdornment, { position: "end" }, endAdornment), value: value, onChange: onChange, onBlur: onBlur }));
    return ReactDOM.createPortal(content, topbarSearchContainer);
}
function _TopbarSearch() {
    useTopbarUpdater();
    return React.createElement(TopbarSearch, null);
}
export default _TopbarSearch;

import { setLocale } from 'yup';
import plural from 'utils/plural';
setLocale({
    mixed: {
        required: 'Необходимо указать значение',
    },
    string: {
        length: ({ length }) => `Значение должно состоять ровно из ${length} ${plural(length, 'символа', 'символов')}`,
    },
});

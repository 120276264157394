// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nCWq9UFtxhYT0IJ7_f89{position:relative;height:5px;width:100%;border-radius:50px;border:none;background:#e1e1e1;margin-top:7px}.U2QvX_L2Uzttx8z46z0w{height:100%;border-radius:inherit;transition:width .2s ease-in}", "",{"version":3,"sources":["webpack://./src/components/inputs/ProgressBar/ProgressBar.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CAEF,sBACE,WAAA,CACA,qBAAA,CACA,4BAAA","sourcesContent":[".progressBar {\n  position: relative;\n  height: 5px;\n  width: 100%;\n  border-radius: 50px;\n  border: none;\n  background: #e1e1e1;\n  margin-top: 7px;\n}\n.filler {\n  height: 100%;\n  border-radius: inherit;\n  transition: width 0.2s ease-in;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": "nCWq9UFtxhYT0IJ7_f89",
	"filler": "U2QvX_L2Uzttx8z46z0w"
};
export default ___CSS_LOADER_EXPORT___;

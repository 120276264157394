var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Avatar } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { http } from 'utils';
import styles from './styles.module.scss';
function fetchUserPicture(id) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield http.get(`/api/kbi/users/${id}/photo`);
        if (!(response === null || response === void 0 ? void 0 : response.ok))
            return;
        const blob = yield response.blob();
        const source = URL.createObjectURL(blob);
        return source;
    });
}
function UserAvatar({ id }) {
    const { data: localSource } = useQuery(['USER/PICTURE', id], () => fetchUserPicture(id));
    return (
    // <div className={styles.userAvatar}>
    //   <Avatar src={`/api/kbi/users/${id}/photo`}>
    //     <PersonIcon />
    //   </Avatar>
    //   <Avatar className={styles.placeholder}>
    //     <PersonIcon />
    //   </Avatar>
    // </div>
    React.createElement("div", { className: styles.userAvatar },
        React.createElement(Avatar, { src: localSource }, localSource || React.createElement(PersonIcon, null))));
}
export default React.memo(UserAvatar);

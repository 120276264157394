/* eslint-disable no-continue */
import React from 'react';
import { AppBar, IconButton, Toolbar, Badge } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { store } from 'app';
import { useLayout } from 'hooks';
import useTotalAlerts from 'hooks/useTotalAlerts';
import useTopbarUpdater from 'hooks/useTopbarUpdater';
import { renderPortalContainer } from 'utils/contents';
import styles from './styles.module.scss';
// function updateTopBarHeigthCSSVar(topBarEl: Element) {
//   const { height } = topBarEl.getBoundingClientRect();
//   document.body.style.setProperty('--topbar-base-height', height + 'px');
// }
// const observer = new MutationObserver(mutations => {
//   for (const mutation of mutations) {
//     const { type, target } = mutation;
//     if (type === 'childList') {
//       updateTopBarHeigthCSSVar(target as Element);
//     }
//   }
// });
// function observeTopBarEl(node: Element) {
//   if (node) {
//     updateTopBarHeigthCSSVar(node);
//     observer.observe(node, { childList: true });
//   } else observer.disconnect();
// }
const toggleLeftbar = () => {
    store.dispatch({ type: 'TOGGLE_LEFTBAR' });
};
const MenuButton = () => {
    const { unresponded } = useTotalAlerts();
    return (React.createElement(IconButton, { color: "inherit", onClick: toggleLeftbar, className: styles.menuButton },
        React.createElement(Badge, { classes: { badge: 'badge' }, badgeContent: unresponded },
            React.createElement(MenuIcon, null))));
};
const mainButton = renderPortalContainer('main-button');
const topbarTitle = renderPortalContainer('topbar-title');
const topbarSearch = renderPortalContainer('topbar-search');
const topbarProgress = renderPortalContainer('topbar-progress');
const toolbarItems = renderPortalContainer('toolbar-items');
const topbarField = renderPortalContainer('topbar-field');
const barTabs = renderPortalContainer('bar-tabs');
const topbarSelector = ({ layout: { topbarSearchExpanded: searchExpanded } }) => ({
    searchExpanded,
});
function TopBar() {
    useTopbarUpdater();
    const layout = useLayout();
    const { searchExpanded } = useSelector(topbarSelector, shallowEqual);
    const moveTitleDown = layout === 'mobile' && searchExpanded;
    const menuButton = layout === 'mobile' && React.createElement(MenuButton, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(AppBar
        // ref={observeTopBarEl}
        , { 
            // ref={observeTopBarEl}
            position: "fixed", id: "topbar", className: styles.topBar, color: layout === 'mobile' ? 'primary' : 'default' },
            React.createElement(Toolbar, null,
                React.createElement("span", null,
                    menuButton,
                    mainButton),
                !moveTitleDown && topbarTitle,
                topbarField,
                React.createElement("span", { className: "flex-separator" }),
                topbarSearch,
                toolbarItems),
            moveTitleDown && React.createElement(Toolbar, null, topbarTitle),
            layout !== 'mobile' && barTabs,
            topbarProgress),
        layout === 'mobile' && barTabs));
}
export default TopBar;

import { store } from 'app';
export function openMenu(key, anchorEl, data) {
    store.dispatch({
        type: 'OPEN_MENU',
        key,
        anchorEl,
        data,
    });
}
export function getMenuData() {
    return store.getState().layout.menu.data;
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".usfJ3Ozh8tyRUb9a7gOP{display:flex;justify-content:center;flex-direction:row;align-items:center;gap:12px;margin-top:12px}.tZHK44IraSJxAAt3eK_O{user-select:\"none\"}", "",{"version":3,"sources":["webpack://./src/components/Paginator/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEA,sBAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA,CACA,eAAA,CAGF,sBACE,kBAAA","sourcesContent":[".root {\n  display: flex;\n  // flex-wrap: wrap;\n  justify-content: center;\n  flex-direction: row;\n  align-items: center;\n  gap: 12px;\n  margin-top: 12px;\n}\n\n.title {\n  user-select: 'none';\n}\n\n// .select {\n//   margin-left: 8px;\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "usfJ3Ozh8tyRUb9a7gOP",
	"title": "tZHK44IraSJxAAt3eK_O"
};
export default ___CSS_LOADER_EXPORT___;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useFormState } from 'components/Form';
import { Form } from 'components';
import MapContainer from 'components/_Map';
import fetchSiteIndoorZoneImage from 'pages/sites/queries/fetchSiteIndoorZoneImage';
import { http } from 'utils';
import FloorSelect from './FloorSelect';
import SearchWorkerControl from './SearchWorkerControl';
import BeaconSwitcher from './BeaconSwitcher';
import styles from './styles.module.scss';
function IZMap(_a) {
    var _b;
    var { beacons, bounds, indoorPointMarkers, indoorPolylines, hideZonesWhenIndoor, polygons, circleMarkers, rootStyle, polylines, progressiveTrack = false } = _a, props = __rest(_a, ["beacons", "bounds", "indoorPointMarkers", "indoorPolylines", "hideZonesWhenIndoor", "polygons", "circleMarkers", "rootStyle", "polylines", "progressiveTrack"]);
    const { values } = useFormState();
    const { indoorZoneId } = values;
    const { data: indoorZone } = useQuery(['INDOOR_ZONE_MAP/INDOOR_ZONE', indoorZoneId], () => __awaiter(this, void 0, void 0, function* () {
        return (yield http.get('/api/indoor/mappictures/' + indoorZoneId));
    }), { enabled: Boolean(indoorZoneId), staleTime: 1000 * 60 * 10, keepPreviousData: false });
    const { data: zoneImage } = useQuery(['INDOOR_ZONE_MAP/ZONE_IMAGE', indoorZone === null || indoorZone === void 0 ? void 0 : indoorZone.id], () => fetchSiteIndoorZoneImage(indoorZone.id), { enabled: Boolean(indoorZone), staleTime: 1000 * 60 * 10, keepPreviousData: false });
    const corners = useMemo(() => {
        return indoorZoneId && indoorZone
            ? indoorZone === null || indoorZone === void 0 ? void 0 : indoorZone.coordinates.map(({ latitude, longitude }) => [latitude, longitude])
            : undefined;
    }, [indoorZoneId, indoorZone]);
    const _circleMarkers = useMemo(() => {
        return indoorZoneId && beacons && beacons.length > 0
            ? beacons
                .filter(b => b.indoorZoneId === indoorZoneId)
                .map(b => ({
                id: b.id,
                latlng: [b.latitude, b.longitude],
                radius: 4,
                pane: 'beacons',
            }))
            : undefined;
    }, [indoorZoneId, beacons]);
    const pointCircleMarkers = useMemo(() => {
        if (!indoorPointMarkers || !indoorZone || indoorPointMarkers.length === 0)
            return;
        return indoorPointMarkers
            .filter(b => b.floor === indoorZone.floor)
            .map(b => ({
            id: b.workerId + ':' + b.dateTime,
            latlng: [b.latitude, b.longitude],
            radius: 6,
            color: b.sos ? 'red' : 'blue',
            tooltip: { date: b.dateTime, workerFullName: b.workerFullName },
        }));
    }, [indoorZone, indoorPointMarkers]);
    return (React.createElement(MapContainer, Object.assign({ progressiveTrack: progressiveTrack, bounds: corners !== null && corners !== void 0 ? corners : bounds, circleMarkers: progressiveTrack ? undefined : (_b = pointCircleMarkers !== null && pointCircleMarkers !== void 0 ? pointCircleMarkers : _circleMarkers) !== null && _b !== void 0 ? _b : circleMarkers, style: rootStyle !== null && rootStyle !== void 0 ? rootStyle : { width: '100%', height: '500px' }, distortableImage: indoorZoneId && indoorZone && zoneImage ? zoneImage : undefined, editableImage: false, corners: corners, polygons: hideZonesWhenIndoor && indoorZoneId ? undefined : polygons, polylines: indoorZoneId && indoorPointMarkers && indoorPointMarkers.length !== 0 ? indoorPolylines : polylines }, props)));
}
function IndoorZonesMap(_a) {
    var { siteId, beacons, selectFirst, rootStyle, indoorPointMarkers, hideZonesWhenIndoor, selectedFloor, onFloorChange, mainMapWidget = false, markers } = _a, props = __rest(_a, ["siteId", "beacons", "selectFirst", "rootStyle", "indoorPointMarkers", "hideZonesWhenIndoor", "selectedFloor", "onFloorChange", "mainMapWidget", "markers"]);
    const [showBeacon, setShowBeacon] = useState(true);
    const [selectedWorker, setSelectedWorker] = useState();
    const onChangeShowBeacon = () => setShowBeacon(prev => !prev);
    useEffect(() => {
        setSelectedWorker(null);
    }, [siteId]);
    useEffect(() => {
        if (!(markers === null || markers === void 0 ? void 0 : markers.length) && selectedWorker) {
            setSelectedWorker(null);
        }
        if ((markers === null || markers === void 0 ? void 0 : markers.length) && selectedWorker) {
            const isWorkerOnMap = !!markers.find(el => el.id === selectedWorker.id);
            if (!isWorkerOnMap) {
                setSelectedWorker(null);
            }
        }
    }, [markers]);
    return (React.createElement("div", { className: styles.indoorZonesMap, style: rootStyle !== null && rootStyle !== void 0 ? rootStyle : { width: '100%', height: '500px' } },
        React.createElement(Form, { initialValues: { siteId }, enableReinitialize: true },
            selectedFloor && React.createElement(BeaconSwitcher, { showBeacon: showBeacon, onChange: onChangeShowBeacon }),
            React.createElement(IZMap, Object.assign({ hideZonesWhenIndoor: hideZonesWhenIndoor, indoorPointMarkers: indoorPointMarkers, beacons: showBeacon ? beacons : [], rootStyle: rootStyle }, props, { markers: markers, selectedWorker: selectedWorker })),
            mainMapWidget && (markers === null || markers === void 0 ? void 0 : markers.length) &&
                React.createElement(SearchWorkerControl, { workerList: markers || [], selectedWorker: selectedWorker, setSelectedWorker: setSelectedWorker }),
            React.createElement(FloorSelect, { onChange: onFloorChange, key: siteId, selectFirst: selectFirst, variant: "outlined" }))));
}
export default IndoorZonesMap;

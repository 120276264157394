import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import styles from './SearchWorkerControl.module.scss';
import useStyles from './Styles';
const SearchWorkerControl = ({ workerList, selectedWorker, setSelectedWorker, }) => {
    const classes = useStyles();
    const getInputLabel = () => {
        if (selectedWorker)
            return ' ';
        if (workerList === null || workerList === void 0 ? void 0 : workerList.length)
            return 'Все сотрудники';
        return 'Отсутствуют сотрудники на карте';
    };
    return (React.createElement(Autocomplete, { className: styles.w300, size: "small", value: selectedWorker || null, 
        // @ts-ignore
        onChange: (_, value) => setSelectedWorker(value), options: workerList, 
        // @ts-ignore
        getOptionLabel: option => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.tooltip) === null || _a === void 0 ? void 0 : _a.fullName; }, getOptionSelected: (option, value) => (option === null || option === void 0 ? void 0 : option.id) === (value === null || value === void 0 ? void 0 : value.id), noOptionsText: "\u041E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044E\u0442 \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u0438 \u043D\u0430 \u043A\u0430\u0440\u0442\u0435", renderInput: params => (React.createElement(TextField, Object.assign({}, params, { classes: { root: classes.root }, placeholder: getInputLabel() }))) }));
};
export default React.memo(SearchWorkerControl);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { http } from 'utils';
import styles from './styles.module.scss';
function fetchAbsentWorkersData(siteId) {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/dashboards/absentworkersinproject', {
            zoneGroupId: siteId,
            fields: 'fullName',
        });
        return result.sort((a, b) => a.fullName.localeCompare(b.fullName));
    });
}
function searchReducer(value, event) {
    var _a;
    return ((_a = event.target) === null || _a === void 0 ? void 0 : _a.value) || '';
}
function AbsentWorkersWidget({ siteId }) {
    const { data = [] } = useQuery(['WIDGETS/ABSENT_WORKERS', siteId], () => fetchAbsentWorkersData(siteId), {
        enabled: Boolean(siteId),
        refetchInterval: 10000,
    });
    const [searchValue, setSearchValue] = React.useReducer(searchReducer, '');
    const filteredData = useMemo(() => {
        const searchFragments = searchValue
            .replace(/[ ]+/g, ' ')
            .split(' ')
            .map(fragment => new RegExp(fragment, 'i'));
        return data.filter(worker => {
            return searchFragments
                .map(fragment => (worker === null || worker === void 0 ? void 0 : worker.fullName.search(fragment)) > -1)
                .every(val => val);
        });
    }, [data, searchValue]);
    return (React.createElement("div", { className: styles.absentWorkers }, siteId && (React.createElement(React.Fragment, null,
        React.createElement(Typography, null,
            'Всего: ', data === null || data === void 0 ? void 0 :
            data.length),
        React.createElement("div", null,
            React.createElement(TextField, { fullWidth: true, variant: "standard", label: "\u041F\u043E\u0438\u0441\u043A", value: searchValue, onChange: setSearchValue, InputProps: {
                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(IconButton, { size: "small", onClick: setSearchValue },
                            React.createElement(ClearIcon, { fontSize: "small" })))),
                } })),
        React.createElement("div", null, filteredData.map(worker => (React.createElement(Typography, { component: "div", key: worker === null || worker === void 0 ? void 0 : worker.fullName }, worker === null || worker === void 0 ? void 0 : worker.fullName))))))));
}
export default AbsentWorkersWidget;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Switch as MuiSwitch, FormControlLabel, } from '@material-ui/core';
import { useField, inputSubscription } from 'components/Form';
import useDerivedFieldProps from './useDerivedFieldProps';
function Switch(_a) {
    var { name, label, onChange, labelPlacement = 'start' } = _a, props = __rest(_a, ["name", "label", "onChange", "labelPlacement"]);
    const _b = useDerivedFieldProps(props), { className } = _b, derivedProps = __rest(_b, ["className"]);
    const { value, change, blur } = useField(name, { subscription: inputSubscription });
    const handleChange = useCallback((event) => {
        const { checked } = event.target;
        change(checked);
        onChange === null || onChange === void 0 ? void 0 : onChange(checked);
    }, [onChange]);
    return (React.createElement(FormControlLabel, { className: className, label: label, labelPlacement: labelPlacement, control: React.createElement(MuiSwitch, Object.assign({}, derivedProps, { checked: value !== null && value !== void 0 ? value : false, onChange: handleChange, onBlur: blur })) }));
}
const MemoSwitch = React.memo(Switch);
export default MemoSwitch;

// import { MenuItem, Select, SelectProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
// import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import styles from './index.module.scss';
function Paginator({ total = 0, limit = 10, offset = 0, 
// limitOptions = [5, 10, 15, 25, 50],
onChange, }) {
    const nextOffset = offset + limit;
    const firstIndex = offset + 1;
    const lastIndex = nextOffset < total ? nextOffset : total;
    const previous = () => {
        let prevOffset = offset - limit;
        prevOffset = prevOffset > 0 ? prevOffset : 0;
        onChange === null || onChange === void 0 ? void 0 : onChange(prevOffset, limit);
    };
    const next = () => onChange === null || onChange === void 0 ? void 0 : onChange(nextOffset, limit);
    // const changeLimit: SelectProps['onChange'] = (event) =>
    //   onChange?.(offset, event.target.value as number);
    return (React.createElement("div", { className: styles.root },
        React.createElement(Typography, { className: styles.title },
            "\u041F\u043E\u043A\u0430\u0437\u0430\u043D\u043E ",
            firstIndex,
            "-",
            lastIndex,
            " \u044D\u043B\u0435\u043C\u0435\u043D\u0442\u043E\u0432 \u0438\u0437 ",
            total),
        React.createElement(IconButton, { onClick: previous, disabled: offset === 0 },
            React.createElement(ChevronLeft, null)),
        React.createElement(IconButton, { onClick: next, disabled: offset + limit >= total },
            React.createElement(ChevronRight, null))));
}
export default React.memo(Paginator);

var _a, _b;
import { getQueryParams } from 'utils';
import { getLayout } from 'hooks/useLayout';
export const initialState = {
    leftbarOpen: (_a = JSON.parse(localStorage.getItem('leftbarOpen'))) !== null && _a !== void 0 ? _a : getLayout(window.innerWidth) === 'desktop',
    rightbarOpen: (_b = JSON.parse(localStorage.getItem('rightbarOpen'))) !== null && _b !== void 0 ? _b : getLayout(window.innerWidth) === 'desktop',
    menu: {
        key: null,
        anchorEl: null,
        data: null,
    },
    dialog: {
        key: null,
        data: null,
    },
    topbarSearchExpanded: Boolean(getQueryParams().search),
};
const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CLOSE_LEFTBAR': {
            return Object.assign(Object.assign({}, state), { leftbarOpen: false });
        }
        case 'OPEN_LEFTBAR': {
            return Object.assign(Object.assign({}, state), { leftbarOpen: true });
        }
        case 'TOGGLE_LEFTBAR': {
            return Object.assign(Object.assign({}, state), { leftbarOpen: !state.leftbarOpen });
        }
        case 'CLOSE_RIGHTBAR': {
            return Object.assign(Object.assign({}, state), { rightbarOpen: false });
        }
        case 'TOGGLE_RIGHTBAR': {
            return Object.assign(Object.assign({}, state), { rightbarOpen: !state.rightbarOpen });
        }
        case 'OPEN_MENU': {
            const { key, anchorEl, data } = action;
            return Object.assign(Object.assign({}, state), { menu: { key, anchorEl, data } });
        }
        case 'CLOSE_MENU': {
            return Object.assign(Object.assign({}, state), { menu: {
                    key: null,
                    anchorEl: null,
                    data: null,
                } });
        }
        case 'OPEN_DIALOG': {
            const { key, data } = action;
            return Object.assign(Object.assign({}, state), { dialog: { key, data } });
        }
        case 'CLOSE_DIALOG': {
            return Object.assign(Object.assign({}, state), { dialog: {
                    key: null,
                    data: null,
                } });
        }
        case 'EXPAND_TOPBAR_SEARCH': {
            return Object.assign(Object.assign({}, state), { topbarSearchExpanded: true });
        }
        case 'COLLAPSE_TOPBAR_SEARCH': {
            return Object.assign(Object.assign({}, state), { topbarSearchExpanded: false });
        }
        default:
            return state;
    }
};
export default layoutReducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sW988Z9Eh7yPiPIXX0JU{position:absolute;top:calc(50% - 20px);z-index:101;left:calc(50% - 20px)}", "",{"version":3,"sources":["webpack://./src/components/CircularProgress/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,oBAAA,CACA,WAAA,CACA,qBAAA","sourcesContent":[".indicator {\n  position: absolute;\n  top: calc(50% - 20px);\n  z-index: 101;\n  left: calc(50% - 20px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indicator": "sW988Z9Eh7yPiPIXX0JU"
};
export default ___CSS_LOADER_EXPORT___;

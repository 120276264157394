var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useContext } from 'react';
import ReactDOM from 'react-dom';
import { Tooltip, IconButton } from '@material-ui/core';
import { MenuItem } from 'components/Menu';
import useTopbarUpdater from 'hooks/useTopbarUpdater';
import { getPortalContainer } from 'utils/contents';
import ToolbarItemContext from './ToolbarItemContext';
import templates from './templates';
const mainButtonContainer = getPortalContainer('main-button');
const observer = new MutationObserver(mutations => {
    for (const { type, target } of mutations) {
        if (type === 'childList') {
            if (target.childNodes.length > 0)
                document.body.style.setProperty('--menu-button-display', 'none');
            else
                document.body.style.setProperty('--menu-button-display', undefined);
        }
    }
});
observer.observe(mainButtonContainer, { childList: true });
const ToolbarItem = React.forwardRef((_a, ref) => {
    var { template, disabled, className } = _a, props = __rest(_a, ["template", "disabled", "className"]);
    useTopbarUpdater();
    const { main, icon, text, onClick, Wrapper = React.Fragment, } = Object.assign(Object.assign({}, templates[template]), props);
    const isMenuItem = useContext(ToolbarItemContext);
    if (isMenuItem && !main)
        return (React.createElement(Wrapper, null,
            React.createElement(MenuItem, { disabled: disabled, ref: ref, className: className, icon: icon, text: text, onClick: disabled ? undefined : onClick })));
    const content = (React.createElement(Wrapper, null,
        React.createElement(Tooltip, { title: text },
            React.createElement("span", null, onClick ? React.createElement(IconButton, { disabled: disabled, className: className, onClick: onClick }, icon)
                : icon))));
    if (main)
        return ReactDOM.createPortal(content, mainButtonContainer);
    return content;
});
export default React.memo(ToolbarItem);

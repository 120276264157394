// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wp9a9ur3wOlHvz81LzR2{text-align:center}.Evr9Ksj7UadMYOaSR_Kh{position:absolute;top:0px;left:0px;width:100%;height:100%}@media only screen and (min-width: 600px){.col-2 .MuiListItemText-root{padding-left:8px}}@media only screen and (max-width: 600px){.col-2 .MuiListItemText-root{margin-top:-6px}}.col-2 .MuiListItemText-primary{color:rgba(0,0,0,.54);font-size:14px;font-weight:500}.MuiListItemText-multiline{margin-top:4px;margin-bottom:4px}.oCQE1zL2DgoVmKT3xYfo{color:rgba(0,0,0,.54)}", "",{"version":3,"sources":["webpack://./src/components/List/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CAKE,0CADF,6BAEI,gBAAA,CAAA,CAEF,0CAJF,6BAKI,eAAA,CAAA,CAGJ,gCACE,qBAAA,CACA,cAAA,CACA,eAAA,CAIJ,2BACE,cAAA,CACA,iBAAA,CAGF,sBACE,qBAAA","sourcesContent":["@import '../../theme.scss';\n\n.empty-text {\n  text-align: center;\n}\n\n.click-action {\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n  height: 100%;\n}\n\n:global(.col-2) {\n  :global(.MuiListItemText-root) {\n    @media only screen and (min-width: 600px) {\n      padding-left: 8px;\n    }\n    @media only screen and (max-width: 600px) {\n      margin-top: -6px;\n    }\n  }\n  :global(.MuiListItemText-primary) {\n    color: rgba(0, 0, 0, 0.54);\n    font-size: 14px;\n    font-weight: 500;\n  }\n}\n\n:global(.MuiListItemText-multiline) {\n  margin-top: 4px;\n  margin-bottom: 4px;\n}\n\n.cascading-icon {\n  color: rgba(0, 0, 0, 0.54);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty-text": "wp9a9ur3wOlHvz81LzR2",
	"emptyText": "wp9a9ur3wOlHvz81LzR2",
	"click-action": "Evr9Ksj7UadMYOaSR_Kh",
	"clickAction": "Evr9Ksj7UadMYOaSR_Kh",
	"cascading-icon": "oCQE1zL2DgoVmKT3xYfo",
	"cascadingIcon": "oCQE1zL2DgoVmKT3xYfo"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (min-width: 960px){.gcWdyLPfWAvL1TkMaWas svg{margin-right:8px}}", "",{"version":3,"sources":["webpack://./src/components/Fab/styles.module.scss","webpack://./src/theme.scss"],"names":[],"mappings":"AAGE,0CACE,0BCmBF,gBAAA,CAAA","sourcesContent":["@import '../../theme.scss';\n\n.root {\n  @media only screen and (min-width: $mobile-width) {\n    svg {\n      @include leading-icon;\n    }\n  }\n}","// $color-secondary-main: #2c7cb2;\n// $color-secondary-main: hsl(184deg 43% 36%);\n// $color-secondary-light: #66abe4;\n$color-secondary-main: hsl(222deg 27% 37%);\n$color-secondary-light: hsl(210deg 48% 67%);\n\n// $color-primary-main: #242f44;\n// $color-primary-main: hsl(219deg 24% 16%);\n$color-primary-main: hsl(222deg 27% 37%);\n$color-primary-dark: hsl(226deg 26% 16%);\n\n$color-alert: #f34151;\n\n$maximized-leftbar-width: 300px;\n$minimized-leftbar-width: 64px;\n$rightbar-width: 400px;\n\n$mobile-width: 960px;\n$tablet-width: 1280px;\n\n$topbar-z-index: 1100;\n\n@mixin leading-icon {\n  margin-right: 8px;\n}\n\n$color-success: hsl(105deg 45% 70%);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "gcWdyLPfWAvL1TkMaWas"
};
export default ___CSS_LOADER_EXPORT___;

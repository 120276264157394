import { getEndLayoutsState, getStoreLayouts } from 'pages/dashboard/utils';
import widgets from 'pages/dashboard/widgets';
const allItemKeys = Object.keys(widgets);
export const initialState = {
    layouts: getStoreLayouts(),
    isLocked: localStorage.getItem('dashboard/is-locked') === 'true',
    selectedSiteId: localStorage.getItem('dashboard/selected-site-id'),
};
let removedLayouts = {};
const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LAYOUTS': {
            const { layouts } = action;
            return Object.assign(Object.assign({}, state), { layouts });
        }
        case 'TOGGLE_ITEM_DISPLAY_STATE': {
            const { itemKey } = action;
            const { layouts, currentBreakpoint } = state;
            if (itemKey === 'ALL') {
                const itemKeys = Object.keys(layouts);
                if (itemKeys.length === allItemKeys.length) {
                    removedLayouts = layouts;
                    return Object.assign(Object.assign({}, state), { layouts: {} });
                }
                const _layouts = removedLayouts;
                removedLayouts = {};
                return Object.assign(Object.assign({}, state), { layouts: _layouts });
            }
            const itemLayout = layouts[itemKey];
            if (itemLayout === undefined)
                return Object.assign(Object.assign({}, state), { layouts: Object.assign(Object.assign({}, layouts), { [itemKey]: removedLayouts[itemKey] || getEndLayoutsState(itemKey, currentBreakpoint) }) });
            removedLayouts[itemKey] = itemLayout;
            const _layouts = Object.assign({}, layouts);
            delete _layouts[itemKey];
            return Object.assign(Object.assign({}, state), { layouts: _layouts });
        }
        case 'TOGGLE_LOCKED_STATE': {
            return Object.assign(Object.assign({}, state), { isLocked: !state.isLocked });
        }
        case 'SET_SELECTED_SITE_ID': {
            const { siteId } = action;
            return Object.assign(Object.assign({}, state), { selectedSiteId: siteId });
        }
        default:
            return state;
    }
};
export default dashboardReducer;

import { QueryClient } from 'react-query';
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            cacheTime: 0,
            staleTime: 0,
        },
    },
});
export default queryClient;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Tab, BottomNavigationAction } from '@material-ui/core';
import { useLayout } from 'hooks';
import useTopbarUpdater from 'hooks/useTopbarUpdater';
function BarTab(_a) {
    var { icon, value, label, disableMobile } = _a, props = __rest(_a, ["icon", "value", "label", "disableMobile"]);
    useTopbarUpdater();
    const { style, onChange, selected, 
    // only BottomNavigationAction props
    showLabel, 
    // only Tab props
    fullWidth, indicator, selectionFollowsFocus, textColor, } = props;
    const layout = useLayout();
    if (layout === 'mobile' && !disableMobile)
        return React.createElement(BottomNavigationAction, Object.assign({}, { icon, value, label, onChange, selected, showLabel, style }));
    return (React.createElement(Tab, Object.assign({}, {
        style,
        icon,
        value,
        label,
        onChange,
        selected,
        fullWidth,
        indicator,
        selectionFollowsFocus,
        textColor,
    })));
}
export default BarTab;

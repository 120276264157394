import { store } from 'app';
export function openDialog(key, data) {
    store.dispatch({
        type: 'OPEN_DIALOG',
        key,
        data,
    });
}
export function closeDialog() {
    store.dispatch({ type: 'CLOSE_DIALOG' });
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Autocomplete } from 'components';
import { http } from 'utils';
const fields = 'id,displayName';
function getPositionListForSelect() {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/worker-professions', {
            fields,
        });
        return result;
    });
}
const getPositionOptionValue = ({ id }) => id;
const getPositionOptionLabel = ({ displayName }) => displayName;
function PositionSelect(_a) {
    var { name, label, multiple, disabled, filterValues } = _a, props = __rest(_a, ["name", "label", "multiple", "disabled", "filterValues"]);
    const { data: positionList = [], isFetching } = useQuery('SELECT/POSITION', getPositionListForSelect, { cacheTime: 10 * 60 * 1000 });
    return (React.createElement(Autocomplete, Object.assign({ name: name || 'positionId', multiple: multiple, label: (label !== null && label !== void 0 ? label : multiple) ? 'Должности' : 'Должность', getOptionValue: getPositionOptionValue, getOptionLabel: getPositionOptionLabel, options: positionList, disabled: disabled !== null && disabled !== void 0 ? disabled : isFetching, filterValues: filterValues !== null && filterValues !== void 0 ? filterValues : !isFetching }, props)));
}
export default React.memo(PositionSelect);

import { ListItem, ListItemIcon, ListItemText, Collapse, List, Menu } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { history } from 'app';
import isReactElement from 'utils/isReactElement';
import isCurrentPath from './isCurrentPath';
import useLeftbarMinimized from '../useLeftbarMinimized';
import styles from './styles.module.scss';
function ExpandableNavItem({ icon = null, text = '', path, children }) {
    const [expanded, setExpanded] = useState(isCurrentPath(location.pathname, path));
    const [anchorEl, setAnchorEl] = useState(null);
    const toggleExpanded = useCallback(() => {
        setExpanded((_expanded) => !_expanded);
    }, []);
    useEffect(() => {
        const unregister = history.listen((location) => {
            if (isCurrentPath(location.pathname, path))
                setExpanded(true);
            else
                setExpanded(false);
        });
        return unregister;
    }, [path]);
    const leftbarMinimized = useLeftbarMinimized();
    if (leftbarMinimized) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ListItem, { className: styles.navItem, selected: expanded, button: true, onClick: event => setAnchorEl(event.target) },
                React.createElement(ListItemIcon, null, icon)),
            React.createElement(Menu, { open: Boolean(anchorEl), anchorEl: anchorEl, onClose: () => setAnchorEl(null) }, React.Children.map(children, child => {
                if (!isReactElement(child))
                    return null;
                return React.cloneElement(child, { isMenuItem: true, onClick: () => setAnchorEl(null) });
            }))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { className: styles.navItem, button: true, onClick: toggleExpanded },
            React.createElement(ListItemIcon, null, icon),
            React.createElement(ListItemText, { primary: text }),
            expanded ? React.createElement(ExpandLessIcon, null) : React.createElement(ExpandMoreIcon, null)),
        React.createElement(Collapse, { in: expanded },
            React.createElement(List, { component: "div", disablePadding: true }, children))));
}
export default ExpandableNavItem;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TextField } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { useField, shouldDisplayFieldError, fieldSubscription } from 'components/Form';
import { useMutableState } from 'hooks';
import { classes } from 'utils';
import useDerivedFieldProps from './useDerivedFieldProps';
import styles from './styles.module.scss';
function Autocomplete(_a) {
    var { name, validate, selectFirst, filterValues, getOptionValue, fullWidth, multiple, getOptionLabel, renderOption, options, onChange } = _a, props = __rest(_a, ["name", "validate", "selectFirst", "filterValues", "getOptionValue", "fullWidth", "multiple", "getOptionLabel", "renderOption", "options", "onChange"]);
    const _b = useDerivedFieldProps(props), { className, disabled } = _b, derivedProps = __rest(_b, ["className", "disabled"]);
    const field = useField(name, { validate, subscription: fieldSubscription });
    const { value, change, blur, error } = field;
    const shouldDisplayError = shouldDisplayFieldError(field);
    const renderInput = (params) => (React.createElement(TextField, Object.assign({}, derivedProps, params, { error: shouldDisplayError, helperText: shouldDisplayError ? error : props.helperText })));
    // alters field value by deleting items that has no corresponding option value
    useEffect(() => {
        if (!filterValues || options == null || value == null)
            return;
        const optionValues = options.map(getOptionValue);
        if (value instanceof Array) {
            if (value.length === 0)
                return;
            const filteredValue = value.filter(val => optionValues.includes(val));
            if (!shallowEqual(filteredValue, value))
                change(filteredValue);
        }
        else if (!optionValues.includes(value))
            change(null);
    }, [filterValues, getOptionValue, options]);
    //
    // setlectFirst routine
    const current = useMutableState();
    if (current.firstSelected === undefined) {
        current.firstSelected = !(value instanceof Array ? value.length === 0 : value == null);
    }
    useEffect(() => {
        if (!selectFirst || current.firstSelected)
            return;
        const firstOption = options === null || options === void 0 ? void 0 : options[0];
        if (firstOption === undefined)
            return;
        const value = getOptionValue(firstOption);
        change(multiple ? [value] : value);
        current.firstSelected = true;
    }, [selectFirst, options, multiple, getOptionValue]);
    //
    const handleChange = useCallback((event, selected) => {
        const nextValue = selected instanceof Array
            ? selected.map(getOptionValue)
            : selected == null ? null : getOptionValue(selected);
        change(nextValue);
        onChange === null || onChange === void 0 ? void 0 : onChange(nextValue);
    }, [getOptionValue, onChange]);
    const selectedOptions = useMemo(() => {
        if (multiple && value instanceof Array)
            return options === null || options === void 0 ? void 0 : options.filter(option => value.includes(getOptionValue(option)));
        const option = options === null || options === void 0 ? void 0 : options.find(option => getOptionValue(option) === value);
        if (!multiple)
            return option;
        if (option !== undefined)
            return [option];
    }, [multiple, getOptionValue, options, value]);
    return (React.createElement(MuiAutocomplete, Object.assign({ className: classes(styles.autocomplete, className), filterSelectedOptions: multiple, renderOption: renderOption || getOptionLabel, value: selectedOptions !== null && selectedOptions !== void 0 ? selectedOptions : (multiple ? [] : null), onChange: handleChange, onBlur: blur }, {
        multiple, fullWidth, disabled,
        getOptionLabel, renderInput,
        options,
    })));
}
const MemoAutocomplete = React.memo(Autocomplete);
export default MemoAutocomplete;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { classes } from 'utils';
import styles from './styles.module.scss';
const workerIcon = (React.createElement(Tooltip, { title: "\u0421\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A" },
    React.createElement(PersonIcon, null)));
function ListItemWorker(_a) {
    var { data, className } = _a, props = __rest(_a, ["data", "className"]);
    if (data == null)
        return null;
    return (React.createElement("div", Object.assign({ className: classes(styles.iconWithLabel, className) }, props),
        workerIcon,
        React.createElement("p", null, data.fullName)));
}
export default React.memo(ListItemWorker);

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Tooltip } from '@material-ui/core';
import EnterpriseIcon from '@material-ui/icons/Business';
import { classes } from 'utils';
import styles from './styles.module.scss';
const enterpriseIcon = (React.createElement(Tooltip, { title: "\u041A\u043E\u043C\u043F\u0430\u043D\u0438\u044F" },
    React.createElement(EnterpriseIcon, null)));
function ListItemEnterprise(_a) {
    var { data, className } = _a, props = __rest(_a, ["data", "className"]);
    if (data == null)
        return null;
    return (React.createElement("div", Object.assign({ className: classes(styles.iconWithLabel, className) }, props),
        enterpriseIcon,
        React.createElement("p", null, data.displayName)));
}
export default React.memo(ListItemEnterprise);

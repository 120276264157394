import { Menu, MenuItem, ListItem, ListItemIcon, ListItemText, IconButton, List, Divider, ListItemAvatar, ListItemSecondaryAction, } from '@material-ui/core';
import AccountIcon from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CreateIcon from '@material-ui/icons/Create';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import { history, store } from 'app';
import { classes } from 'utils';
import useLeftbarMinimized from './useLeftbarMinimized';
import styles from './styles.module.scss';
function Profile() {
    const user = useSelector((state) => state.auth);
    const leftbarMinimized = useLeftbarMinimized();
    const [anchorEl, setAnchorEl] = useState(null);
    const openProfileMenu = useCallback(event => {
        setAnchorEl(event.currentTarget);
    }, []);
    const closeProfileMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);
    const startEditProfile = useCallback(() => {
        setAnchorEl(null);
        const user = store.getState().auth;
        if (user === null || user === void 0 ? void 0 : user.id)
            history.push(`/admin/users/${user === null || user === void 0 ? void 0 : user.id}`);
    }, []);
    const logout = useCallback(() => {
        setAnchorEl(null);
        store.dispatch({ type: 'SIGNOUT' });
        localStorage.removeItem('user');
    }, []);
    if (!user)
        return null;
    const { name, login, enterprise, role, phone, eMail } = user;
    if (leftbarMinimized)
        return (React.createElement("span", { className: styles.profile },
            React.createElement(List, null,
                React.createElement(ListItem, { disableGutters: true },
                    React.createElement(ListItemAvatar, null,
                        React.createElement(AccountIcon, { className: classes(styles.avatarIcon, styles.avatarIconMinimized) }))),
                React.createElement(Divider, { className: styles.divider }))));
    return (React.createElement("span", { className: styles.profile },
        React.createElement(List, { className: styles.info },
            React.createElement(ListItem, null,
                React.createElement(ListItemAvatar, null,
                    React.createElement(AccountIcon, { className: styles.avatarIcon }))),
            React.createElement(ListItem, null,
                React.createElement(ListItemText, { className: styles.text, primary: name, secondary: eMail }),
                React.createElement(ListItemSecondaryAction, null,
                    React.createElement(IconButton, { onClick: openProfileMenu, color: "inherit" },
                        React.createElement(ArrowDropDownIcon, null)))),
            React.createElement(Divider, { className: styles.divider })),
        React.createElement(Menu, { anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: closeProfileMenu },
            React.createElement(MenuItem, { onClick: startEditProfile },
                React.createElement(ListItemIcon, null,
                    React.createElement(CreateIcon, null)),
                React.createElement(ListItemText, null, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C")),
            React.createElement(Divider, null),
            React.createElement(List, null,
                name && React.createElement(ListItem, null, name),
                login && React.createElement(ListItem, null, login),
                enterprise && React.createElement(ListItem, null, enterprise.name),
                role && React.createElement(ListItem, null, role.name),
                phone && React.createElement(ListItem, null, phone),
                eMail && React.createElement(ListItem, null, eMail)),
            React.createElement(Divider, null),
            React.createElement(MenuItem, { onClick: logout },
                React.createElement(ListItemIcon, { className: styles.logoutIcon },
                    React.createElement(LogOutIcon, null)),
                React.createElement(ListItemText, null, "\u0412\u044B\u0439\u0442\u0438")))));
}
export default Profile;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TextField as MuiTextField } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { useField, shouldDisplayFieldError, fieldSubscription } from 'components/Form';
import useDerivedFieldProps from './useDerivedFieldProps';
function TextField(_a) {
    var { name, validate, maskedInputProps, maxLength, onChange } = _a, props = __rest(_a, ["name", "validate", "maskedInputProps", "maxLength", "onChange"]);
    const _b = useDerivedFieldProps(props), { readOnly } = _b, derivedProps = __rest(_b, ["readOnly"]);
    const field = useField(name, { validate, subscription: fieldSubscription });
    const { value, change, blur, error } = field;
    const handleChange = useCallback((event) => {
        const { value } = event.target;
        change(value);
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    }, [onChange]);
    const displayError = shouldDisplayFieldError(field);
    const maskedInput = useMemo(() => {
        if ((maskedInputProps === null || maskedInputProps === void 0 ? void 0 : maskedInputProps.mask) == null)
            return;
        return (_a) => {
            var { inputRef } = _a, _props = __rest(_a, ["inputRef"]);
            return (React.createElement(MaskedInput, Object.assign({ ref: ref => { var _a; return inputRef((_a = ref === null || ref === void 0 ? void 0 : ref.inputElement) !== null && _a !== void 0 ? _a : null); }, placeholderChar: "_", showMask: true }, maskedInputProps, _props)));
        };
    }, [maskedInputProps]);
    return (React.createElement(MuiTextField, Object.assign({}, derivedProps, { value: value !== null && value !== void 0 ? value : '', onChange: handleChange, onBlur: blur, error: displayError, helperText: displayError ? error : props.helperText, 
        // variant={readOnly ? 'standard' : props.variant}
        // required={readOnly ? false : props.required}
        InputProps: Object.assign({ inputComponent: maskedInput }, props.InputProps), inputProps: Object.assign({ maxLength }, props.inputProps) })));
}
const MemoTextField = React.memo(TextField);
export default MemoTextField;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QcQ41wrqkiCkqBGMl1lI{padding:0 25px 25px 25px;height:100%}.e9yJhRXQl4205Tinx60h{font-style:normal;font-weight:normal;font-size:20px;line-height:23px;color:var(--color-title);opacity:.8}.fp8Et5gCcSW241PmLM0s{font-style:normal;font-weight:normal;font-size:13px;line-height:15px;color:var(--color-text);margin:10px 0}.dUv1zXDk2ztEREoeYz1A{color:var(--color-blue);font-size:13px;line-height:15px}.JKcEKlR8TptGJIWABobt{font-size:13px;line-height:15px;color:var(--color-text)}.JKcEKlR8TptGJIWABobt span{padding:0 5px 0 5px}.erxuJDoCLSMpVhZTNGlW{margin-top:10px}", "",{"version":3,"sources":["webpack://./src/pages/dashboard/widgets/BiometryWidget.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,WAAA,CAGF,sBACE,iBAAA,CACA,kBAAA,CACA,cAAA,CACA,gBAAA,CACA,wBAAA,CACA,UAAA,CAGF,sBACE,iBAAA,CACA,kBAAA,CACA,cAAA,CACA,gBAAA,CACA,uBAAA,CACA,aAAA,CAGF,sBACE,uBAAA,CACA,cAAA,CACA,gBAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,uBAAA,CACA,2BACE,mBAAA,CAIJ,sBACE,eAAA","sourcesContent":[".container {\n  padding: 0 25px 25px 25px;\n  height: 100%;\n}\n\n.title {\n  font-style: normal;\n  font-weight: normal;\n  font-size: 20px;\n  line-height: 23px;\n  color: var(--color-title);\n  opacity: 0.8;\n}\n\n.text {\n  font-style: normal;\n  font-weight: normal;\n  font-size: 13px;\n  line-height: 15px;\n  color: var(--color-text);\n  margin: 10px 0;\n}\n\n.link {\n  color: var(--color-blue);\n  font-size: 13px;\n  line-height: 15px;\n}\n\n.btn {\n  font-size: 13px;\n  line-height: 15px;\n  color: var(--color-text);\n  span {\n    padding: 0 5px 0 5px;\n  }\n}\n\n.list {\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "QcQ41wrqkiCkqBGMl1lI",
	"title": "e9yJhRXQl4205Tinx60h",
	"text": "fp8Et5gCcSW241PmLM0s",
	"link": "dUv1zXDk2ztEREoeYz1A",
	"btn": "JKcEKlR8TptGJIWABobt",
	"list": "erxuJDoCLSMpVhZTNGlW"
};
export default ___CSS_LOADER_EXPORT___;

import { Grid, Tooltip } from '@material-ui/core';
import Charge20Icon from '@material-ui/icons/Battery20';
import Charge30Icon from '@material-ui/icons/Battery30';
import Charge50Icon from '@material-ui/icons/Battery50';
import Charge60Icon from '@material-ui/icons/Battery60';
import Charge80Icon from '@material-ui/icons/Battery80';
import Charge90Icon from '@material-ui/icons/Battery90';
import ChargeFullIcon from '@material-ui/icons/BatteryFull';
import ChargeUnknownIcon from '@material-ui/icons/BatteryUnknown';
import PackageTimeIcon from '@material-ui/icons/Event';
import PutOnIcon from '@material-ui/icons/Link';
import PutOffIcon from '@material-ui/icons/LinkOff';
import LocationIcon from '@material-ui/icons/MyLocation';
import AlertIcon from '@material-ui/icons/NotificationImportant';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import TimeFromLastPointIcon from '@material-ui/icons/Schedule';
import WorkShiftIcon from '@material-ui/icons/Timelapse';
import WiifOnIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import { formatDuration } from 'utils';
export function ListItemPowerIndicator({ value }) {
    return (React.createElement(Tooltip, { title: value ? 'Включены' : 'Выключены' },
        React.createElement(PowerIcon, { style: { color: value ? 'hsl(105deg 40% 65%)' : '#f34151' } })));
}
export function ListItemPutOnIndicator({ value }) {
    return value == null ? null : value ? (React.createElement(Tooltip, { title: "\u041E\u0434\u0435\u0442\u044B" },
        React.createElement(PutOnIcon, { style: { color: 'hsl(105deg 40% 65%)' } }))) : (React.createElement(Tooltip, { title: "\u0421\u043D\u044F\u0442\u044B" },
        React.createElement(PutOffIcon, { style: { color: '#f34151' } })));
}
export function ListItemWorkshiftTimings({ start, end }) {
    return (React.createElement(Grid, { container: true, wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Tooltip, { title: "\u0412\u0440\u0435\u043C\u044F \u043D\u0430\u0447\u0430\u043B\u0430 \u0438 \u043A\u043E\u043D\u0446\u0430 \u0441\u043C\u0435\u043D\u044B" },
                React.createElement(WorkShiftIcon, { style: { fontSize: '32px' } }))),
        React.createElement(Grid, { item: true, style: { fontSize: '12px' } },
            React.createElement("p", null, start ? new Date(start).toLocaleString('ru') : '-'),
            React.createElement("p", null, end ? new Date(end).toLocaleString('ru') : '-'))));
}
export function ListItemLastPackageTime({ value }) {
    return (React.createElement(Grid, { container: true, alignItems: "center", style: { fontSize: '12px' }, wrap: "nowrap" },
        React.createElement(Tooltip, { title: "\u0412\u0440\u0435\u043C\u044F \u043F\u043E\u0441\u043B\u0435\u0434\u043D\u0435\u0433\u043E \u043F\u0430\u043A\u0435\u0442\u0430" },
            React.createElement(PackageTimeIcon, { fontSize: "small" })),
        new Date(value).toLocaleString('ru')));
}
export function ListItemLastPointDuration({ value }) {
    return (React.createElement(Grid, { container: true, alignItems: "center", wrap: "nowrap" },
        React.createElement(Tooltip, { title: "\u0412\u0440\u0435\u043C\u044F \u0441 \u043C\u043E\u043C\u0435\u043D\u0442\u0430 \u043F\u0435\u0440\u0435\u0434\u0430\u0447\u0438 \u043F\u043E\u0441\u043B\u0435\u0434\u043D\u0435\u0439 \u043A\u043E\u043E\u0440\u0434\u0438\u043D\u0430\u0442\u044B" },
            React.createElement(TimeFromLastPointIcon, { fontSize: "small" })),
        formatDuration(value)));
}
export function ListItemLastAlertTime({ value }) {
    return (React.createElement(Grid, { item: true },
        React.createElement(Grid, { container: true, alignItems: "center", wrap: "nowrap" },
            React.createElement(Tooltip, { title: "\u0412\u0440\u0435\u043C\u044F \u043F\u043E\u0441\u043B\u0435\u0434\u043D\u0435\u0433\u043E \u0441\u0438\u0433\u043D\u0430\u043B\u0430 \u0442\u0440\u0435\u0432\u043E\u0433\u0438" },
                React.createElement(AlertIcon, { fontSize: "small" })),
            new Date(value).toLocaleString('ru'))));
}
export function ListItemWifiIndicator({ value }) {
    return value ? (React.createElement(Tooltip, { title: "\u041F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u043E \u043A Wifi" },
        React.createElement(WiifOnIcon, { fontSize: "small" }))) : (React.createElement(Tooltip, { title: "\u041D\u0435\u0442 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u044F \u043A Wifi" },
        React.createElement(WifiOffIcon, { fontSize: "small" })));
}
function getBatteryChargeIcon(charge) {
    if (!charge)
        return ChargeUnknownIcon;
    if (charge > 90)
        return ChargeFullIcon;
    if (charge > 80 && charge <= 90)
        return Charge90Icon;
    if (charge > 70 && charge <= 80)
        return Charge80Icon;
    if (charge > 55 && charge <= 70)
        return Charge60Icon;
    if (charge > 35 && charge <= 55)
        return Charge50Icon;
    if (charge > 20 && charge <= 35)
        return Charge30Icon;
    if (charge > 0 && charge <= 20)
        return Charge20Icon;
}
export function ListItemBatteryCharge({ value }) {
    const BatteryChargeIcon = getBatteryChargeIcon(value);
    return (React.createElement(Grid, { container: true, alignItems: "center", wrap: "nowrap" },
        React.createElement(Tooltip, { title: "\u0417\u0430\u0440\u044F\u0434 \u0431\u0430\u0442\u0430\u0440\u0435\u0438" },
            React.createElement(BatteryChargeIcon, { fontSize: "small" })),
        value && value + '%'));
}
export function ListItemCoordinates({ latitude, longitude, accuracy, valid, time, }) {
    return (React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Tooltip, { title: valid
                    ? 'Валидные координаты, их точность и время передачи'
                    : 'Невалидные координаты, время их передачи' },
                React.createElement(LocationIcon, { style: {
                        color: valid ? 'hsl(105deg 40% 65%)' : '#f34151',
                        fontSize: '32px',
                    } }))),
        React.createElement(Grid, { item: true, style: { fontSize: '12px' } },
            React.createElement("p", null,
                latitude,
                ", ",
                longitude,
                " ",
                accuracy && React.createElement("span", null,
                    "\u00B1 ",
                    accuracy)),
            React.createElement("p", null, new Date(time).toLocaleString('ru')))));
}

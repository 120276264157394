export const initialState = { head: [], body: [] };
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PRINT_DATA': {
            const { head, body } = action;
            return { head, body };
        }
        default:
            return state;
    }
};
export default authReducer;

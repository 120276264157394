// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xUUakjcUoS9DOVomYMpN{width:300px;height:44px;position:absolute;top:10px;left:104px;z-index:500}", "",{"version":3,"sources":["webpack://./src/components/IndoorZonesMap/SearchWorkerControl/SearchWorkerControl.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,WAAA,CACA,iBAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".w300 {\n  width: 300px;\n  height: 44px;\n  position: absolute;\n  top: 10px;\n  left: 104px;\n  z-index: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"w300": "xUUakjcUoS9DOVomYMpN"
};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable react/no-array-index-key */
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
const printTableSelector = (state) => state.print;
function PrintTable() {
    const { head, body } = useSelector(printTableSelector, shallowEqual);
    // useEffect(() => {
    //   if (head.length > 0 && body.length > 0) print();
    // }, [head, body]);
    return (React.createElement(Table, null,
        React.createElement(TableHead, null,
            React.createElement(TableRow, null, head.map((cell, index) => (React.createElement(TableCell, { key: index }, cell))))),
        React.createElement(TableBody, null, body.map((row, index) => (React.createElement(TableRow, { key: index }, row.map((cell, index) => (React.createElement(TableCell, { key: index }, cell)))))))));
}
export default PrintTable;

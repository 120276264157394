function toggleValueInArray(valueArray, value) {
    if (!(valueArray instanceof Array))
        valueArray = [];
    if (!valueArray.includes(value))
        return [...valueArray, value];
    const currentOptionIndex = valueArray.indexOf(value);
    return [
        ...valueArray.slice(0, currentOptionIndex),
        ...valueArray.slice(currentOptionIndex + 1),
    ];
}
export default toggleValueInArray;

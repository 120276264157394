// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W36WhGhlvW80fMnlkxp0{display:flex;align-items:center}.W36WhGhlvW80fMnlkxp0 .MuiSvgIcon-root{margin-right:4px}.W36WhGhlvW80fMnlkxp0 p{display:inline}.y7GnJEYa4WooUQxcI5G5{height:auto;padding:2px 0px;background-color:#f0f0f0}.KQgpk7sXuN05gp9xM884{margin:0px -2px -2px 0px}.KQgpk7sXuN05gp9xM884 .y7GnJEYa4WooUQxcI5G5{margin:0px 2px 2px 0px}.KQgpk7sXuN05gp9xM884 .y7GnJEYa4WooUQxcI5G5 .MuiChip-label{max-width:200px}.nz5kPuaYZvkce_YsD8kA{color:unset;background-color:unset}.ym879vNKBa_msCWWlICp{color:#5dbb82;border-color:#5dbb82}.WOCDGV061YuBxxBcICwV{color:rgba(0,0,0,.26);border-color:rgba(0,0,0,.26)}.Kiz6FN5PXzYXhsTEUm7L{position:relative}.Kiz6FN5PXzYXhsTEUm7L .DImmYkpdOH7kudyp6xAq{position:absolute;top:0px}", "",{"version":3,"sources":["webpack://./src/templates/data/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,uCACE,gBAAA,CAEF,wBACE,cAAA,CAIJ,sBACE,WAAA,CACA,eAAA,CACA,wBAAA,CAGF,sBACE,wBAAA,CACA,4CACE,sBAAA,CACA,2DACE,eAAA,CAKN,sBACE,WAAA,CACA,sBAAA,CAGF,sBACE,aAAA,CACA,oBAAA,CAGF,sBACE,qBAAA,CACA,4BAAA,CAGF,sBACE,iBAAA,CACA,4CACE,iBAAA,CACA,OAAA","sourcesContent":[".icon-with-label {\n  display: flex;\n  align-items: center;\n  :global(.MuiSvgIcon-root) {\n    margin-right: 4px;\n  }\n  p {\n    display: inline;\n  }\n}\n\n.chip {\n  height: auto;\n  padding: 2px 0px;\n  background-color: #f0f0f0;\n}\n\n.chip-list {\n  margin: 0px -2px -2px 0px;\n  .chip {\n    margin: 0px 2px 2px 0px;\n    :global(.MuiChip-label) {\n      max-width: 200px;\n    }\n  }\n}\n\n.avatar {\n  color: unset;\n  background-color: unset;\n}\n\n.attached {\n  color: #5dbb82;\n  border-color: #5dbb82;\n}\n\n.archive {\n  color: rgba(0, 0, 0, 0.26);\n  border-color: rgba(0, 0, 0, 0.26);\n}\n\n.user-avatar {\n  position: relative;\n  .placeholder {\n    position: absolute;\n    top: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon-with-label": "W36WhGhlvW80fMnlkxp0",
	"iconWithLabel": "W36WhGhlvW80fMnlkxp0",
	"chip": "y7GnJEYa4WooUQxcI5G5",
	"chip-list": "KQgpk7sXuN05gp9xM884",
	"chipList": "KQgpk7sXuN05gp9xM884",
	"avatar": "nz5kPuaYZvkce_YsD8kA",
	"attached": "ym879vNKBa_msCWWlICp",
	"archive": "WOCDGV061YuBxxBcICwV",
	"user-avatar": "Kiz6FN5PXzYXhsTEUm7L",
	"userAvatar": "Kiz6FN5PXzYXhsTEUm7L",
	"placeholder": "DImmYkpdOH7kudyp6xAq"
};
export default ___CSS_LOADER_EXPORT___;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Menu as MuiMenu, Drawer, List } from '@material-ui/core';
import { useLayout, useMutableState } from 'hooks';
import closeMenu from './closeMenu';
const anchorSelector = ({ layout: { menu } }) => menu.anchorEl;
const nullSelector = () => null;
function Menu(_a) {
    var { open, children } = _a, props = __rest(_a, ["open", "children"]);
    const layout = useLayout();
    const anchorEl = useSelector(open ? anchorSelector : nullSelector, shallowEqual);
    const current = useMutableState();
    if (open)
        current.children = children;
    if (layout === 'mobile')
        return (React.createElement(Drawer, { open: open, onClose: props.onClose || closeMenu, anchor: "bottom" },
            React.createElement(List, null, current.children)));
    return (React.createElement(MuiMenu, Object.assign({ open: open, anchorEl: anchorEl, onClose: closeMenu }, props), current.children));
}
export default Menu;

import { List } from '@material-ui/core';
import EnterpriseIcon from '@material-ui/icons/Business';
import BeaconsIcon from '@material-ui/icons/Contactless';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HistoryIcon from '@material-ui/icons/History';
import ConstructionSiteIcon from '@material-ui/icons/LocationOn';
import WorkersIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import DevicesIcon from '@material-ui/icons/SettingsRemote';
import AnaliticsIcon from 'components/icons/Analitics';
import TurnstilesIcon from 'components/icons/Login';
import { useRole } from 'hooks';
import React from 'react';
import UserRoles from 'utils/UserRoles';
import AlertsNavItem from './AlertsNavItem';
import { ExpandableNavItem, NavItem } from './NavItem';
import { fetchPageSiteList } from './queries';
const checkPermissionsSos = (id) => {
    const host = window.location.host;
    if (host === 'watch.exon-smart.com') {
        return id !== '47a09fd2-c199-4178-8944-22781394ed47';
    }
    return true;
};
function NavList() {
    const role = useRole();
    const [isSigurUser, setIsSigurUser] = useState(true);
    const { id } = useSelector((state) => state.auth);
    const showSOS = checkPermissionsSos(id);
    const { data: siteList } = useQuery('NAV_BAR/SITE_LIST', fetchPageSiteList, { staleTime: 0 });
    useEffect(() => {
        var _a, _b, _c;
        if (!isSigurUser) {
            setIsSigurUser(true);
        }
        for (let i = 0; i < (siteList === null || siteList === void 0 ? void 0 : siteList.length); i++) {
            if (((_c = (_b = (_a = siteList === null || siteList === void 0 ? void 0 : siteList[i]) === null || _a === void 0 ? void 0 : _a.zoneGroup) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.sigurObject) !== 'True') {
                setIsSigurUser(false);
                break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteList]);
    const isAdminNavItemVisible = role === UserRoles.ADMIN ||
        role === UserRoles.DEV ||
        role === UserRoles.TEMPADMIN ||
        role === UserRoles.ENT_MANAGER;
    return (React.createElement(React.Fragment, null,
        React.createElement(List, null,
            React.createElement(NavItem, { icon: React.createElement(DashboardIcon, null), text: "\u0414\u0438\u0441\u043F\u0435\u0442\u0447\u0435\u0440\u0441\u043A\u0438\u0439 \u043F\u0443\u043D\u043A\u0442", path: "/", exact: true }),
            React.createElement(NavItem, { icon: React.createElement(EnterpriseIcon, null), text: "\u041A\u043E\u043C\u043F\u0430\u043D\u0438\u0438", path: "/enterprises" }),
            React.createElement(NavItem, { icon: React.createElement(ConstructionSiteIcon, null), text: "\u041E\u0431\u044A\u0435\u043A\u0442\u044B", path: "/sites" }),
            !isSigurUser && React.createElement(NavItem, { icon: React.createElement(WorkersIcon, null), text: "\u0421\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u0438", path: "/workers" }),
            !isSigurUser && React.createElement(NavItem, { icon: React.createElement(DevicesIcon, null), text: "\u0423\u0441\u0442\u0440\u043E\u0439\u0441\u0442\u0432\u0430", path: "/devices" }),
            !isSigurUser && React.createElement(NavItem, { icon: React.createElement(BeaconsIcon, null), text: "\u041C\u0430\u044F\u043A\u0438", path: "/beacons" }),
            React.createElement(ExpandableNavItem, { icon: React.createElement(TurnstilesIcon, null), text: "\u0422\u0443\u0440\u043D\u0438\u043A\u0435\u0442\u044B", path: "/turnstiles" },
                React.createElement(NavItem, { text: "\u041E\u0442\u0447\u0435\u0442 \u043F\u043E \u0442\u0435\u043C\u043F\u0435\u0440\u0430\u0442\u0443\u0440\u0430\u043C", path: "/turnstiles/temperature-report" }),
                React.createElement(NavItem, { text: "\u0410\u043D\u0430\u043B\u0438\u0442\u0438\u043A\u0430 \u043F\u043E \u043E\u0431\u044A\u0435\u043A\u0442\u0443", path: "/turnstiles/site-analytics" }),
                isSigurUser && React.createElement(NavItem, { text: "\u0424\u043E\u0440\u043C\u0430 \u042212", path: "/analytics/t12report" }))),
        React.createElement(List, null,
            !isSigurUser && (React.createElement(ExpandableNavItem, { icon: React.createElement(AnaliticsIcon, null), text: "\u0410\u043D\u0430\u043B\u0438\u0442\u0438\u043A\u0430 \u0438 \u043E\u0442\u0447\u0435\u0442\u044B", path: "/analytics" },
                React.createElement(NavItem, { text: "\u0423\u0447\u0451\u0442 \u0440\u0430\u0431\u043E\u0447\u0435\u0433\u043E \u0432\u0440\u0435\u043C\u0435\u043D\u0438", path: "/analytics/work-time" }),
                React.createElement(NavItem, { text: "\u0424\u043E\u0440\u043C\u0430 \u042212", path: "/analytics/t12report" }),
                React.createElement(NavItem, { text: "\u041E\u0442\u0447\u0451\u0442 \u043F\u043E \u0432\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044E \u043D\u0430 \u043E\u0431\u044A\u0435\u043A\u0442\u044B", path: "/analytics/site-entries" }),
                React.createElement(NavItem, { text: "\u041E\u0442\u0447\u0451\u0442 \u043F\u043E \u0432\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044E \u0432 \u0437\u043E\u043D\u044B", path: "/analytics/zone-entries" }))),
            !isSigurUser && (React.createElement(ExpandableNavItem, { icon: React.createElement(HistoryIcon, null), text: "\u0416\u0443\u0440\u043D\u0430\u043B \u0441\u043E\u0431\u044B\u0442\u0438\u0439", path: "/event-log" },
                React.createElement(NavItem, { text: "\u041E\u043F\u0435\u0440\u0430\u0442\u0438\u0432\u043D\u044B\u0435 \u0434\u0430\u043D\u043D\u044B\u0435", path: "/event-log/recent-data" }),
                React.createElement(NavItem, { text: "\u0418\u0441\u0442\u043E\u0440\u0438\u044F \u0441\u043E\u0431\u044B\u0442\u0438\u0439", path: "/event-log/history" })))),
        React.createElement(List, null,
            isAdminNavItemVisible && (React.createElement(ExpandableNavItem, { icon: React.createElement(SettingsIcon, null), text: "\u0410\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u0435", path: "/admin" },
                React.createElement(NavItem, { text: "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0438", path: "/admin/users" }),
                React.createElement(NavItem, { text: "\u0423\u0432\u0435\u0434\u043E\u043C\u043B\u0435\u043D\u0438\u0435 \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u043E\u0432", path: "/admin/worker-notification" }),
                React.createElement(NavItem, { text: "\u0416\u0443\u0440\u043D\u0430\u043B \u0431\u0438\u043E\u043C\u0435\u0442\u0440\u0438\u0438", path: "/admin/biometry" }))),
            !isSigurUser && showSOS && React.createElement(AlertsNavItem, null))));
}
export default NavList;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar, Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import ClearIcon from '@material-ui/icons/Clear';
import PersonIcon from '@material-ui/icons/Person';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import { openCamera, openFileDialog } from 'app';
import { Dialog, DialogButton, Menu, MenuItem } from 'components';
import { fieldSubscription, shouldDisplayFieldError, useField } from 'components/Form';
import UploadIcon from 'components/icons/FileUpload';
import { isMobile } from 'utils';
import styles from './styles.module.scss';
import useDerivedFieldProps from './useDerivedFieldProps';
const personIcon = React.createElement(PersonIcon, null);
const uploadIcon = React.createElement(UploadIcon, null);
const cameraIcon = React.createElement(CameraIcon, null);
const addPhotoIcon = React.createElement(AddPhotoIcon, null);
const clearIcon = React.createElement(ClearIcon, null);
const localFileURLList = new Map();
function getLocalFileURL(file) {
    let url = localFileURLList.get(file);
    if (url === undefined) {
        url = URL.createObjectURL(file);
        localFileURLList.set(file, url);
    }
    return url;
}
function AvatarInput(_a) {
    var { name, validate } = _a, props = __rest(_a, ["name", "validate"]);
    const _b = useDerivedFieldProps(props), { readOnly, disabled } = _b, derivedProps = __rest(_b, ["readOnly", "disabled"]);
    const field = useField(name, { validate, subscription: fieldSubscription });
    const { value, change, blur, error } = field;
    const [captionDialogOpen, setCaptionDialogOpen] = useState(false);
    const closeCaptionDialog = useCallback(() => setCaptionDialogOpen(false), []);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const openMenu = useCallback(event => setMenuAnchor(event.target), []);
    const closeMenu = useCallback(() => setMenuAnchor(null), []);
    const handleUploadPhoto = useCallback(() => {
        openFileDialog(([file]) => change(file), {
            accept: ['image/*'],
        });
        closeMenu();
    }, [name, change]);
    const handleTakePhoto = useCallback(() => {
        if (isMobile())
            openCamera(([file]) => change(file));
        else
            setCaptionDialogOpen(true);
        closeMenu();
    }, [name]);
    const clear = useCallback(() => {
        change(null);
        closeMenu();
    }, [name]);
    const displayError = shouldDisplayFieldError(field);
    const videoRef = useRef();
    const videoRefCallback = useCallback((videoEl) => __awaiter(this, void 0, void 0, function* () {
        var _c;
        if ((_c = navigator.mediaDevices) === null || _c === void 0 ? void 0 : _c.getUserMedia) {
            try {
                const stream = yield navigator.mediaDevices.getUserMedia({ video: true });
                videoEl.srcObject = stream;
                videoRef.current = videoEl;
            }
            catch (error) {
                //
            }
        }
    }), []);
    const handleCapture = useCallback(() => {
        const videoEl = videoRef.current;
        const canvasEl = document.createElement('canvas');
        canvasEl.width = videoEl.videoWidth;
        canvasEl.height = videoEl.videoHeight;
        const ctx = canvasEl.getContext('2d');
        ctx.drawImage(videoEl, 0, 0, videoEl.videoWidth, videoEl.videoHeight);
        canvasEl.toBlob(image => {
            const file = new File([image], 'new_photo.png');
            change(file);
            closeCaptionDialog();
        });
    }, [name]);
    const { isSuccess: hasCamera } = useQuery(['PHOTO_CAMERA'], () => __awaiter(this, void 0, void 0, function* () {
        var _d;
        yield ((_d = navigator.mediaDevices) === null || _d === void 0 ? void 0 : _d.getUserMedia({ video: true }));
    }), { retry: false });
    const src = value instanceof File ? getLocalFileURL(value) : value;
    const children = readOnly ? personIcon : addPhotoIcon;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.avatarInput },
            React.createElement("div", { className: "wrapper", onClick: disabled ? undefined : openMenu },
                React.createElement(Avatar, Object.assign({ tabIndex: disabled ? -1 : 0 }, (value ? { src } : { children }), derivedProps)),
                value && !disabled && (React.createElement(Avatar, Object.assign({ className: "overlay" }, props), addPhotoIcon))),
            displayError && React.createElement(Typography, { color: "error" }, error)),
        React.createElement(Menu, { open: Boolean(menuAnchor), anchorEl: menuAnchor, onClose: closeMenu },
            React.createElement(MenuItem, { icon: uploadIcon, text: "\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C", onClick: handleUploadPhoto }),
            React.createElement(MenuItem, { icon: cameraIcon, text: "\u0421\u0434\u0435\u043B\u0430\u0442\u044C \u0444\u043E\u0442\u043E", onClick: handleTakePhoto }),
            value && React.createElement(MenuItem, { icon: clearIcon, text: "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C", onClick: clear })),
        React.createElement(Dialog, { open: captionDialogOpen, onClose: closeCaptionDialog, maxWidth: false },
            React.createElement(DialogTitle, null, "\u041A\u0430\u043C\u0435\u0440\u0430"),
            React.createElement(DialogContent, null,
                React.createElement("video", { autoPlay: true, ref: videoRefCallback, width: "640px", height: "480px", style: { display: hasCamera ? undefined : 'none' } }),
                !hasCamera && React.createElement(Box, { width: "640px", height: "480px" }, "\u0412\u0438\u0434\u0435\u043E \u043A\u0430\u043C\u0435\u0440\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430")),
            React.createElement(DialogActions, null,
                React.createElement(Button, { variant: "outlined", onClick: closeCaptionDialog }, "\u041E\u0442\u043C\u0435\u043D\u0430"),
                React.createElement(DialogButton, { onClick: handleCapture },
                    React.createElement(CameraIcon, { className: "leading-icon" }),
                    "\u0421\u0434\u0435\u043B\u0430\u0442\u044C \u0444\u043E\u0442\u043E")))));
}
export default React.memo(AvatarInput);

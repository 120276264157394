const storeUser = localStorage.getItem('user');
export const initialState = storeUser ? JSON.parse(storeUser) : null;
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SIGNIN': {
            return action.user;
        }
        case 'SIGNOUT': {
            return null;
        }
        default:
            return state;
    }
};
export default authReducer;

import SaveIcon from '@material-ui/icons/Save';
import Fab from '../Fab';
export const submitButtonTemplates = {
    save: {
        children: React.createElement(React.Fragment, null,
            React.createElement(SaveIcon, { className: "leading-icon" }),
            "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"),
    },
    'save-fab': {
        component: Fab,
        icon: React.createElement(SaveIcon, null),
        label: 'Сохранить',
    },
};

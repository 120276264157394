import { BottomNavigation, Divider, Tabs, Toolbar } from '@material-ui/core';
import { useLayout } from 'hooks';
import ReactDOM from 'react-dom';
import { getPortalContainer } from 'utils/contents';
const barTabsContainer = getPortalContainer('bar-tabs');
function setCSSVar(node) {
    if (node) {
        document.body.style.setProperty('--bottom-bar-height', node.clientHeight + 'px');
    }
    else {
        document.body.style.setProperty('--bottom-bar-height', '0px');
    }
}
function BarTabs({ value, onChange, children, disableMobile }) {
    // useTopbarUpdater();
    const layout = useLayout();
    let content = null;
    if (children) {
        const _children = React.Children.map(children, child => {
            if (!child)
                return null;
            // @ts-expect-error
            return React.cloneElement(child, { disableMobile });
        });
        if (layout === 'mobile' && !disableMobile) {
            content = (React.createElement("div", { ref: setCSSVar, style: {
                    position: 'fixed',
                    bottom: '0px',
                    top: 'unset',
                    width: '100%',
                    zIndex: 1000,
                    paddingTop: '12px',
                } },
                React.createElement(Divider, null),
                React.createElement(BottomNavigation, Object.assign({}, { value, onChange }), _children)));
        }
        else {
            content = (React.createElement(Toolbar, { className: "bar-tabs-toolbar" },
                React.createElement(Tabs, Object.assign({}, { value, onChange }), _children)));
        }
    }
    return ReactDOM.createPortal(content, barTabsContainer);
}
export default BarTabs;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Box, Button, Drawer, Fab, Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tooltip, Typography, } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import { DatePicker, Form, getColor, Select, SelectItem, Switch, TimePicker } from 'components';
import IndoorZonesMap from 'components/IndoorZonesMap';
import { workerSuccessIcon } from 'components/MapElements/WorkerMarker';
import { useLayout } from 'hooks';
import Leaflet from 'leaflet';
import fetchPageBeaconList from 'pages/beacons/queries/fetchPageBeaconList';
import PositionSelect from 'pages/workers/PositionSelect';
import { WorkerSelect } from 'templates';
import { http, notify } from 'utils';
import styles from './styles.module.scss';
const getDefaultFilterValues = () => ({
    searchType: 'worker',
    searchEntityId: null,
    date: new Date().toISOString().slice(0, 10),
    startTime: '08:00',
    endTime: '17:00',
    onlyValid: true,
});
function fetchWorkersMonitorData(siteId, { searchType, searchEntityId, date, startTime, endTime, onlyValid }) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield http.get('/api/kbi/dashboards/trackinginproject', {
            zoneGroupId: siteId,
            begin: date + 'T' + startTime,
            end: date + 'T' + endTime,
            includeInValid: !onlyValid,
            workerId: searchType === 'worker' ? searchEntityId : undefined,
            professionId: searchType === 'position' ? searchEntityId : undefined,
            fields: '*,zones[id,name,color,states[points]]',
        });
        if (!response)
            return [];
        // fragment for debugging
        // return mockData[searchType === 'worker' ? 'byWorker' : 'byPosition'] as WorkersMonitorData;
        return response.result;
    });
}
function fetchSiteZones(siteId) {
    return __awaiter(this, void 0, void 0, function* () {
        const fields = 'zoneGroup[id,name,zones[id,name,color,deleted,states[deleted,points]]]';
        const { result } = yield http.get(`/api/kbi/construction-site/${siteId}`, { fields }, { onError: () => notify.error('Не удалось получить зоны объекта') });
        if (!result)
            return [];
        return [result === null || result === void 0 ? void 0 : result.zoneGroup];
    });
}
function getWorkersMonitorMapData(data, floor, progressiveTrack, indoorTrackline) {
    if ((data === null || data === void 0 ? void 0 : data.length) === 0)
        return {};
    const polylines = data
        .filter(item => { var _a; return ((_a = item.points) === null || _a === void 0 ? void 0 : _a.length) > 0; })
        .map(({ points }, index) => {
        const sumDistance = points.reduce((distance, { location: [lng, lat] }, index) => {
            if (index === 0)
                return 0;
            const prevLatLng = points[index - 1].location;
            const d = Math.pow((Math.pow(Math.abs(lng - prevLatLng[0]), 2) + Math.pow(Math.abs(lat - prevLatLng[1]), 2)), 0.5);
            return distance + d;
        }, 0);
        const getLatLngs = () => {
            let currDistance = 0;
            const arr = points.map(({ location: [lng, lat] }, pointIndex) => {
                let d = 0;
                if (pointIndex > 0) {
                    d = Math.pow((Math.pow(Math.abs(lng - points[pointIndex - 1].location[0]), 2) +
                        Math.pow(Math.abs(lat - points[pointIndex - 1].location[1]), 2)), 0.5);
                    currDistance += d;
                }
                return ([
                    lat,
                    lng,
                    currDistance / sumDistance,
                ]);
            });
            return arr;
        };
        return ({
            id: new Date().toISOString(),
            latlngs: progressiveTrack ? getLatLngs() : points.map(({ location: [lng, lat] }) => [lat, lng]),
            color: getColor(index + 6) + '55',
            decoratorPatterns: progressiveTrack ? null : [
                {
                    offset: 10,
                    repeat: 50,
                    symbol: Leaflet.Symbol.arrowHead({
                        pixelSize: 5,
                        pathOptions: {
                            fill: true,
                            opacity: 0.9,
                            fillOpacity: 0.7,
                            color: getColor(index + 6),
                            weight: 1,
                        },
                    }),
                },
            ],
        });
    });
    const circleMarkers = data
        .filter(item => { var _a; return ((_a = item.points) === null || _a === void 0 ? void 0 : _a.length) > 0; })
        .map(({ workerId, workerFullName, points }, index) => {
        return points.slice(0, points.length - 1).map(({ location: [lng, lat], date }) => ({
            id: workerId + ':' + date,
            latlng: [lat, lng],
            color: getColor(index + 6) + '99',
            radius: 5,
            tooltip: {
                workerFullName,
                date,
            },
        }));
    })
        .flat();
    const markers = data
        .filter(item => { var _a; return ((_a = item.points) === null || _a === void 0 ? void 0 : _a.length) > 0; })
        .map(({ workerId, workerFullName, points, indoorPoints }, index) => {
        if (floor) {
            const floorPoints = (indoorPoints !== null && indoorPoints !== void 0 ? indoorPoints : []).filter(p => p.floor === floor);
            if (floorPoints.length === 0)
                return null;
            const { latitude, longitude, dateTime, } = floorPoints[floorPoints.length - 1];
            return {
                id: workerId,
                latlng: [latitude, longitude],
                icon: workerSuccessIcon,
                tooltip: {
                    workerFullName,
                    date: dateTime,
                },
            };
        }
        const { location: [lng, lat], date, } = points[points.length - 1];
        return {
            id: workerId,
            latlng: [lat, lng],
            icon: workerSuccessIcon,
            tooltip: {
                workerFullName,
                date,
            },
        };
    }).filter(Boolean);
    const polygons = data
        .flatMap(item => item.zones)
        .reduce((total, zone) => {
        if (total.some(_zone => _zone.id === zone.id))
            return total;
        return [...total, zone];
    }, [])
        .map(({ name, states, color: [red, green, blue, alpha] }) => ({
        id: name,
        latlngs: states[0].points.map(([lat, lng]) => [lng, lat]),
        color: `rgba(${red},${green},${blue},${alpha})`,
        tooltip: name,
    }));
    const indoorPointMarkers = data === null || data === void 0 ? void 0 : data.filter(w => w.indoorPoints).flatMap(w => {
        var _a;
        return (_a = w.indoorPoints) === null || _a === void 0 ? void 0 : _a.map(ip => (Object.assign(Object.assign({}, ip), { workerId: w.workerId, workerFullName: w.workerFullName })));
    });
    const indoorPolylines = indoorTrackline ? data
        .filter(item => { var _a; return ((_a = item.indoorPoints) === null || _a === void 0 ? void 0 : _a.length) > 0; })
        .map(({ indoorPoints }, index) => {
        return ({
            id: new Date().toISOString(),
            latlngs: indoorPoints.map(({ latitude, longitude }) => [latitude, longitude]),
            color: getColor(index + 6) + '55',
            decoratorPatterns: [
                {
                    offset: 10,
                    repeat: 50,
                    symbol: Leaflet.Symbol.arrowHead({
                        pixelSize: 5,
                        pathOptions: {
                            fill: true,
                            opacity: 0.9,
                            fillOpacity: 0.7,
                            color: getColor(index + 6),
                            weight: 1,
                        },
                    }),
                },
            ],
        });
    }) : null;
    const bounds = (polylines === null || polylines === void 0 ? void 0 : polylines.length) > 0
        ? polylines.flatMap(pl => pl.latlngs)
        : (polygons === null || polygons === void 0 ? void 0 : polygons.length)
            ? polygons.flatMap(pl => pl.latlngs)
            : undefined;
    return {
        progressiveTrack,
        polylines,
        bounds,
        circleMarkers,
        markers,
        polygons,
        indoorPointMarkers: (indoorPointMarkers === null || indoorPointMarkers === void 0 ? void 0 : indoorPointMarkers.length) > 0 ? indoorPointMarkers : undefined,
        indoorPolylines,
    };
}
const validateEntityId = (value) => (value ? undefined : 'Необходимо выбрать значение');
const renderTooltip = (entityName, data) => {
    let content = null;
    if (entityName === 'Polygon') {
        content = React.createElement(Typography, { className: styles.polygonTooltip }, data);
    }
    if (entityName === 'Marker' || entityName === 'CircleMarker') {
        const { workerFullName, date } = data;
        content = (React.createElement(React.Fragment, null,
            React.createElement(Typography, null, workerFullName),
            React.createElement(Typography, null, new Date(date).toLocaleString('ru'))));
    }
    return (React.createElement(Paper, { className: styles.mapWidgetTooltip, elevation: 4 },
        React.createElement(Box, { padding: 1 }, content)));
};
function WorkersMonitorWidget({ siteId }) {
    const [tab, setTab] = React.useState(0);
    const handleTabChange = useCallback((event, newValue) => setTab(newValue), []);
    const [filterValues, setFilterValues] = useState({
        searchType: 'worker',
    });
    const { searchEntityId, searchType, progressiveTrack, indoorTrackline } = filterValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => setFilterValues(getDefaultFilterValues), [siteId]);
    const { data = [] } = useQuery(['WIDGETS/WORKERS_MONITOR', siteId, filterValues], () => fetchWorkersMonitorData(siteId, filterValues), { enabled: Boolean(searchEntityId) });
    const { data: outdoorZones = [] } = useQuery(['WIDGETS/WORKERS_MONITOR/POLYGONS', siteId], () => fetchSiteZones(siteId), { enabled: !searchEntityId && !siteId });
    // const filteredData = useMemo(() => filterDataByPositionId(data, positionId), [data, positionId]);
    const [floor, setFloor] = useState(null);
    const mapData = useMemo(() => getWorkersMonitorMapData(searchEntityId ? data : outdoorZones, floor, progressiveTrack, indoorTrackline), [data, outdoorZones, searchEntityId, floor, progressiveTrack, indoorTrackline]);
    const [entriesStartTime, setEntriesStartTime] = React.useState('00:00');
    const displayedEntries = useMemo(() => {
        var _a;
        const specifiedDate = new Date('01.01.01 ' + entriesStartTime);
        return (_a = data === null || data === void 0 ? void 0 : data[0]) === null || _a === void 0 ? void 0 : _a.entries.filter(({ zoneEntryTime }) => {
            const currentDate = new Date('01.01.01 ' + zoneEntryTime);
            return currentDate > specifiedDate;
        });
    }, [data, entriesStartTime]);
    const { data: beacons = [] } = useQuery(['BEACONS/BEACON_LIST', siteId], () => fetchPageBeaconList({ siteId }));
    const [filterOpen, setFilterOpen] = useState(false);
    const layout = useLayout();
    const isMobile = layout === 'mobile';
    const displayFilter = isMobile ? filterOpen : true;
    const FilterContainer = isMobile ? Drawer : Box;
    const openFilter = () => setFilterOpen(true);
    const closeFilter = () => setFilterOpen(false);
    const FilterContainerProps = isMobile ? {
        open: displayFilter,
        onClose: closeFilter,
        classes: { paper: styles.workerMonitorDrawerPaper },
    } : {};
    return (React.createElement(Box, { display: "flex", height: "100%" },
        React.createElement(FilterContainer, Object.assign({ width: "450px" }, FilterContainerProps),
            React.createElement("span", null,
                React.createElement(Box, { pb: "24px" },
                    React.createElement(Tabs, { value: tab, onChange: handleTabChange, variant: "fullWidth" },
                        React.createElement(Tab, { label: "\u041E\u0441\u043D\u043E\u0432\u043D\u043E\u0435" }),
                        React.createElement(Tab, { label: "\u0414\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u043E" }))),
                tab === 0 && (React.createElement(Form, { initialValues: filterValues, onChange: setFilterValues },
                    React.createElement(Box, { className: styles.controlsVertical },
                        React.createElement(Select, { name: "searchType", label: "\u0422\u0438\u043F \u043F\u043E\u0438\u0441\u043A\u0430", variant: "standard" },
                            React.createElement(SelectItem, { value: "worker", label: "\u041F\u043E \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u0443" }),
                            React.createElement(SelectItem, { value: "position", label: "\u041F\u043E \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u0438" })),
                        searchType === 'worker' && (React.createElement(WorkerSelect, { siteId: siteId, name: "searchEntityId", validate: validateEntityId, variant: "standard" })),
                        searchType === 'position' && (React.createElement(PositionSelect, { name: "searchEntityId", validate: validateEntityId, variant: "standard" })),
                        React.createElement(DatePicker, { fullWidth: true, disableKeyboardInput: true, inputVariant: "standard", name: "date", label: "\u0414\u0430\u0442\u0430" }),
                        React.createElement(Box, { display: "flex", justifyContent: "space-between" },
                            React.createElement(TimePicker, { inputVariant: "standard", name: "startTime", label: "\u041D\u0430\u0447\u0430\u043B\u044C\u043D\u043E\u0435 \u0432\u0440\u0435\u043C\u044F" }),
                            React.createElement(TimePicker, { inputVariant: "standard", name: "endTime", label: "\u041A\u043E\u043D\u0435\u0447\u043D\u043E\u0435 \u0432\u0440\u0435\u043C\u044F" })),
                        React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
                            React.createElement(Typography, null, "\u0422\u043E\u043B\u044C\u043A\u043E \u0432\u0430\u043B\u0438\u0434\u043D\u044B\u0435"),
                            React.createElement(Switch, { name: "onlyValid" })),
                        !floor && React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
                            React.createElement(Typography, null, "\u0426\u0432\u0435\u0442\u043D\u043E\u0439 \u0442\u0440\u0435\u043A"),
                            React.createElement(Switch, { name: "progressiveTrack" })),
                        floor && React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
                            React.createElement(Typography, null, "\u041E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0442\u044C \u0442\u0440\u0435\u043A"),
                            React.createElement(Switch, { name: "indoorTrackline" }))))),
                tab === 1 && (React.createElement(Form, { initialValues: { entriesStartTime }, onChange: ({ entriesStartTime }) => setEntriesStartTime(entriesStartTime || '00:00') },
                    React.createElement(Box, { className: styles.controlsVertical },
                        React.createElement(Typography, null, "\u041C\u0438\u043D\u0438\u043C\u0430\u043B\u044C\u043D\u043E\u0435 \u0432\u0440\u0435\u043C\u044F \u043D\u0430\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044F \u0432 \u0437\u043E\u043D\u0435"),
                        React.createElement(TimePicker, { disabled: searchType !== 'worker' || (data === null || data === void 0 ? void 0 : data.length) === 0, inputVariant: "standard", name: "entriesStartTime" })),
                    React.createElement(Box, { pt: "24px" }, searchType === 'worker' && (displayedEntries === null || displayedEntries === void 0 ? void 0 : displayedEntries.length) > 0 && (React.createElement(Table, null,
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, null, "\u0417\u043E\u043D\u0430"),
                                React.createElement(TableCell, null, "\u0412\u0440\u0435\u043C\u044F \u043D\u0430\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044F"))),
                        React.createElement(TableBody, null, displayedEntries.map(({ zoneName, zoneEntryTime }) => (React.createElement(TableRow, { key: zoneName },
                            React.createElement(TableCell, null, zoneName),
                            React.createElement(TableCell, null, zoneEntryTime))))))))))),
            isMobile && React.createElement(Button, { onClick: closeFilter }, "\u0421\u043A\u0440\u044B\u0442\u044C")),
        React.createElement(Box, { width: "100%", ml: "16px" },
            React.createElement(IndoorZonesMap, Object.assign({ beacons: beacons, siteId: siteId, rootStyle: { width: '100%', height: '100%' }, style: { width: '100%', height: '100%' }, renderTooltip: renderTooltip }, mapData, { hideZonesWhenIndoor: true, editableImageZIndex: 100, onFloorChange: (_zoneId, _floor) => setFloor(_floor) }))),
        isMobile && (React.createElement("div", { className: styles.workerMonitorFabContainer },
            React.createElement(Tooltip, { title: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0444\u0438\u043B\u044C\u0442\u0440" },
                React.createElement(Fab, { size: "small", onClick: openFilter },
                    React.createElement(FilterIcon, null)))))));
}
export default React.memo(WorkersMonitorWidget);

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Tooltip } from '@material-ui/core';
import { classes, getDeviceTypeByName } from 'utils';
import DeviceIcon from './DeviceIcon';
import styles from './styles.module.scss';
function ListItemDevice(_a) {
    var _b;
    var { data, className } = _a, props = __rest(_a, ["data", "className"]);
    if (data == null)
        return null;
    return (React.createElement("div", Object.assign({ className: classes(styles.iconWithLabel, className) }, props),
        React.createElement(Tooltip, { title: "\u0423\u0442\u0441\u0440\u043E\u0439\u0441\u0442\u0432\u043E" },
            React.createElement("span", { style: { display: 'inherit' } },
                React.createElement(DeviceIcon, { type: getDeviceTypeByName((_b = data.model) === null || _b === void 0 ? void 0 : _b.displayName) }))),
        React.createElement("p", null, data.name)));
}
export default React.memo(ListItemDevice);

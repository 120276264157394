import { useLocation } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { history, store } from 'app';
import { getLayout } from 'hooks/useLayout';
import isCurrentPath from './isCurrentPath';
import useLeftbarMinimized from '../useLeftbarMinimized';
import styles from './styles.module.scss';
function NavItem({ icon = null, text = '', path, exact = false, isMenuItem, onClick }, ref) {
    const location = useLocation();
    const selected = isCurrentPath(location.pathname, path, exact);
    const handleClick = useCallback(event => {
        history.push(path, {});
        if (getLayout(window.innerWidth) !== 'desktop')
            store.dispatch({ type: 'CLOSE_LEFTBAR' });
        onClick === null || onClick === void 0 ? void 0 : onClick(event);
    }, [path]);
    const leftbarMinimized = useLeftbarMinimized();
    if (isMenuItem) {
        return React.createElement(MenuItem, { ref: ref, onClick: handleClick },
            icon && React.createElement(ListItemIcon, null, icon),
            React.createElement(ListItemText, { primary: text }));
    }
    return (React.createElement(ListItem, { className: styles.navItem, selected: selected, button: true, onClick: handleClick },
        React.createElement(ListItemIcon, null, icon),
        !leftbarMinimized && React.createElement(ListItemText, { primary: text })));
}
export default React.forwardRef(NavItem);

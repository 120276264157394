var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { queryClient, socket } from 'app';
import { http } from 'utils';
function fetchTotalAlerts() {
    return __awaiter(this, void 0, void 0, function* () {
        const [{ result: unresponded }, { result: today }] = yield Promise.all([
            http.get('/api/kbi/alert/unresponded_count'),
            http.get('/api/kbi/alert/today_count'),
        ]);
        return { unresponded, today };
    });
}
function useTotalAlerts() {
    const { data } = useQuery('ALERTS/TOTAL', fetchTotalAlerts);
    useEffect(() => {
        const unsubscribe = socket.subscribe('ALERT_COUNT_CHANGE', (data) => queryClient.setQueryData('ALERTS/TOTAL', data));
        // setTimeout(() => queryClient.setQueryData('ALERTS/TOTAL', { unresponded: 5 }), 2000);
        return unsubscribe;
    }, []);
    return data || Object();
}
export default useTotalAlerts;

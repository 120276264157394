import { queryClient } from 'app';
export function getQueryData(...keys) {
    const [query] = queryClient.getQueryCache().findAll([...keys]);
    return query === null || query === void 0 ? void 0 : query.state.data;
}
export function getActiveQueryData(...keys) {
    const [query] = queryClient.getQueryCache().findAll([...keys], { active: true });
    return query === null || query === void 0 ? void 0 : query.state.data;
}
export function refetchActiveQuery(...keys) {
    queryClient.refetchQueries([...keys], { active: true });
}

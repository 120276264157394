var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Autocomplete } from 'components';
import { useFieldValues } from 'hooks';
import { http } from 'utils';
const toWorkerSelectOption = ({ id, fullName, attributes, enterprise, builds, device, }) => {
    var _a;
    return ({
        id,
        fullname: fullName,
        photo: attributes.photo,
        enterpriseId: enterprise === null || enterprise === void 0 ? void 0 : enterprise.id,
        enterpriseName: enterprise === null || enterprise === void 0 ? void 0 : enterprise.displayName,
        constructionSiteIdList: (_a = builds === null || builds === void 0 ? void 0 : builds.map(constructionSite => constructionSite.id)) !== null && _a !== void 0 ? _a : [],
        deviceId: device === null || device === void 0 ? void 0 : device.id,
    });
};
const fields = 'id,fullName,attributes[photo],enterprise[id,displayName],builds[id],device[id]';
function getWorkerListForSelect() {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/workers', {
            fields,
        });
        return result.map(toWorkerSelectOption);
    });
}
const getWorkerOptionValue = ({ id }) => id;
const getWorkerOptionLabel = ({ fullname }) => fullname;
function WorkerSelect(_a) {
    var { enterpriseFieldName = 'enterpriseId', constructionSiteFieldName = 'constructionSiteId', siteId, name, label, multiple, disabled, filter, filterValues } = _a, props = __rest(_a, ["enterpriseFieldName", "constructionSiteFieldName", "siteId", "name", "label", "multiple", "disabled", "filter", "filterValues"]);
    const [enterpriseFieldValue, constructionSiteFieldValue] = useFieldValues(enterpriseFieldName, constructionSiteFieldName);
    siteId = siteId !== null && siteId !== void 0 ? siteId : constructionSiteFieldValue;
    const { data = [], isFetching } = useQuery('SELECT/WORKER', getWorkerListForSelect, {
        cacheTime: 10 * 60 * 1000,
    });
    const workerList = useMemo(() => (filter == null ? data : data.filter(filter)), [data, filter]);
    const options = useMemo(() => {
        if ((enterpriseFieldValue == null || enterpriseFieldValue.length === 0) &&
            (siteId == null || siteId.length === 0))
            return workerList;
        return workerList.filter(({ enterpriseId, constructionSiteIdList }) => {
            // if (enterpriseFieldValue) {
            //   if (enterpriseFieldValue instanceof Array && !enterpriseFieldValue.includes(enterpriseId))
            //     return false;
            //   if (enterpriseId !== enterpriseFieldValue) return false;
            // }
            if (siteId) {
                if (constructionSiteIdList.length === 0)
                    return false;
                for (const costructionSiteId of constructionSiteIdList) {
                    if (siteId instanceof Array && siteId.includes(costructionSiteId))
                        return true;
                    if (costructionSiteId === siteId)
                        return true;
                }
                return false;
            }
            return true;
        });
    }, [workerList, enterpriseFieldValue, siteId]);
    return (React.createElement(Autocomplete, Object.assign({ name: name || 'workerId', multiple: multiple, label: (label !== null && label !== void 0 ? label : multiple) ? 'Сотрудники' : 'Сотрудник', getOptionValue: getWorkerOptionValue, getOptionLabel: getWorkerOptionLabel, options: options, disabled: disabled !== null && disabled !== void 0 ? disabled : isFetching, filterValues: filterValues !== null && filterValues !== void 0 ? filterValues : !isFetching }, props)));
}
export default React.memo(WorkerSelect);

import { matchPath, useLocation } from 'react-router-dom';
import { history } from 'app';
import { useFormPageMode } from 'hooks';
function usePathTabs(path, exact) {
    const { pathname } = useLocation();
    const formPageMode = useFormPageMode();
    const formPageModePathSuffix = formPageMode === 'create' || formPageMode === 'edit' ? '/' + formPageMode : '';
    const tab = useMemo(() => {
        var _a;
        const match = matchPath(pathname, {
            path: path + '/:tab?' + formPageModePathSuffix,
            exact,
        });
        return (_a = match === null || match === void 0 ? void 0 : match.params.tab) !== null && _a !== void 0 ? _a : '';
    }, [path, exact, pathname, formPageModePathSuffix]);
    const handleTabChange = useCallback((event, value) => {
        const { state } = history.location;
        const tabPathSuffix = value ? '/' + value : '';
        history.replace(path + tabPathSuffix + formPageModePathSuffix, state);
    }, [path, formPageModePathSuffix]);
    return [tab, handleTabChange];
}
export default usePathTabs;

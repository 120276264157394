import React from 'react';
import { Box, Grid, IconButton, Tooltip, Paper, makeStyles } from '@material-ui/core';
import { TextField, Field, Autocomplete } from 'components';
import DeleteContactIcon from '@material-ui/icons/Clear';
import { useFieldValues } from 'hooks';
const useStyles = makeStyles(() => ({
    root: {
        width: '300px',
    },
}));
const informingTypes = [
    {
        type: 'email',
        label: 'E-mail',
    },
    {
        type: 'phone',
        label: 'Телефон',
    },
];
// временное решение до тех пор пока не сможем в информирование добавить несколько телефонов или email'ов
const getOptionList = (values, index) => {
    if (values.length === 0)
        return informingTypes;
    if (values.length === 1)
        return informingTypes;
    if (values.length === 2) {
        if (index === 0 && values[1].informingType === 'email')
            return [informingTypes[1]];
        if (index === 0 && values[1].informingType === 'phone')
            return [informingTypes[0]];
        if (index === 1 && values[0].informingType === 'email')
            return [informingTypes[1]];
        if (index === 1 && values[0].informingType === 'phone')
            return [informingTypes[0]];
    }
    return informingTypes;
};
const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);
const required = (value) => (value ? undefined : 'Обязательное поле');
const email = value => {
    const regexpEmail = /\S+@\S+\.\S+/;
    return regexpEmail.test(value) ? undefined : 'Введите корректный email';
};
const phone = value => {
    const regexpPhone = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
    return regexpPhone.test(value) ? undefined : 'Введите корректный телефон';
};
const UserInformingField = ({ index, onDelete, readOnly }) => {
    const styles = useStyles();
    const values = useFieldValues('informingContacts');
    return (React.createElement(Paper, { elevation: 2 },
        React.createElement(Box, { padding: "16px" },
            React.createElement(Field, { name: `informingContacts[${index}]` },
                React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                    React.createElement(Grid, { item: true },
                        React.createElement(Autocomplete, { classes: styles, validate: required, name: "informingType", label: "\u0422\u0438\u043F \u0438\u043D\u0444\u043E\u0440\u043C\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F", 
                            // @ts-ignore
                            getOptionValue: ({ type }) => type, 
                            // @ts-ignore
                            getOptionLabel: ({ label }) => label, options: getOptionList(values, index), disabled: readOnly })),
                    values[index].informingType === 'phone' &&
                        React.createElement(Grid, { item: true },
                            React.createElement(TextField, { classes: styles, validate: composeValidators(required, phone), fullWidth: true, name: "phone", label: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D" })),
                    values[index].informingType === 'email' &&
                        React.createElement(Grid, { item: true },
                            React.createElement(TextField, { classes: styles, validate: composeValidators(required, email), fullWidth: true, name: "email", label: "\u042D\u043B. \u043F\u043E\u0447\u0442\u0430" })),
                    React.createElement(Grid, { item: true }, !readOnly && (React.createElement(Tooltip, { title: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u043C\u0435\u0442\u043E\u0434 \u0438\u043D\u0444\u043E\u0440\u043C\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F" },
                        React.createElement("span", null,
                            React.createElement(IconButton, { onClick: onDelete },
                                React.createElement(DeleteContactIcon, null)))))))))));
};
export default UserInformingField;

import PrintIcon from '@material-ui/icons/Print';
import FilterIcon from '@material-ui/icons/FilterList';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
import DetailsIcon from '@material-ui/icons/Visibility';
import { store } from 'app';
// import { useLayout } from 'hooks';
const templates = {
    filter: {
        icon: React.createElement(FilterIcon, null),
        text: 'Фильтры',
        onClick: () => store.dispatch({ type: 'TOGGLE_RIGHTBAR' }),
        // Wrapper: ({ children }: { children: ReactElement }) => {
        //   const layout = useLayout();
        //   if (layout === 'desktop') return null;
        //   return children;
        // },
    },
    print: {
        icon: React.createElement(PrintIcon, null),
        text: 'Распечатать',
    },
    download: {
        icon: React.createElement(DownloadIcon, null),
        text: 'Загрузить',
    },
    edit: {
        icon: React.createElement(EditIcon, null),
        text: 'Редактировать',
    },
    delete: {
        icon: React.createElement(DeleteIcon, null),
        text: 'Удалить',
    },
    cancel: {
        icon: React.createElement(CancelIcon, null),
        text: 'Отмена',
    },
    back: {
        icon: React.createElement(ArrowBackIcon, null),
        text: 'Назад',
    },
    refresh: {
        icon: React.createElement(RefreshIcon, null),
        text: 'Обновить',
    },
    details: {
        icon: React.createElement(DetailsIcon, null),
        text: 'Подробнее',
    },
};
export default templates;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".usOVOF8_eQjWNyR8BBpr{border-left:4px solid rgba(0,0,0,0)}.usOVOF8_eQjWNyR8BBpr:hover{background-color:rgba(255,255,255,.06)}.usOVOF8_eQjWNyR8BBpr.Mui-selected{background-color:rgba(255,255,255,.12);border-color:#455478}.usOVOF8_eQjWNyR8BBpr.Mui-selected:hover{background-color:rgba(255,255,255,.16)}.usOVOF8_eQjWNyR8BBpr>.MuiListItemIcon-root{color:#82abd3;min-width:40px}", "",{"version":3,"sources":["webpack://./src/layout/LeftBar/NavItem/styles.module.scss","webpack://./src/theme.scss"],"names":[],"mappings":"AAEA,sBACE,mCAAA,CACA,4BACE,sCAAA,CAEF,mCACE,sCAAA,CACA,oBCNmB,CDQrB,yCACE,sCAAA,CAGF,4CACE,aCZoB,CDapB,cAAA","sourcesContent":["@import '../../../theme';\n\n.nav-item {\n  border-left: 4px solid transparent;\n  &:hover {\n    background-color: rgba(255, 255, 255, 0.06);\n  }\n  &:global(.Mui-selected) {\n    background-color: rgba(255, 255, 255, 0.12);\n    border-color: $color-secondary-main;\n  }\n  &:global(.Mui-selected):hover {\n    background-color: rgba(255, 255, 255, 0.16);\n  }\n\n  & > :global(.MuiListItemIcon-root) {\n    color: $color-secondary-light;\n    min-width: 40px;\n  }\n}","// $color-secondary-main: #2c7cb2;\n// $color-secondary-main: hsl(184deg 43% 36%);\n// $color-secondary-light: #66abe4;\n$color-secondary-main: hsl(222deg 27% 37%);\n$color-secondary-light: hsl(210deg 48% 67%);\n\n// $color-primary-main: #242f44;\n// $color-primary-main: hsl(219deg 24% 16%);\n$color-primary-main: hsl(222deg 27% 37%);\n$color-primary-dark: hsl(226deg 26% 16%);\n\n$color-alert: #f34151;\n\n$maximized-leftbar-width: 300px;\n$minimized-leftbar-width: 64px;\n$rightbar-width: 400px;\n\n$mobile-width: 960px;\n$tablet-width: 1280px;\n\n$topbar-z-index: 1100;\n\n@mixin leading-icon {\n  margin-right: 8px;\n}\n\n$color-success: hsl(105deg 45% 70%);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav-item": "usOVOF8_eQjWNyR8BBpr",
	"navItem": "usOVOF8_eQjWNyR8BBpr"
};
export default ___CSS_LOADER_EXPORT___;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Divider, CircularProgress, LinearProgress, Checkbox as MuiCheckbox, FormControlLabel, } from '@material-ui/core';
import { useFormState, useForm } from 'components/Form';
import { Checkbox } from 'components';
import { useFieldValues } from 'hooks';
import { http } from 'utils';
import styles from './styles.module.scss';
const toWorkerSelectOption = ({ id, fullName }) => ({
    id,
    fullname: fullName,
});
function getWorkerListForSelect(constructionId) {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/workers', {
            fields: `id,fullName,builds[id=${constructionId}]`,
        });
        return result.map(toWorkerSelectOption);
    });
}
function WorkerSelectList({ constructionSiteFieldName = 'constructionSiteId', zoneFieldName = 'zoneId', name = 'workerIds', label = 'Сотрудники', disabled, }) {
    var _a;
    const [constructionSiteFieldValue, zoneFieldValue] = useFieldValues(constructionSiteFieldName, zoneFieldName);
    const { data: workerList, isFetching, isLoading } = useQuery(['SELECT/WORKER_LIST', constructionSiteFieldValue], () => getWorkerListForSelect(constructionSiteFieldValue), {
        cacheTime: 10 * 60 * 1000,
        enabled: Boolean(constructionSiteFieldValue),
        keepPreviousData: false,
    });
    disabled =
        disabled !== null && disabled !== void 0 ? disabled : (isFetching ||
            (constructionSiteFieldName && !constructionSiteFieldValue) ||
            zoneFieldValue != null);
    const form = useForm();
    const { values } = useFormState();
    useEffect(() => {
        if (constructionSiteFieldName != null)
            form.change(name, []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [constructionSiteFieldName, constructionSiteFieldValue]);
    const allWorkerIdList = useMemo(() => workerList === null || workerList === void 0 ? void 0 : workerList.map(worker => worker.id), [workerList]);
    const allSelected = ((_a = values.workerIds) === null || _a === void 0 ? void 0 : _a.length) === (allWorkerIdList === null || allWorkerIdList === void 0 ? void 0 : allWorkerIdList.length);
    const handleSelectAll = useCallback((event, checked) => form.change('workerIds', checked ? allWorkerIdList : []), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allWorkerIdList]);
    return (React.createElement("div", { className: styles.workerSelectList },
        React.createElement("div", { className: "header" },
            React.createElement(FormControlLabel, { label: label, control: React.createElement(MuiCheckbox, { disabled: disabled, checked: allSelected, color: "primary", onChange: handleSelectAll }) })),
        isFetching && !isLoading ? React.createElement(LinearProgress, null) : React.createElement(Divider, null),
        React.createElement("div", { className: "inner" },
            isLoading && (React.createElement("div", { style: { textAlign: 'center' } },
                React.createElement(CircularProgress, null))), workerList === null || workerList === void 0 ? void 0 :
            workerList.map(({ id, fullname }) => (React.createElement("div", { key: id },
                React.createElement(Checkbox, { disabled: disabled, value: id, name: name, label: fullname, color: "primary" })))))));
}
export default React.memo(WorkerSelectList);

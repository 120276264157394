import { Select, SelectItem, allColors } from 'components';
export function ColorSelectItem({ value }) {
    return React.createElement("div", { style: { width: '64px', height: '18px', backgroundColor: value } });
}
const colorItems = allColors.map(value => (React.createElement(SelectItem, { key: value, value: value },
    React.createElement(ColorSelectItem, { value: value }))));
function ColorSelect({ children, name = 'color' }) {
    return (React.createElement(Select, { displayEmpty: true, name: name, label: "\u0426\u0432\u0435\u0442", style: { width: '120px' } },
        colorItems,
        children));
}
export default ColorSelect;

import Leaflet from 'leaflet';
import 'leaflet.markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
import MapElementCollection from './MapElementCollection';
import markerFactory from './factories/markerFactory';
class MarkerClusterContainer {
    constructor(container) {
        this.container = container;
        this.layer = Leaflet.markerClusterGroup({
            spiderfyOnMaxZoom: false,
            showCoverageOnHover: false,
            zoomToBoundsOnClick: false,
            maxClusterRadius: 20,
        });
        this.markers = new MapElementCollection(this, markerFactory);
        this.layer.addEventListener('clustermouseover', (event) => {
            const markerLayers = event.sourceTarget.getAllChildMarkers();
            let tooltips = [];
            for (const element of this.markers.getElementList()) {
                if (markerLayers.includes(element.layer))
                    tooltips.push(element.tooltip);
            }
            tooltips = tooltips.filter(item => item);
            if (tooltips.length > 0)
                this.tooltip.open(event.originalEvent, 'MarkerCluster', tooltips);
        });
    }
    get tooltip() {
        return this.container.tooltip;
    }
}
export default MarkerClusterContainer;

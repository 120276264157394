var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Leaflet from 'leaflet';
const markerFactory = {
    entityName: 'CircleMarker',
    createLayer(state) {
        const { latlng } = state, options = __rest(state, ["latlng"]);
        return Leaflet.circleMarker(latlng, options);
    },
    updateLayer(layer, state, nextState) {
        const { latlng, color, radius } = nextState;
        if (state.latlng[0] !== latlng[0] || state.latlng[1] !== latlng[1])
            layer.setLatLng(latlng);
        if (state.radius !== radius)
            layer.setRadius(radius);
        if (state.color !== color)
            layer.setStyle({ color });
    },
};
export default markerFactory;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { KeyboardDatePicker, DatePicker as MuiDatePicker, } from '@material-ui/pickers';
import { useField, shouldDisplayFieldError, fieldSubscription } from 'components/Form';
import { classes } from 'utils';
import useDerivedFieldProps from './useDerivedFieldProps';
import { makeDateFormatted } from './formatDate';
function DatePicker(_a) {
    var { name, validate, helperText, serializeFormat = 'date', disableKeyboardInput, onChange } = _a, props = __rest(_a, ["name", "validate", "helperText", "serializeFormat", "disableKeyboardInput", "onChange"]);
    const _b = useDerivedFieldProps(props), { className } = _b, derivedProps = __rest(_b, ["className"]);
    const handleValidate = useCallback((value) => {
        if (value instanceof Date && isNaN(value.getTime()))
            return 'Неверный формат даты';
        return validate === null || validate === void 0 ? void 0 : validate(value);
    }, [validate]);
    const field = useField(name, { validate: handleValidate, subscription: fieldSubscription });
    const { change, blur, error } = field;
    let { value } = field;
    value = typeof value === 'string' ? new Date(value) : value;
    makeDateFormatted(value, serializeFormat);
    const handleChange = useCallback((nextValue) => {
        makeDateFormatted(nextValue, serializeFormat);
        change(nextValue);
        onChange === null || onChange === void 0 ? void 0 : onChange(nextValue);
    }, [serializeFormat, onChange]);
    const displayError = shouldDisplayFieldError(field);
    const DatePickerComponent = disableKeyboardInput ? MuiDatePicker : KeyboardDatePicker;
    return (React.createElement(DatePickerComponent, Object.assign({}, derivedProps, { value: value !== null && value !== void 0 ? value : null, onChange: handleChange, onBlur: blur, className: classes(className, 'date-picker'), error: displayError, helperText: displayError ? error : helperText })));
}
const MemoDatePicker = React.memo(DatePicker);
export default MemoDatePicker;

export const inputSubscription = {
    value: true,
    dirty: true,
};
export const errorSubscription = {
    touched: true,
    error: true,
    submitError: true,
    dirtySinceLastSubmit: true,
    submitFailed: true,
};
export const fieldSubscription = Object.assign(Object.assign({}, inputSubscription), errorSubscription);

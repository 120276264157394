var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useForm } from 'components/Form';
import { Autocomplete } from 'components';
import { useFieldValues } from 'hooks';
import { http } from 'utils';
function getFloorListForSelect(сonstructionSiteId) {
    return __awaiter(this, void 0, void 0, function* () {
        const zones = yield http.get('/api/indoor/mappictures/getbycsiteid', {
            сonstructionSiteId,
        });
        return zones.sort((a, b) => (b === null || b === void 0 ? void 0 : b.floor) - (a === null || a === void 0 ? void 0 : a.floor));
    });
}
const getFloorOptionValue = (zone) => zone.id;
const getFloorOptionLabel = (zone) => zone.floor.toString();
function FloorSelect(_a) {
    var { siteFieldName = 'siteId', name, label, multiple, disabled, filterValues, onChange } = _a, props = __rest(_a, ["siteFieldName", "name", "label", "multiple", "disabled", "filterValues", "onChange"]);
    const siteFieldValue = useFieldValues(siteFieldName);
    const { data: zoneList = [], isFetching } = useQuery(['SELECT/FLOOR', siteFieldValue], () => getFloorListForSelect(siteFieldValue), { staleTime: 10 * 60 * 1000, enabled: Boolean(siteFieldValue), keepPreviousData: false });
    const form = useForm();
    const hangeChange = useCallback((zoneId) => {
        var _a;
        const floor = (_a = zoneList === null || zoneList === void 0 ? void 0 : zoneList.find(z => z.id === zoneId)) === null || _a === void 0 ? void 0 : _a.floor;
        form.change('floor', floor);
        onChange === null || onChange === void 0 ? void 0 : onChange(zoneId, floor);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [zoneList]);
    if (!zoneList || zoneList.length === 0)
        return null;
    return (React.createElement(Autocomplete, Object.assign({ name: name || 'indoorZoneId', multiple: multiple, label: label !== null && label !== void 0 ? label : (multiple ? 'Этажи' : 'Этаж'), getOptionValue: getFloorOptionValue, getOptionLabel: getFloorOptionLabel, options: zoneList, disabled: disabled !== null && disabled !== void 0 ? disabled : isFetching, filterValues: filterValues !== null && filterValues !== void 0 ? filterValues : !isFetching, onChange: hangeChange }, props)));
}
export default React.memo(FloorSelect);

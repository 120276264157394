import DetailsIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import ReadMoreIcon from 'components/icons/ReadMore';
const templates = {
    details: {
        icon: React.createElement(DetailsIcon, null),
        text: 'Посмотреть',
    },
    delete: {
        icon: React.createElement(DeleteIcon, null),
        text: 'Удалить',
    },
    readmore: {
        icon: React.createElement(ReadMoreIcon, null),
        text: 'Подробнее',
    },
};
export default templates;

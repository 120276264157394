var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import WatchIcon from '@material-ui/icons/Watch';
import CardIcon from '@material-ui/icons/CreditCard';
function DeviceIcon(_a) {
    var { type } = _a, props = __rest(_a, ["type"]);
    const CurrentIcon = type === 'card' ? CardIcon : WatchIcon;
    return React.createElement(CurrentIcon, Object.assign({}, props));
}
export default React.memo(DeviceIcon);

import { Select, SelectItem } from 'components';
function PageSizeSelect({ children }) {
    const _children = children || [
        React.createElement(SelectItem, { key: 0, value: 5, label: "5" }),
        React.createElement(SelectItem, { key: 1, value: "", label: "10" }),
        React.createElement(SelectItem, { key: 2, value: 15, label: "15" }),
        React.createElement(SelectItem, { key: 3, value: 25, label: "25" }),
        React.createElement(SelectItem, { key: 4, value: 50, label: "50" }),
    ];
    return (React.createElement(Select, { displayEmpty: true, defaultValue: 10, name: "pageSize", label: "\u042D\u043B\u0435\u043C\u0435\u043D\u0442\u043E\u0432 \u043D\u0430 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0435" }, _children));
}
export default PageSizeSelect;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Checkbox, TextField, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { http } from 'utils';
import { Chart, getSizeToFitBars, addValueLabelsToBars } from 'components';
import styles from './styles.module.scss';
const autocompleteProps = {
    renderOption: (option, { selected }) => (React.createElement(React.Fragment, null,
        React.createElement(Checkbox, { checked: selected }),
        option)),
    renderTags: (values) => (values.length > 0 ? values.join(', ') : 'Все'),
    getOptionLabel: option => option || '',
    renderInput: inputProps => React.createElement(TextField, Object.assign({}, inputProps, { variant: "standard", label: "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u0438" })),
};
const barOptions = {
    barThickness: 12,
    barPercentage: 0.6,
    categoryPercentage: 1,
};
const padding = {
    top: 10,
    right: 20,
    bottom: 10,
    left: 10,
};
const chartOptions = {
    plugins: {
        legend: {
            display: false,
        },
    },
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio: 1,
    layout: {
        padding,
    },
    scales: {
        x: {
            display: false,
        },
        y: {
            grid: {
                display: false,
                borderColor: 'transparent',
            },
        },
    },
    hover: {
        mode: null,
    },
    animation: {
        // duration: 300,
        onComplete: ({ chart }) => addValueLabelsToBars(chart),
    },
};
function fetchWorkerPresenceData(siteId, date) {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/dashboards/workersinproject', {
            zoneGroupId: siteId,
            dateTime: date.toISOString().slice(0, 10),
        });
        // fragment for debugging
        // return [
        //   { groupName: 'Начальник участка', count: 1 },
        //   { groupName: 'Подсобный рабочий', count: 1 },
        //   { groupName: 'Производитель работ', count: 2 },
        //   { groupName: 'Электрогазосварщик', count: 2 },
        //   { groupName: 'Механик', count: 1 },
        //   { groupName: 'Монтажник', count: 9 },
        //   { groupName: 'Электромонтер', count: 1 },
        //   { groupName: 'Мойщик', count: 1 },
        //   { groupName: 'Монтажник наружных трубопроводов', count: 1 },
        //   { groupName: 'Машинист', count: 2 },
        //   { groupName: 'Разнорабочий', count: 3 },
        //   { groupName: 'Водитель', count: 1 },
        //   { groupName: 'Рабочий', count: 1 },
        //   { groupName: 'Сварщик', count: 1 },
        // ];
        return result.sort((item1, item2) => item2.count - item1.count);
    });
}
function WorkerPresenceWidget({ siteId }) {
    const now = new Date();
    const [date, setDate] = useState(now);
    const [selectedPositions, setSelectedPositions] = useState([]);
    const handleChange = useCallback((event, newValue) => setSelectedPositions(newValue), []);
    const { data = [], isLoading } = useQuery(['WIDGETS/WORKERS_PRESENCE', siteId, date], () => fetchWorkerPresenceData(siteId, date), { enabled: Boolean(siteId), refetchInterval: 10000 });
    const positions = useMemo(() => data.map(tickData => tickData.groupName), [data]);
    const filteredData = useMemo(() => selectedPositions.length === 0
        ? data
        : data.filter(tickData => selectedPositions.includes(tickData.groupName)), [data, selectedPositions]);
    const chartData = useMemo(() => ({
        labels: filteredData.map(tickData => tickData.groupName),
        datasets: [
            Object.assign(Object.assign({ backgroundColor: '#9ac2ff' }, barOptions), { data: filteredData.map(tickData => tickData.count) }),
        ],
    }), [filteredData]);
    const canvasProps = useMemo(() => ({
        style: {
            height: getSizeToFitBars(filteredData.length, barOptions, padding.top + padding.bottom) + 'px',
        },
    }), [filteredData.length]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.controls },
            React.createElement(DatePicker, { disableToolbar: true, inputVariant: "standard", label: "\u0414\u0430\u0442\u0430", maxDate: now, value: date, onChange: setDate }),
            React.createElement(Autocomplete, Object.assign({ fullWidth: true, multiple: true, disableCloseOnSelect: true, options: positions, value: selectedPositions, onChange: handleChange }, autocompleteProps))),
        React.createElement("div", { style: { display: filteredData.length === 0 ? 'none' : undefined } },
            React.createElement(Chart, { canvasProps: canvasProps, type: "bar", options: chartOptions, data: chartData })),
        siteId && filteredData.length === 0 && !isLoading && (React.createElement("div", { className: styles.empty },
            React.createElement(Typography, null, "\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u044B \u043E\u0442\u0441\u0443\u0442\u0441\u0432\u043E\u0432\u0430\u043B\u0438 \u043D\u0430 \u043E\u0431\u044A\u0435\u043A\u0442\u0435")))));
}
export default WorkerPresenceWidget;

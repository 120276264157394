// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A2sbQTwDh4YUviUrZQoE{padding:24px;overflow:auto}.g6Ng33aCXvCjd3QxlzAH .MuiDialog-paper{min-width:500px;height:auto}", "",{"version":3,"sources":["webpack://./src/pages/admin/Biometry/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CAIA,uCACE,eAAA,CACA,WAAA","sourcesContent":[".root {\n  padding: 24px;\n  overflow: auto;\n}\n\n.requestDialog {\n  :global(.MuiDialog-paper) {\n    min-width: 500px;\n    height: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "A2sbQTwDh4YUviUrZQoE",
	"requestDialog": "g6Ng33aCXvCjd3QxlzAH"
};
export default ___CSS_LOADER_EXPORT___;

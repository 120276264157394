var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Leaflet, { CRS } from 'leaflet';
import screenfull from 'screenfull';
import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.vectorgrid';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet-toolbar';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet-toolbar/dist/leaflet.toolbar.css';
import 'leaflet-distortableimage';
import 'leaflet-distortableimage/dist/leaflet.distortableimage.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerRetinaIcon from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadowIcon from 'leaflet/dist/images/marker-shadow.png';
import tiles from './tiles';
import MapElementCollection from './MapElementCollection';
import markerFactory from './factories/markerFactory';
import circleMarkerFactory from './factories/circleMarkerFactory';
import polygonFactory from './factories/polygonFactory';
import polylineFactory from './factories/polylineFactory';
import progressivePolylineFactory from './factories/progressivePolylineFactory';
import polylineDecoratorFactory from './factories/polylineDecoratorFactory';
import MapTooltip from './MapTooltip';
import MarkerClusterContainer from './MarkerClusterContainer';
import FeatureGroupContainer from './FeatureGroupContainer';
import vectorTileLayerStyles from './vectorTileStyles';
import styles from './styles.module.scss';
window.screenfull = screenfull;
// const includeFeatures = Object.keys(vectorTileLayerStyles);
const L = Leaflet;
delete Leaflet.Icon.Default.prototype['_getIconUrl'];
Leaflet.Icon.Default.mergeOptions({
    iconUrl: markerIcon,
    iconRetinaUrl: markerRetinaIcon,
    shadowUrl: markerShadowIcon,
});
// draw locale
Leaflet.drawLocal.draw.toolbar.buttons.polygon = 'Нарисовать полигон';
Leaflet.drawLocal.draw.toolbar.finish.text = 'Сохранить';
Leaflet.drawLocal.draw.toolbar.actions.text = 'Отмена';
Leaflet.drawLocal.draw.toolbar.undo.text = 'Удалить последнюю точку';
Leaflet.drawLocal.draw.handlers.polygon.tooltip.start = 'Кликните чтобы начать рисовать';
Leaflet.drawLocal.draw.handlers.polygon.tooltip.cont = 'Кликните чтобы продолжить рисовать';
Leaflet.drawLocal.draw.handlers.polygon.tooltip.end =
    'Кликните первую точку чтобы закончить рисовать';
// edit locale
Leaflet.drawLocal.edit.toolbar.buttons.edit = 'Редактировать';
Leaflet.drawLocal.edit.toolbar.buttons.editDisabled = 'Нечего редактировать';
Leaflet.drawLocal.edit.toolbar.buttons.remove = 'Удалить';
Leaflet.drawLocal.edit.toolbar.buttons.removeDisabled = 'Нечего удалять';
Leaflet.drawLocal.edit.toolbar.actions.save.text = 'Сохранить';
Leaflet.drawLocal.edit.toolbar.actions.cancel.text = 'Отмена';
Leaflet.drawLocal.edit.toolbar.actions.clearAll.text = 'Очистить все';
Leaflet.drawLocal.edit.handlers.remove.tooltip.text = 'Кликните на объект, чтобы удалить';
Leaflet.drawLocal.edit.handlers.edit.tooltip.subtext = 'Нажмите отмена, чтобы сбросить изменения';
Leaflet.drawLocal.edit.handlers.edit.tooltip.text =
    'Перетащите вершины или маркеры, чтобы редактировать объект';
class MapContainer {
    constructor() {
        this.rootNode = document.createElement('div');
        this.layer = Leaflet.map(this.rootNode, {
            fullscreenControl: true,
        });
        this.markerCluster = new MarkerClusterContainer(this);
        this.alertMarkerCluster = new MarkerClusterContainer(this);
        this.markers = new MapElementCollection(this, markerFactory);
        this.alertMarkers = new MapElementCollection(this, markerFactory);
        this.circleMarkers = new MapElementCollection(this, circleMarkerFactory);
        this.polygons = new MapElementCollection(this, polygonFactory);
        this.polylines = new MapElementCollection(this, polylineFactory);
        this.progressivePolylines = new MapElementCollection(this, progressivePolylineFactory);
        this.polylineDecorators = new MapElementCollection(this, polylineDecoratorFactory);
        this.tooltip = new MapTooltip();
        this.interval = null;
        this.mounted = false;
        this.onEditableElementsChange = () => undefined;
        this._disabled = false;
        this._editableElements = new FeatureGroupContainer(this);
        this._editableImage = true;
        this._editableImageZIndex = 300;
        this.appendMap = this.appendMap.bind(this);
        this.mouseupCallback = this.mouseupCallback.bind(this);
    }
    initialize(node) {
        const { layer: map } = this;
        map.addLayer(this.markerCluster.layer);
        map.addLayer(this.alertMarkerCluster.layer);
        // map.addControl(new Leaflet.Control.FullScreen());
        map.attributionControl.remove();
        // this.rootNode.addEventListener('wheel', event => {
        //   const { clientX, clientY } = event;
        //   this.tooltip.move(clientX, clientY);
        // });
        let lastMouseMoveTimeout = null;
        const showTooltip = () => this.tooltip.show();
        this.rootNode.addEventListener('mousemove', event => {
            this.tooltip.hide();
            // const { clientX, clientY } = event;
            // this.tooltip.move(clientX, clientY);
            clearTimeout(lastMouseMoveTimeout);
            lastMouseMoveTimeout = setTimeout(showTooltip, 200);
        });
        const tileControls = {};
        let defaultTileLayer;
        for (const tile of tiles) {
            const { name, url, vector } = tile, options = __rest(tile, ["name", "url", "vector"]);
            let layer;
            if (vector) {
                // @ts-expect-error
                layer = Leaflet.vectorGrid.protobuf(url, Object.assign(Object.assign({ 
                    // @ts-expect-error
                    rendererFactory: Leaflet.canvas.tile, 
                    // interactive: true,
                    vectorTileLayerStyles }, options), { style: 'mapbox://styles/mapbox/streets-v9' }));
                // // @ts-expect-error
                // layer.setFeatureStyle('hz', []);
            }
            else
                layer = Leaflet.tileLayer(url, options);
            if (defaultTileLayer === undefined)
                defaultTileLayer = layer;
            tileControls[name] = layer;
        }
        map.addLayer(defaultTileLayer);
        Leaflet.control.layers(tileControls).addTo(map);
        map.addEventListener('baselayerchange', (event) => {
            const center = map.getCenter();
            const zoom = map.getZoom();
            const tile = tiles.find(tile => tile.name === event.name);
            if (tile.crs != null)
                map.options.crs = tile.crs;
            else
                map.options.crs = CRS.EPSG3857;
            map.setView(center, zoom);
        });
        this.rootNode.classList.add(styles.rootNode);
        node.appendChild(this.rootNode);
        this.tooltip.appendToBody();
        let sizes = this.rootNode.getBoundingClientRect();
        this.interval = window.setInterval(() => {
            if (!this.mounted)
                return;
            const newSizes = this.rootNode.getBoundingClientRect();
            if (newSizes.height !== sizes.height || newSizes.width !== sizes.width) {
                sizes = newSizes;
                this.layer.invalidateSize();
            }
        }, 1000);
        map.on('draw:created', (event) => {
            var _a;
            switch (event.layerType) {
                case 'polygon': {
                    const layer = event.layer;
                    const newPolygon = {
                        id: new Date().getTime(),
                        color: layer.options.color,
                        fillOpacity: layer.options.fillOpacity,
                        // @ts-expect-error
                        latlngs: layer.getLatLngs()[0].map(({ lat, lng }) => [lat, lng]),
                    };
                    const featureGroup = this._editableElements.featureGroup;
                    const polygons = (_a = featureGroup === null || featureGroup === void 0 ? void 0 : featureGroup.polygons) !== null && _a !== void 0 ? _a : [];
                    this.onEditableElementsChange(Object.assign(Object.assign({}, featureGroup), { polygons: [...polygons, newPolygon] }));
                    break;
                }
            }
            // this._editableLayers.layer.addLayer(e.layer);
        });
        map.on('draw:deleted', (event) => {
            const layers = event.layers;
            const { _editableElements } = this;
            const elements = Object.assign({}, _editableElements.featureGroup);
            layers.eachLayer(layer => {
                if (layer instanceof Leaflet.Polygon) {
                    const id = _editableElements.polygons.getElementIdByLayer(layer);
                    const index = elements.polygons.findIndex(el => el.id === id);
                    elements.polygons = [
                        ...elements.polygons.slice(0, index),
                        ...elements.polygons.slice(index + 1),
                    ];
                    // _editableElements.polygons.deleteElementById(id);
                }
            });
            this.onEditableElementsChange(elements);
        });
        map.on('draw:edited', (event) => {
            const layers = event.layers;
            const { _editableElements } = this;
            const elements = Object.assign({}, _editableElements.featureGroup);
            layers.eachLayer(layer => {
                if (layer instanceof Leaflet.Polygon) {
                    const id = _editableElements.polygons.getElementIdByLayer(layer);
                    const index = elements.polygons.findIndex(el => el.id === id);
                    elements.polygons[index] = Object.assign(Object.assign({}, elements.polygons[index]), { color: layer.options.color, fillOpacity: layer.options.fillOpacity, 
                        // @ts-expect-error
                        latlngs: layer.getLatLngs()[0].map(({ lat, lng }) => [lat, lng]) });
                }
            });
            this.onEditableElementsChange(elements);
        });
        map.on('click', (event) => {
            var _a;
            (_a = this.onClick) === null || _a === void 0 ? void 0 : _a.call(this, event);
        });
        map.on('draw:drawvertex', (event) => {
            var _a;
            // @ts-expect-error
            (_a = this.onDrawVertex) === null || _a === void 0 ? void 0 : _a.call(this, event, this._drawControl._toolbars.draw._modes.polygon.handler);
        });
        map.on('draw:editvertex', (event) => {
            var _a;
            // @ts-expect-error
            (_a = this.onEditVertex) === null || _a === void 0 ? void 0 : _a.call(this, event, this._drawControl._toolbars.edit._modes.edit.handler);
        });
        const beaconsPane = map.createPane('beacons');
        beaconsPane.style.zIndex = '400';
        // map.options.maxZoom = 25;
    }
    set maxZoom(value) {
        this.layer.options.maxZoom = value;
    }
    appendMap(node) {
        return __awaiter(this, void 0, void 0, function* () {
            if (node !== null) {
                this.initialize(node);
                this.mounted = true;
            }
            else {
                this.mounted = false;
                setTimeout(() => {
                    try {
                        this.tooltip.removeFromBody();
                        this.layer.remove();
                    }
                    catch (err) {
                        //
                    }
                });
                clearInterval(this.interval);
            }
        });
    }
    enable() {
        const { layer, rootNode } = this;
        layer.dragging.enable();
        layer.touchZoom.enable();
        layer.doubleClickZoom.enable();
        layer.scrollWheelZoom.enable();
        layer.boxZoom.enable();
        layer.keyboard.enable();
        if (layer.tap)
            layer.tap.enable();
        rootNode.style.cursor = 'grab';
    }
    disable() {
        const { layer, rootNode } = this;
        layer.dragging.disable();
        layer.touchZoom.disable();
        layer.doubleClickZoom.disable();
        layer.scrollWheelZoom.disable();
        layer.boxZoom.disable();
        layer.keyboard.disable();
        if (layer.tap)
            layer.tap.disable();
        rootNode.style.cursor = 'default';
    }
    get disabled() {
        return this._disabled;
    }
    set disabled(value) {
        if (this._disabled === value)
            return;
        if (value)
            this.disable();
        else
            this.enable();
    }
    get editableElements() {
        return this._editableElements.featureGroup;
    }
    set editableElements(value) {
        if (value === this._editableElements.featureGroup)
            return;
        this._editableElements.updateAll(value);
    }
    get drawingOptions() {
        return this._drawingOptions;
    }
    set drawingOptions(value) {
        if (this._drawingOptions === value)
            return;
        if (this._drawControl !== undefined)
            this.layer.removeControl(this._drawControl);
        if (value) {
            this._drawControl = new Leaflet.Control.Draw(Object.assign(Object.assign({}, value), { edit: Object.assign(Object.assign({}, value.edit), { featureGroup: this._editableElements.layer }) }));
            this.layer.addControl(this._drawControl);
            // refactor needed
            try {
                const editHandler = 
                // @ts-expect-error
                this._drawControl._toolbars.edit._modes.edit.handler;
                let startPoly = null;
                // @ts-expect-error
                this._drawControl._container
                    .getElementsByClassName('leaflet-draw-edit-edit')[0]
                    .addEventListener('click', () => {
                    // @ts-expect-error
                    startPoly = editHandler._featureGroup
                        .getLayers()[0]
                        .getLatLngs()[0]
                        .map(({ lat, lng }) => ({ lat, lng }));
                });
                // @ts-expect-error
                editHandler.on('enabled', () => {
                    // @ts-expect-error
                    this._drawControl._toolbars.edit._actionButtons[1].button.addEventListener('click', () => {
                        // @ts-expect-error
                        const layer = editHandler._featureGroup.getLayers()[0];
                        // @ts-expect-error
                        editHandler._uneditedLayerProps[layer._leaflet_id] = {};
                        // @ts-expect-error
                        editHandler._uneditedLayerProps[layer._leaflet_id].latlngs = [
                            ...startPoly.map(p => (Object.assign({}, p))),
                        ];
                        // @ts-expect-error
                        editHandler.revertLayers();
                        // @ts-expect-error
                        editHandler._uneditedLayerProps[layer._leaflet_id] = undefined;
                        // @ts-expect-error
                        editHandler.custom_lastPoints = undefined;
                    });
                });
            }
            catch (_a) {
                //
            }
            //
        }
        else {
            this._drawControl = undefined;
        }
    }
    get distortableImage() {
        return this._distortableImageSrc;
    }
    set distortableImage(value) {
        var _a;
        if (this._distortableImageSrc === value)
            return;
        if (this._distortableImageLayer != null) {
            this.layer.removeLayer(this._distortableImageLayer);
            // this._distortableImageLayer.clearAllEventListeners();
            // const img: HTMLImageElement = this._distortableImageLayer._image;
            // img.parentNode?.removeChild(img);
        }
        if (value) {
            this._distortableImageSrc = value;
            this._distortableImageLayer = L.distortableImageOverlay(value, {
                selected: true,
                translation: {
                    distortImage: 'Искривить',
                    dragImage: 'Передвинуть',
                    lockMode: 'Заблокировать редактирование',
                    makeImageOpaque: 'Сделать не прозрачной',
                    makeImageTransparent: 'Сделать прозрачной',
                    rotateImage: 'Повернуть',
                    freeRotateImage: 'Повернуть свободно',
                    scaleImage: 'Масштабировать',
                },
                actions: [
                    L.DragAction,
                    L.ScaleAction,
                    L.DistortAction,
                    L.RotateAction,
                    L.FreeRotateAction,
                    L.LockAction,
                    L.OpacityAction,
                ],
                corners: (_a = this._corners) !== null && _a !== void 0 ? _a : undefined,
                editable: this._editableImage,
            });
            this.layer.addLayer(this._distortableImageLayer);
            // ugly hack
            try {
                this._distortableImageLayer._image.style.setProperty('z-index', this._editableImageZIndex);
                if (this._editableImage)
                    this._distortableImageLayer.scaleBy(1);
            }
            catch (err) {
                //
            }
            this.layer.addEventListener('mouseup', this.mouseupCallback);
        }
        else {
            this._distortableImageSrc = undefined;
            this._distortableImageLayer = undefined;
            try {
                this.layer.removeEventListener('mouseup', this.mouseupCallback);
            }
            catch (err) {
                //
            }
        }
    }
    mouseupCallback() {
        var _a, _b, _c, _d, _e, _f, _g;
        const corners = this._distortableImageLayer.getCorners().map(c => {
            if (c instanceof Array)
                return c;
            const { lat, lng } = c;
            return [lat, lng];
        });
        let diff = false;
        for (let index = 0; index < 4; index++) {
            if (((_a = corners === null || corners === void 0 ? void 0 : corners[index]) === null || _a === void 0 ? void 0 : _a[0]) !== ((_c = (_b = this._corners) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c[0]) ||
                ((_d = corners === null || corners === void 0 ? void 0 : corners[index]) === null || _d === void 0 ? void 0 : _d[1]) !== ((_f = (_e = this._corners) === null || _e === void 0 ? void 0 : _e[index]) === null || _f === void 0 ? void 0 : _f[1])) {
                diff = true;
                break;
            }
        }
        if (diff) {
            this._corners = corners;
            (_g = this.onDistortImage) === null || _g === void 0 ? void 0 : _g.call(this, corners);
        }
    }
    get corners() {
        return this._corners;
    }
    set corners(value) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        let diff = false;
        for (let index = 0; index < 4; index++) {
            if (((_a = value === null || value === void 0 ? void 0 : value[index]) === null || _a === void 0 ? void 0 : _a[0]) !== ((_c = (_b = this._corners) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c[0]) ||
                ((_d = value === null || value === void 0 ? void 0 : value[index]) === null || _d === void 0 ? void 0 : _d[1]) !== ((_f = (_e = this._corners) === null || _e === void 0 ? void 0 : _e[index]) === null || _f === void 0 ? void 0 : _f[1])) {
                diff = true;
                break;
            }
        }
        if (diff) {
            this._corners = value;
            (_g = this._distortableImageLayer) === null || _g === void 0 ? void 0 : _g.setCorners((_h = value === null || value === void 0 ? void 0 : value.map(c => Leaflet.latLng(c === null || c === void 0 ? void 0 : c[0], c === null || c === void 0 ? void 0 : c[1]))) !== null && _h !== void 0 ? _h : []);
        }
    }
    get editableImage() {
        return this._editableImage;
    }
    set editableImage(value) {
        var _a, _b, _c, _d;
        if (this._editableImage === value)
            return;
        this._editableImage = value;
        if (this._distortableImageLayer) {
            if (value)
                (_b = (_a = this._distortableImageLayer).enable) === null || _b === void 0 ? void 0 : _b.call(_a);
            else
                (_d = (_c = this._distortableImageLayer).disable) === null || _d === void 0 ? void 0 : _d.call(_c);
        }
    }
    get editableImageZIndex() {
        return this._editableImageZIndex;
    }
    set editableImageZIndex(value) {
        var _a;
        this._editableImageZIndex = value;
        try {
            if ((_a = this._distortableImageLayer) === null || _a === void 0 ? void 0 : _a._image)
                this._distortableImageLayer._image.style.setProperty('z-index', value);
        }
        catch (err) {
            //
        }
    }
}
export default MapContainer;

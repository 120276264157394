var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import http from './http';
const deviceTypeByName = {
    Часы: 'watch',
    Карта: 'card',
    Планшет: 'tablet',
};
const deviceTypeById = new Map();
const deviceIdByType = new Map();
export function fetchDeviceTypeList() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const fields = 'id,name';
            const { result } = yield http.get('/api/kbi/control-device/models', {
                fields,
            });
            if (!result)
                return;
            for (const deviceName in deviceTypeByName) {
                const id = result.find(({ name }) => name === deviceName).id;
                const type = deviceTypeByName[deviceName];
                deviceTypeById.set(id, type);
                deviceIdByType.set(type, id);
            }
        }
        catch (err) {
            //
        }
    });
}
export function getDeviceTypeByName(name) {
    return deviceTypeByName[name];
}
export function getDeviceTypeById(id) {
    return deviceTypeById.get(id);
}
export function getDeviceId(type) {
    return deviceIdByType.get(type);
}

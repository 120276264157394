var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from '@material-ui/core';
import { useMutableState } from 'hooks';
import { useDialogContext } from './DialogContext';
import templates from './templates';
function DialogButton(_a) {
    var { render, component } = _a, props = __rest(_a, ["render", "component"]);
    const { open, disabled } = useDialogContext();
    const current = useMutableState();
    if (open)
        current.props = Object.assign(Object.assign({ disabled }, templates[props.template]), props);
    if (render != null)
        return React.cloneElement(render, current.props);
    const Component = component || Button;
    return React.createElement(Component, Object.assign({}, current.props));
}
export default React.memo(DialogButton);

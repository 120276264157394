const containerList = new Map();
function createPortalContainer(key) {
    const inner = document.createElement('span');
    inner.style.setProperty('display', 'contents');
    function appendInner(node) {
        if (node) {
            node.appendChild(inner);
            node.style.setProperty('display', 'contents');
        }
    }
    return {
        inner,
        outer: React.createElement("span", { id: key, ref: appendInner }),
    };
}
function _getContainer(key) {
    let container = containerList.get(key);
    if (!container) {
        container = createPortalContainer(key);
        containerList.set(key, container);
    }
    return container;
}
export function getPortalContainer(key) {
    const container = _getContainer(key);
    return container.inner;
}
export function renderPortalContainer(key) {
    const container = _getContainer(key);
    return container.outer;
}

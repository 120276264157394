var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Drawer, SwipeableDrawer, Typography, } from '@material-ui/core';
import { store } from 'app';
import { ResetButton, SubmitButton } from 'components';
import { Form } from 'components/Form';
import { useLayout, useQueryParams } from 'hooks';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { updateQueryParams } from 'utils';
import clone from 'utils/clone';
import { getPortalContainer } from 'utils/contents';
import styles from './styles.module.scss';
const rightbarContainer = getPortalContainer('rightbar-container');
const closeFilterBar = () => {
    store.dispatch({ type: 'CLOSE_RIGHTBAR' });
};
const openFilterBar = () => {
    store.dispatch({ type: 'TOGGLE_RIGHTBAR' });
};
const filterBarSelector = ({ layout }) => {
    return layout.rightbarOpen;
};
const drawerStaticProps = {
    anchor: 'right',
    classes: { paper: styles.drawerPaper },
};
function FilterBar(_a) {
    var { children, initialValues } = _a, props = __rest(_a, ["children", "initialValues"]);
    const open = useSelector(filterBarSelector, shallowEqual);
    const layout = useLayout();
    const _b = useQueryParams(), { page, 
    // pageSize,
    search } = _b, filter = __rest(_b, ["page", "search"]);
    const recent = useRef({}).current;
    recent.emptyFilter = clone(filter, null);
    const handleSubmit = useCallback(({ values }) => {
        // for (const key in values) {
        //   const value = values[key];
        //   if (value instanceof Date) values[key] = value.toISOString();
        // }
        updateQueryParams(Object.assign(Object.assign(Object.assign({}, recent.emptyFilter), values), { page: undefined }));
        if (layout === 'mobile')
            closeFilterBar();
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layout]);
    const innerRef = useRef();
    useLayoutEffect(() => {
        if (layout === 'mobile')
            return;
        const bodyStyle = document.body.style;
        if ((layout === 'tablet' || layout === 'desktop') && !open) {
            bodyStyle.setProperty('--rightbar-width', '0px');
            return;
        }
        const { width } = innerRef.current.getBoundingClientRect();
        bodyStyle.setProperty('--rightbar-width', width + 'px');
        return () => bodyStyle.setProperty('--rightbar-width', '0px');
    }, [open, layout]);
    const drawerProps = useMemo(() => {
        switch (layout) {
            case 'mobile': {
                return {
                    variant: 'temporary',
                    onClose: closeFilterBar,
                    ModalProps: { keepMounted: true },
                    open,
                    onOpen: openFilterBar,
                };
            }
            case 'tablet': {
                return {
                    variant: 'persistent',
                    open,
                    style: { width: open ? undefined : '0px' },
                };
            }
            case 'desktop': {
                return {
                    variant: 'persistent',
                    open,
                    style: { width: open ? undefined : '0px' },
                };
            }
        }
    }, [layout, open]);
    const DrawerComponent = layout !== 'mobile' ? Drawer : SwipeableDrawer;
    const rightbar = (
    // @ts-expect-error
    React.createElement(DrawerComponent, Object.assign({}, drawerStaticProps, drawerProps, { className: styles.drawer }),
        React.createElement(Form, Object.assign({ initialValues: initialValues, values: Object.assign(Object.assign({}, initialValues), filter), onSubmit: handleSubmit }, props),
            React.createElement("span", { ref: innerRef, className: styles.inner },
                React.createElement("div", { className: styles.header },
                    React.createElement(Typography, { variant: "h6" }, "\u0424\u0438\u043B\u044C\u0442\u0440\u044B")),
                React.createElement("div", { className: styles.content }, React.Children.map(children, child => (React.createElement("span", { style: { display: 'flex', flexDirection: 'column' } }, child)))),
                React.createElement("div", { className: styles.footer },
                    React.createElement(ResetButton, { label: "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C", variant: "outlined" }),
                    React.createElement(SubmitButton, null, "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C"))))));
    return ReactDOM.createPortal(rightbar, rightbarContainer);
}
export default FilterBar;

import { Box, Tooltip } from '@material-ui/core';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import React from 'react';
import styles from './styles.module.scss';
function BeaconSwitcher({ showBeacon, onChange }) {
    return (React.createElement(Tooltip, { title: showBeacon ? 'Скрыть маяки' : 'Показать маяки' },
        React.createElement(Box, { className: styles.controlContainer, onClick: onChange }, showBeacon ? React.createElement(BlurOnIcon, null) : React.createElement(BlurOffIcon, null))));
}
export default BeaconSwitcher;

import { getIn } from 'final-form';
import { useForm } from './FormContext';
import { useName } from './NameContext';
function useFieldValue(name) {
    name = useName(name);
    const form = useForm();
    const [fieldState, setFieldState] = useState(() => { var _a, _b, _c; return (_c = (_b = (_a = form.getFieldState(name)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : getIn(form.getState().values, name)) !== null && _c !== void 0 ? _c : {}; });
    useEffect(() => {
        const unsubscribe = form.registerField(name, setFieldState, { value: true });
        return unsubscribe;
    }, [name]);
    return fieldState.value;
}
export default useFieldValue;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JbYQ49RsZJ4L8HNTNhfE{width:100%;max-width:350px}.JbYQ49RsZJ4L8HNTNhfE .MuiInputBase-root{color:inherit}.JbYQ49RsZJ4L8HNTNhfE .MuiFormControl-root{margin:0px}.JbYQ49RsZJ4L8HNTNhfE .MuiInput-underline:before{border-bottom-color:rgba(0,0,0,0) !important}", "",{"version":3,"sources":["webpack://./src/components/TopbarField/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,eAAA,CACA,yCACE,aAAA,CAEF,2CACE,UAAA,CAEF,iDACE,4CAAA","sourcesContent":[".root {\n  width: 100%;\n  max-width: 350px;\n  :global(.MuiInputBase-root) {\n    color: inherit;\n  }\n  :global(.MuiFormControl-root) {\n    margin: 0px;\n  }\n  :global(.MuiInput-underline:before) {\n    border-bottom-color: transparent !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "JbYQ49RsZJ4L8HNTNhfE"
};
export default ___CSS_LOADER_EXPORT___;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { http } from 'utils';
import { Chart, addValueLabelsToBars } from 'components';
const barOptions = {
    barThickness: 24,
    barPercentage: 0.8,
    categoryPercentage: 1,
};
const padding = {
    top: 29,
    right: 10,
    bottom: 10,
    left: 10,
};
const chartOptions = {
    plugins: {
        legend: {
            display: false,
        },
    },
    indexAxis: 'x',
    maintainAspectRatio: false,
    aspectRatio: 1,
    layout: {
        padding,
    },
    scales: {
        x: {
            grid: {
                display: false,
                borderColor: 'transparent',
            },
        },
        y: {
            grid: {
                borderColor: 'transparent',
            },
            ticks: {
                display: false,
                stepSize: 1,
                count: 6,
            },
        },
    },
    hover: {
        mode: null,
    },
    animation: {
        onComplete: ({ chart }) => addValueLabelsToBars(chart, true),
    },
};
function fetchTotalWorkersData(siteId) {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/dashboards/workersinprojectgrouphours', {
            zoneGroupId: siteId,
        });
        // fragment for debugging
        // return [
        //   { groupName: '00:00', count: 4 },
        //   { groupName: '01:00', count: 2 },
        //   { groupName: '02:00', count: 2 },
        //   { groupName: '03:00', count: 1 },
        //   { groupName: '04:00', count: 0 },
        //   { groupName: '05:00', count: 1 },
        //   { groupName: '06:00', count: 2 },
        //   { groupName: '07:00', count: 26 },
        // ];
        return result;
    });
}
function TotalWorkersWidget({ siteId }) {
    const { data = [] } = useQuery(['WIDGETS/TOTAL_WORKERS', siteId], () => fetchTotalWorkersData(siteId), {
        enabled: Boolean(siteId),
        refetchInterval: 10000,
    });
    const chartData = useMemo(() => ({
        labels: data.map(item => item.groupName),
        datasets: [
            Object.assign(Object.assign({ backgroundColor: '#9ac2ff' }, barOptions), { data: data.map(item => item.count) }),
        ],
    }), [data]);
    return React.createElement(Chart, { type: "bar", options: chartOptions, data: chartData });
}
export default TotalWorkersWidget;

const UserRoles = {
    DEV: "DEVELOPER",
    ADMIN: "ADMIN",
    ENT_MANAGER: "KBI_COMPANY_MNG",
    ENT_DISPATCHER: "KBI_COMPANY_DISP",
    OBJ_MANAGER: "KBI_OBJ_MNG",
    OBJ_DISPATCHER: "KBI_OBJ_DISP",
    TEMPADMIN: "OUTSIDE_ADMIN",
};
export default UserRoles;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rqhptI84nDguAzvaqnQM{color:rgba(0,0,0,.54)}.eM5OXv1RM9t7SYS7sjqA{padding-left:32px}", "",{"version":3,"sources":["webpack://./src/components/Menu/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CAGF,sBACE,iBAAA","sourcesContent":[".cascading-icon {\n  color: rgba(0, 0, 0, 0.54);\n}\n\n.nested-list {\n  padding-left: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cascading-icon": "rqhptI84nDguAzvaqnQM",
	"cascadingIcon": "rqhptI84nDguAzvaqnQM",
	"nested-list": "eM5OXv1RM9t7SYS7sjqA",
	"nestedList": "eM5OXv1RM9t7SYS7sjqA"
};
export default ___CSS_LOADER_EXPORT___;

function useMenuState(key) {
    const menuSelector = useCallback(({ layout: { menu } }) => (menu.key === key ? menu : { data: null, anchorEl: null }), [key]);
    const { data, anchorEl } = useSelector(menuSelector, shallowEqual);
    if (anchorEl == null)
        return { open: false };
    return {
        open: true,
        data,
        anchorEl,
    };
}
export default useMenuState;

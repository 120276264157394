var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ListItem, MenuItem as MuiMenuItem, ListItemIcon, ListItemText, } from '@material-ui/core';
import { useLayout } from 'hooks';
import closeMenu from './closeMenu';
import templates from './templates';
const MenuItem = React.forwardRef((_a, ref) => {
    var { onClick, data, disabled, className, template, children, disableCloseOnClick } = _a, props = __rest(_a, ["onClick", "data", "disabled", "className", "template", "children", "disableCloseOnClick"]);
    const { icon, text } = Object.assign(Object.assign({}, templates[template]), props);
    const layout = useLayout();
    const handleClick = useCallback(event => {
        if (onClick != null)
            onClick(event, data);
        if (!disableCloseOnClick)
            closeMenu();
    }, [onClick, disableCloseOnClick, data]);
    children =
        children !== undefined
            ? children
            : [
                icon && React.createElement(ListItemIcon, { key: "icon" }, icon),
                text && React.createElement(ListItemText, { key: "text" }, text),
            ];
    if (layout === 'mobile')
        return (React.createElement(ListItem, { button: true, disabled: disabled, className: className, onClick: handleClick }, children));
    return (React.createElement(MuiMenuItem, { disabled: disabled, ref: ref, className: className, onClick: handleClick }, children));
});
export default React.memo(MenuItem);

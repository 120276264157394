import Leaflet from 'leaflet';
import MapElementCollection from './MapElementCollection';
import markerFactory from './factories/markerFactory';
import circleMarkerFactory from './factories/circleMarkerFactory';
import polygonFactory from './factories/polygonFactory';
import polylineFactory from './factories/polylineFactory';
// import polylineDecoratorFactory from './factories/polylineDecoratorFactory';
import MarkerClusterContainer from './MarkerClusterContainer';
class FeatureGroupContainer {
    constructor(container) {
        this.container = container;
        this.layer = Leaflet.featureGroup();
        this.markerCluster = new MarkerClusterContainer(this);
        this.markers = new MapElementCollection(this, markerFactory);
        this.circleMarkers = new MapElementCollection(this, circleMarkerFactory);
        this.polygons = new MapElementCollection(this, polygonFactory);
        this.polylines = new MapElementCollection(this, polylineFactory);
        container.layer.addLayer(this.layer);
    }
    get tooltip() {
        return this.container.tooltip;
    }
    updateAll(featureGroup) {
        var _a, _b, _c, _d;
        const { markers, polygons, circleMarkers, polylines } = featureGroup !== null && featureGroup !== void 0 ? featureGroup : {};
        if (polygons !== ((_a = this.featureGroup) === null || _a === void 0 ? void 0 : _a.polygons))
            this.polygons.updateAll(polygons == null ? [] : polygons);
        if (markers !== ((_b = this.featureGroup) === null || _b === void 0 ? void 0 : _b.markers))
            this.markers.updateAll(markers == null ? [] : markers);
        if (circleMarkers !== ((_c = this.featureGroup) === null || _c === void 0 ? void 0 : _c.circleMarkers))
            this.circleMarkers.updateAll(circleMarkers == null ? [] : circleMarkers);
        if (polylines !== ((_d = this.featureGroup) === null || _d === void 0 ? void 0 : _d.polylines))
            this.polylines.updateAll(polylines == null ? [] : polylines);
        // @ts-expect-error
        this.featureGroup = featureGroup;
    }
}
export default FeatureGroupContainer;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ARRAY_ERROR } from 'final-form';
import { useForm } from './FormContext';
import { useFormProps } from './FormPropsContext';
import useField from './useField';
const arraySubscriptions = {
    length: true,
};
function useFieldArray(name, config) {
    const { validate: validateFn, } = config !== null && config !== void 0 ? config : {};
    const { disabled, readOnly } = useFormProps();
    const form = useForm();
    const validate = useCallback((values) => __awaiter(this, void 0, void 0, function* () {
        if (!validateFn)
            return undefined;
        const error = yield validateFn(values);
        if (!error || Array.isArray(error)) {
            return error;
        }
        return { [ARRAY_ERROR]: error };
    }), [validateFn]);
    const { length } = useField(name, {
        subscription: arraySubscriptions,
        validate,
    });
    const map = useCallback((callback) => {
        var _a;
        const { value: values } = (_a = form.getFieldState(name)) !== null && _a !== void 0 ? _a : Object();
        return (values !== null && values !== void 0 ? values : []).map((value, index) => callback(index));
    }, [name]);
    const arrayMutators = useMemo(() => {
        const { push, remove } = form.mutators;
        return {
            push: (value) => push(name, value),
            remove: (index) => remove(name, index),
        };
    }, [name]);
    return Object.assign(Object.assign({ map }, arrayMutators), { length: length !== null && length !== void 0 ? length : 0, readOnly, disabled });
}
export default useFieldArray;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Typography } from '@material-ui/core';
import { http } from 'utils';
import styles from './styles.module.scss';
function fetchSiteMonitoringData(siteId) {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/dashboards/monitoringproject', {
            zoneGroupId: siteId,
        });
        // fragment for debugging
        return result;
    });
}
function SiteMonitorWidget({ siteId }) {
    const { data = {} } = useQuery(['WIDGETS/SITE_MONITORING', siteId], () => fetchSiteMonitoringData(siteId), {
        enabled: Boolean(siteId),
        refetchInterval: 10000,
    });
    return (React.createElement("div", { className: styles.siteMonitoring },
        React.createElement(Typography, { component: "div" },
            "\u0417\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043D\u043E:",
            React.createElement("span", null, data === null || data === void 0 ? void 0 : data.workerGroupZoneTodayCount)),
        React.createElement(Typography, { component: "div" },
            "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u043E\u0432 \u043D\u0430 \u043E\u0431\u044A\u0435\u043A\u0442\u0435:",
            React.createElement("span", null, data === null || data === void 0 ? void 0 : data.workerGroupZoneNowCount)),
        React.createElement(Typography, { component: "div" },
            "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0442\u0435\u0445\u043D\u0438\u043A\u0438 \u043D\u0430 \u043E\u0431\u044A\u0435\u043A\u0442\u0435:",
            React.createElement("span", null, data === null || data === void 0 ? void 0 : data.machineryGroupZoneNowCount)),
        React.createElement(Typography, { component: "div" },
            "\u0422\u0440\u0435\u0432\u043E\u0433\u0438:",
            React.createElement("span", null, data === null || data === void 0 ? void 0 : data.alertGroupZoneTodayCount)),
        React.createElement(Typography, { component: "div" },
            "\u0421\u0443\u0431\u043F\u043E\u0434\u0440\u044F\u0434\u043D\u044B\u0435 \u043E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u0438:",
            React.createElement("span", null, data === null || data === void 0 ? void 0 : data.subContractorGroupZoneTodayCount)),
        React.createElement(Typography, { component: "div" },
            "\u0412\u044B\u0434\u0430\u043D\u043D\u044B\u0435 \u0443\u0441\u0442\u0440\u043E\u0439\u0441\u0442\u0432\u0430:",
            React.createElement("span", null, data === null || data === void 0 ? void 0 : data.deviceAssignCount)),
        React.createElement(Typography, { component: "div" },
            "\u041A\u043E\u043B-\u0432\u043E \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u043E\u0432 \u0441 \u0434\u043B\u0438\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u044C\u044E \u0441\u043C\u0435\u043D\u044B 10 \u043C\u0438\u043D\u0443\u0442 \u0438 \u043C\u0435\u043D\u0435\u0435:",
            React.createElement("span", null, data === null || data === void 0 ? void 0 : data.workerShiftLessTenCount))));
}
export default SiteMonitorWidget;

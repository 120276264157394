import React from 'react';
import { IconButton } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { ListItemClickAction } from 'components';
function ListItemMoreAction({ onClick, data }) {
    const handleClick = useCallback((event) => {
        if (onClick != null)
            onClick(event, data);
    }, [onClick, data]);
    return (React.createElement(ListItemClickAction, { onClick: handleClick },
        React.createElement(IconButton, null,
            React.createElement(MoreIcon, null))));
}
export default React.memo(ListItemMoreAction);

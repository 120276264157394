// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RnhHuBD0pOtXGTi54zon{display:flex}.RnhHuBD0pOtXGTi54zon .MuiFormControl-root{flex-grow:1}.ga79R2KhsI5LhN6saqJC,.ZSgHP5FQsxlrF30AbfyO{transition:transform 200ms}.ZSgHP5FQsxlrF30AbfyO{transform:rotate(180deg)}", "",{"version":3,"sources":["webpack://./src/templates/inputs/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,2CACE,WAAA,CAKJ,4CACE,0BAAA,CAGF,sBAEE,wBAAA","sourcesContent":[".order-select-root {\n  display: flex;\n  :global(.MuiFormControl-root) {\n    flex-grow: 1;\n  }\n  \n}\n\n.order-icon-asc {\n  transition: transform 200ms;\n}\n\n.order-icon-desc {\n  @extend .order-icon-asc;\n  transform: rotate(180deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"order-select-root": "RnhHuBD0pOtXGTi54zon",
	"orderSelectRoot": "RnhHuBD0pOtXGTi54zon",
	"order-icon-asc": "ga79R2KhsI5LhN6saqJC",
	"orderIconAsc": "ga79R2KhsI5LhN6saqJC",
	"order-icon-desc": "ZSgHP5FQsxlrF30AbfyO",
	"orderIconDesc": "ZSgHP5FQsxlrF30AbfyO"
};
export default ___CSS_LOADER_EXPORT___;

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Form, SubmitButton } from 'components';
import { ConstructionSiteSelect } from 'templates';
import { http, notify, refetchActiveQuery } from 'utils';
import WorkerSelectList from '../WorkerNotification/WorkerSelectList';
import styles from './styles.module.scss';
function requestBiometry({ values }) {
    const { workerIds, siteId } = values;
    return http.post('/api/kbi/watchpush/requestbiometricsbyworkers', { workerIds, siteId }, {
        onSuccess: () => notify.success('Биометрия успешно запрошена'),
        onError: () => notify.error('Не удалось запросить биометрию'),
    });
}
function BiometryRequestDialog({ open, onClose, onSuccess, siteId, queryName = 'ADMIN/BIOMETRY', }) {
    const { mutate } = useMutation([], requestBiometry, {
        onSuccess: () => {
            refetchActiveQuery(queryName);
            onClose();
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        },
    });
    return (React.createElement(Dialog, { open: open, onClose: onClose, className: styles.requestDialog },
        React.createElement(DialogTitle, null, "\u0417\u0430\u043F\u0440\u043E\u0441\u0438\u0442\u044C \u0431\u0438\u043E\u043C\u0435\u0442\u0440\u0438\u044E"),
        React.createElement(Form, { onSubmit: mutate, initialValues: siteId ? { siteId } : undefined },
            React.createElement(DialogContent, null,
                !siteId && (React.createElement(ConstructionSiteSelect, { fullWidth: true, required: true, name: "siteId", onlyWithSigurSystem: false })),
                React.createElement(WorkerSelectList, { name: "workerIds", constructionSiteFieldName: "siteId" })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: onClose }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"),
                React.createElement(SubmitButton, { className: "danger", variant: "contained", label: "\u0417\u0430\u043F\u0440\u043E\u0441\u0438\u0442\u044C" })))));
}
export default BiometryRequestDialog;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Route, Switch } from 'react-router-dom';
import { InputAdornment, IconButton, Box } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { history, store } from 'app';
import { Form, SubmitButton, TextField, Checkbox } from 'components';
import { useQueryParams } from 'hooks';
import { http, notify } from 'utils';
import About from './About';
import styles from './styles.module.scss';
function PasswordField(props) {
    const [showPassword, setShowPassword] = useState(false);
    return (React.createElement(TextField, Object.assign({ className: styles.signinInput, variant: "standard", name: "password", label: "\u041F\u0430\u0440\u043E\u043B\u044C", type: showPassword ? 'text' : 'password', InputProps: {
            endAdornment: (React.createElement(InputAdornment, { position: "end" },
                React.createElement(IconButton, { onClick: () => setShowPassword(value => !value), onMouseDown: event => event.preventDefault() }, showPassword ? React.createElement(VisibilityIcon, null) : React.createElement(VisibilityOffIcon, null)))),
        } }, props)));
}
function handleSubmit({ values: { username, password, rememberMe }, }) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = (_a = (yield http.post('/api/Auth/login', {
            login: username,
            password,
            rememberMe,
        }))) !== null && _a !== void 0 ? _a : {};
        if (result) {
            const { result: user } = yield http.get('/api/Auth/profile', { fields: "*,role[id,name,code]" });
            store.dispatch({ type: 'SIGNIN', user });
            localStorage.setItem('user', JSON.stringify(user));
        }
        else {
            notify.error('Неверное имя пользователя или пароль');
        }
    });
}
const handleForgotPassword = () => history.push('/restore-password');
function SignInForm() {
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { onSubmit: handleSubmit, initialValues: { rememberMe: true } },
            React.createElement(TextField, { className: styles.signinInput, variant: "standard", name: "username", label: "\u0418\u043C\u044F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F" }),
            React.createElement(PasswordField, null),
            React.createElement(Box, { marginTop: "16px", textAlign: "center" },
                React.createElement(Checkbox, { name: "rememberMe", label: "\u0417\u0430\u043F\u043E\u043C\u043D\u0438\u0442\u044C \u043C\u0435\u043D\u044F" })),
            React.createElement(SubmitButton, { className: styles.submitButton }, "\u0412\u043E\u0439\u0442\u0438")),
        React.createElement(Box, { marginTop: "24px", textAlign: "center" },
            React.createElement("div", { className: styles.forgotPasswordLink, onClick: handleForgotPassword }, "\u0417\u0430\u0431\u044B\u043B\u0438 \u043F\u0430\u0440\u043E\u043B\u044C?"))));
}
function handleRestorePassword({ values: { email } }) {
    return __awaiter(this, void 0, void 0, function* () {
        yield http.post(`/api/kbi/users/credentials?mail=${email}`, {}, {
            onSuccess: () => notify.success('Письмо для восстановления пароля отправлено на указанный адрес эл. почты'),
        });
    });
}
const handleBackToAuthorization = () => history.push('');
function RestorePasswordForm() {
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { onSubmit: handleRestorePassword },
            React.createElement(TextField, { className: styles.signinInput, variant: "standard", name: "email", label: "\u0410\u0434\u0440\u0435\u0441 \u044D\u043B. \u043F\u043E\u0447\u0442\u044B" }),
            React.createElement(SubmitButton, { className: styles.submitButton, label: "\u0412\u043E\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C" })),
        React.createElement(Box, { marginTop: "24px", textAlign: "center" },
            React.createElement("div", { className: styles.forgotPasswordLink, onClick: handleBackToAuthorization }, "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043A \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u0438"))));
}
function handleChangePassword(id, code, { values: { newPassword } }) {
    return __awaiter(this, void 0, void 0, function* () {
        yield http.put(`/api/kbi/users/changepassword?id=${id}&code=${code}&newPassword=${newPassword}`, {}, {
            onSuccess: () => {
                notify.success('Пароль успешно изменен');
                handleBackToAuthorization();
            },
        });
    });
}
function validateChangePasswordForm({ newPassword, repeatedPassword }) {
    const errors = {};
    if (newPassword !== repeatedPassword)
        errors.repeatedPassword = 'Пароли должны совпадать';
    return errors;
}
function validateNewPassword(value) {
    if (!value)
        return 'Необходимо указать новый пароль';
}
function validateRepeatedPassword(value) {
    if (!value)
        return 'Необходимо повторить';
}
function ChangePasswordForm() {
    const { id } = usePathParams();
    const { code } = useQueryParams();
    const { mutate } = useMutation(['CHANGE_PASSWORD'], (values) => handleChangePassword(id, code, values));
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { onSubmit: mutate, validate: validateChangePasswordForm },
            React.createElement(PasswordField, { name: "newPassword", label: "\u041D\u043E\u0432\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C", validate: validateNewPassword }),
            React.createElement(PasswordField, { name: "repeatedPassword", label: "\u041F\u043E\u0432\u0442\u043E\u0440\u0438\u0442\u0435 \u043F\u0430\u0440\u043E\u043B\u044C", validate: validateRepeatedPassword }),
            React.createElement(SubmitButton, { className: styles.submitButton, label: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C" })),
        React.createElement(Box, { marginTop: "24px", textAlign: "center" },
            React.createElement("div", { className: styles.forgotPasswordLink, onClick: handleBackToAuthorization }, "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043A \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u0438"))));
}
function SignInPage() {
    return (React.createElement("div", { className: styles.root },
        React.createElement("div", { className: styles.leftBar },
            React.createElement("div", { className: styles.appName }, process.env.REACT_APP_NAME),
            React.createElement("div", { className: styles.inputs },
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: "/restore-password", component: RestorePasswordForm }),
                    React.createElement(Route, { exact: true, path: "/user/userchangepassword/:id", component: ChangePasswordForm }),
                    React.createElement(Route, { component: SignInForm })))),
        React.createElement("div", { className: styles.sliderContainer },
            React.createElement(About, null))));
}
export default SignInPage;

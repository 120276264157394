import { combineReducers } from 'redux';
import deviceListPage from './deviceListPage';
import layout from './layout';
import dashboard from './dashboard';
import expand from './expand';
import select from './select';
import auth from './auth';
import print from './print';
const reducers = {
    auth,
    layout,
    deviceListPage,
    dashboard,
    expand,
    select,
    print,
};
const rootReducer = combineReducers(reducers);
export default rootReducer;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ListItem, MenuItem, ListItemIcon, ListItemText, List, Collapse, Menu, } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CascadingIcon from '@material-ui/icons/ArrowRight';
import useLayout from 'hooks/useLayout';
import closeMenu from './closeMenu';
import templates from './templates';
import styles from './styles.module.scss';
const CascadingMenuItem = React.forwardRef((_a, ref) => {
    var { children, template } = _a, props = __rest(_a, ["children", "template"]);
    const { icon, text } = Object.assign(Object.assign({}, templates[template]), props);
    const layout = useLayout();
    const menuKey = useSelector(({ layout: { menu } }) => menu.key, shallowEqual);
    const [anchor, setAnchor] = useState();
    const open = menuKey != null && Boolean(anchor);
    const handleClick = useCallback(event => {
        event.stopPropagation();
        setAnchor(_anchor => (_anchor != null ? null : event.currentTarget));
    }, []);
    const handleMenuClose = useCallback(() => {
        setAnchor(null);
        closeMenu();
    }, []);
    const cascadingIconRef = useRef();
    useLayoutEffect(() => {
        if (layout === 'mobile')
            return;
        const ulElement = cascadingIconRef.current.closest('ul');
        const paperEl = cascadingIconRef.current.closest('.MuiMenu-paper');
        ulElement.style.setProperty('width', 'calc(100% + 24px)');
        paperEl.style.setProperty('padding-right', '24px');
        return () => {
            ulElement.style.removeProperty('width');
            paperEl.style.removeProperty('padding-right');
        };
    }, [layout]);
    const ExpandIcon = open ? ExpandLessIcon : ExpandMoreIcon;
    if (layout === 'mobile')
        return (React.createElement(React.Fragment, null,
            React.createElement(ListItem, { button: true, onClick: handleClick },
                icon && React.createElement(ListItemIcon, null, icon),
                text && React.createElement(ListItemText, null, text),
                React.createElement(ExpandIcon, { className: styles.cascadingIcon })),
            React.createElement(Collapse, { in: open },
                React.createElement(List, { className: styles.nestedList, component: "div", disablePadding: true }, children))));
    return (React.createElement(MenuItem, { ref: ref, onClick: handleClick },
        icon && React.createElement(ListItemIcon, null, icon),
        text && React.createElement(ListItemText, null, text),
        React.createElement(CascadingIcon, { ref: cascadingIconRef, className: styles.cascadingIcon }),
        React.createElement(Menu, { open: open, anchorEl: anchor, onClose: handleMenuClose }, children)));
});
export default React.memo(CascadingMenuItem);

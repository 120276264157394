import React from 'react';
import { Checkbox } from '@material-ui/core';
import { ListItemClickAction } from 'components';
// type ListItemSelectActionMutableState = {
//   selected: boolean;
// };
function ListItemSelectAction({ selected, onChange, data }) {
    // const recent = useMutableState<ListItemSelectActionMutableState>();
    // recent.selected = selected;
    const inputRef = useRef();
    const handleClick = useCallback((event) => {
        if (onChange != null)
            onChange(event, !inputRef.current.checked, data);
    }, [onChange, data]);
    const handleChange = useCallback((event, checked) => {
        if (onChange != null)
            onChange(event, checked, data);
    }, [onChange, data]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Checkbox, { inputRef: inputRef, checked: selected, onChange: handleChange }),
        React.createElement(ListItemClickAction, { showOverlay: selected, onClick: handleClick })));
}
export default React.memo(ListItemSelectAction);

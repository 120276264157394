// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tbDkhrI2ZUTzuwfH7aFg{color:inherit;max-width:512px;transition:width 100ms,border-bottom-color 200ms;width:48px;cursor:auto}.tbDkhrI2ZUTzuwfH7aFg .MuiInputAdornment-positionEnd{margin-left:0px !important}.tbDkhrI2ZUTzuwfH7aFg input{width:0px}.tbDkhrI2ZUTzuwfH7aFg:before{border-bottom-color:rgba(0,0,0,0) !important}.zSszcsH5XcqXp_ghBe1r{transition:width 200ms,border-bottom-color 100ms;color:inherit;max-width:512px;width:100%}.zSszcsH5XcqXp_ghBe1r .MuiInputAdornment-positionEnd{margin-left:0px !important}", "",{"version":3,"sources":["webpack://./src/components/TopbarSearch/styles.module.scss"],"names":[],"mappings":"AAQA,sBAPE,aAAA,CACA,eAAA,CAQA,gDAAA,CACA,UAAA,CACA,WAAA,CATA,qDACE,0BAAA,CASF,4BACE,SAAA,CAEF,6BACE,4CAAA,CAIJ,sBACE,gDAAA,CArBA,aAAA,CACA,eAAA,CAsBA,UAAA,CArBA,qDACE,0BAAA","sourcesContent":["@mixin search-input {\n  color: inherit;\n  max-width: 512px;\n  & :global(.MuiInputAdornment-positionEnd) {\n    margin-left: 0px !important;\n  }\n}\n\n.search-input-collapsed {\n  @include search-input;\n  transition: width 100ms, border-bottom-color 200ms;\n  width: 48px;\n  cursor: auto;\n  & input {\n    width: 0px;\n  }\n  &:before {\n    border-bottom-color: transparent !important;\n  }\n}\n\n.search-input-expanded {\n  transition: width 200ms, border-bottom-color 100ms;\n  @include search-input;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-input-collapsed": "tbDkhrI2ZUTzuwfH7aFg",
	"searchInputCollapsed": "tbDkhrI2ZUTzuwfH7aFg",
	"search-input-expanded": "zSszcsH5XcqXp_ghBe1r",
	"searchInputExpanded": "zSszcsH5XcqXp_ghBe1r"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Chip, Tooltip, Grid } from '@material-ui/core';
import ConstructionSiteIcon from '@material-ui/icons/LocationOn';
import styles from './styles.module.scss';
const constructionSiteIcon = (React.createElement(Tooltip, { title: "\u041E\u0431\u044A\u0435\u043A\u0442" },
    React.createElement(ConstructionSiteIcon, { fontSize: "small" })));
let ConstructionSiteChip = ({ data }) => {
    if (data == null)
        return null;
    return React.createElement(Chip, { className: styles.chip, icon: constructionSiteIcon, label: data.name });
};
ConstructionSiteChip = React.memo(ConstructionSiteChip);
function ListItemConstructionSiteList({ data }) {
    if (data == null)
        return null;
    return (React.createElement(Grid, { container: true, className: styles.chipList, wrap: "wrap" }, data === null || data === void 0 ? void 0 : data.map((site) => (React.createElement(Grid, { item: true, key: site.id },
        React.createElement(ConstructionSiteChip, { data: site }))))));
}
export default ListItemConstructionSiteList;

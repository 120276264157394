// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vhh9WK_Yj9jKijNBAkzk{padding:24px;overflow:auto}.vhh9WK_Yj9jKijNBAkzk .footer{margin-top:8px}.MEZRuiucXM6Fk65uyfOS .header{padding:2px 2px 2px 16px}.MEZRuiucXM6Fk65uyfOS .inner{max-height:440px;overflow:auto;padding:16px 8px 8px 16px}.MEZRuiucXM6Fk65uyfOS .MuiDivider-root{margin-top:3px}", "",{"version":3,"sources":["webpack://./src/pages/admin/WorkerNotification/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA,CACA,8BACE,cAAA,CAKF,8BACE,wBAAA,CAEF,6BACE,gBAAA,CACA,aAAA,CACA,yBAAA,CAEF,uCACE,cAAA","sourcesContent":[".root {\n  padding: 24px;\n  overflow: auto;\n  :global(.footer) {\n    margin-top: 8px;\n  }\n}\n\n.worker-select-list {\n  :global(.header) {\n    padding: 2px 2px 2px 16px;\n  }\n  :global(.inner) {\n    max-height: 440px;\n    overflow: auto;\n    padding: 16px 8px 8px 16px;\n  }\n  :global(.MuiDivider-root) {\n    margin-top: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "vhh9WK_Yj9jKijNBAkzk",
	"worker-select-list": "MEZRuiucXM6Fk65uyfOS",
	"workerSelectList": "MEZRuiucXM6Fk65uyfOS"
};
export default ___CSS_LOADER_EXPORT___;

import ChartContainer from './ChartContainer';
function Chart({ type, data, options, canvasProps }) {
    const chartContainer = useMemo(() => new ChartContainer(), []);
    useEffect(() => {
        chartContainer.initialize(type);
        return chartContainer.destroy;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);
    useEffect(() => {
        chartContainer.update(options, data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, data, options]);
    return React.createElement("canvas", Object.assign({}, canvasProps, { ref: chartContainer.handleCanvasRefCallback }));
}
export default Chart;

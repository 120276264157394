import React from 'react';
import { SvgIcon } from '@material-ui/core';
function ReadMore(props) {
    return (React.createElement(SvgIcon, Object.assign({}, props),
        React.createElement("g", null,
            React.createElement("rect", { fill: "none", height: "24", width: "24" })),
        React.createElement("g", null,
            React.createElement("g", null,
                React.createElement("rect", { height: "2", width: "9", x: "13", y: "7" }),
                React.createElement("rect", { height: "2", width: "9", x: "13", y: "15" }),
                React.createElement("rect", { height: "2", width: "6", x: "16", y: "11" }),
                React.createElement("polygon", { points: "13,12 8,7 8,11 2,11 2,13 8,13 8,17" })))));
}
export default ReadMore;

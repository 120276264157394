import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
const templates = {
    delete: {
        className: 'danger',
        children: (React.createElement(React.Fragment, null,
            React.createElement(DeleteIcon, { className: "leading-icon" }),
            "\u0423\u0434\u0430\u043B\u0438\u0442\u044C")),
    },
    save: {
        children: (React.createElement(React.Fragment, null,
            React.createElement(SaveIcon, { className: "leading-icon" }),
            "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")),
    },
    accept: {
        children: (React.createElement(React.Fragment, null,
            React.createElement(SaveIcon, { className: "leading-icon" }),
            "\u041F\u0440\u0438\u043D\u044F\u0442\u044C")),
    },
    close: {
        children: (React.createElement(React.Fragment, null, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C")),
    },
};
export default templates;

import { Chart } from 'chart.js';
class ChartContainer {
    constructor() {
        this.canvasEl = null;
        this.chart = null;
        this.handleCanvasRefCallback = this.handleCanvasRefCallback.bind(this);
        this.destroy = this.destroy.bind(this);
    }
    handleCanvasRefCallback(node) {
        this.canvasEl = node;
    }
    initialize(type) {
        this.chart = new Chart(this.canvasEl, {
            type,
            data: {
                datasets: [],
            },
        });
    }
    destroy() {
        this.chart.destroy();
        this.chart = null;
    }
    update(options, data) {
        const { chart } = this;
        if (chart === null)
            return;
        if (chart.options !== options)
            chart.options = options;
        if (chart.data !== data)
            chart.data = data;
        chart.update();
    }
}
export default ChartContainer;

export const initialState = {
    expandList: new Map(),
};
const expandReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOOGLE_EXPANDED': {
            const { expandList } = state;
            const { name, key } = action;
            if (expandList.get(name) === key)
                expandList.set(name, null);
            else
                expandList.set(name, key);
            return state;
        }
        default:
            return state;
    }
};
export default expandReducer;

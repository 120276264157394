// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FedWybb0DDtlJsfTcuOg{position:absolute;z-index:10000}._2jViF6GmYK35l4ysfYH2{width:inherit;height:inherit}.map-container{cursor:grab}.map-container>div{cursor:inherit !important}", "",{"version":3,"sources":["webpack://./src/components/_Map/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,aAAA,CAKF,uBACE,aAAA,CACA,cAAA,CAGF,eACE,WAAA,CACA,mBACE,yBAAA","sourcesContent":[".tooltip-container {\n  position: absolute;\n  z-index: 10000;\n  // background-color: white;\n  // padding: 8px;\n}\n\n.root-node {\n  width: inherit;\n  height: inherit;\n}\n\n:global(.map-container) {\n  cursor: grab;\n  > div {\n    cursor: inherit !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip-container": "FedWybb0DDtlJsfTcuOg",
	"tooltipContainer": "FedWybb0DDtlJsfTcuOg",
	"root-node": "_2jViF6GmYK35l4ysfYH2",
	"rootNode": "_2jViF6GmYK35l4ysfYH2"
};
export default ___CSS_LOADER_EXPORT___;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { KeyboardTimePicker, TimePicker as MuiTimePicker, } from '@material-ui/pickers';
import { useField, shouldDisplayFieldError, fieldSubscription } from 'components/Form';
import { classes } from 'utils';
import useDerivedFieldProps from './useDerivedFieldProps';
import { makeDateFormatted } from './formatDate';
function TimePicker(_a) {
    var { name, validate, helperText, disableKeyboardInput, onChange } = _a, props = __rest(_a, ["name", "validate", "helperText", "disableKeyboardInput", "onChange"]);
    const _b = useDerivedFieldProps(props), { className } = _b, derivedProps = __rest(_b, ["className"]);
    const handleValidate = useCallback((value) => {
        if (value instanceof Date && isNaN(value.getTime()))
            return 'Неверный формат времени';
        return validate === null || validate === void 0 ? void 0 : validate(value);
    }, [validate]);
    const field = useField(name, { validate: handleValidate, subscription: fieldSubscription });
    const { change, blur, error } = field;
    let { value } = field;
    value = typeof value === 'string' ? new Date('0000-01-01T' + value) : value;
    makeDateFormatted(value, 'time');
    const handleChange = useCallback((nextValue) => {
        makeDateFormatted(nextValue, 'time');
        change(nextValue);
        onChange === null || onChange === void 0 ? void 0 : onChange(nextValue);
    }, [onChange]);
    const displayError = shouldDisplayFieldError(field);
    const TimePickerComponent = disableKeyboardInput ? MuiTimePicker : KeyboardTimePicker;
    return (React.createElement(TimePickerComponent, Object.assign({}, derivedProps, { value: value !== null && value !== void 0 ? value : null, onChange: handleChange, onBlur: blur, className: classes(className, 'time-picker'), error: displayError, helperText: displayError ? error : helperText })));
}
const MemoTimePicker = React.memo(TimePicker);
export default MemoTimePicker;

import React from 'react';
import { renderPortalContainer } from 'utils/contents';
import TopBar from './TopBar';
import LeftBar from './LeftBar';
import PrimaryActions from './PrimaryActions';
import styles from './styles.module.scss';
const rightbarContainer = renderPortalContainer('rightbar-container');
function Layout({ children }) {
    return (React.createElement("div", { className: styles.root },
        React.createElement(TopBar, null),
        React.createElement(LeftBar, null),
        React.createElement(PrimaryActions, null),
        React.createElement("div", { className: styles.content }, children),
        rightbarContainer));
}
export default Layout;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ItvwkP7DCcaG_x2uWr15{position:relative}.y_Ux_BzjsRutsDukksce{background-color:#fff;opacity:.36;position:absolute;width:100%;height:100%;z-index:100}", "",{"version":3,"sources":["webpack://./src/components/Overlay/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,qBAAA,CACA,WAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA","sourcesContent":[".root {\n  position: relative;\n}\n\n.overlay {\n  background-color: #fff;\n  opacity: 0.36;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 100;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ItvwkP7DCcaG_x2uWr15",
	"overlay": "y_Ux_BzjsRutsDukksce"
};
export default ___CSS_LOADER_EXPORT___;

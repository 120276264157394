import { getIn, ARRAY_ERROR } from 'final-form';
import { isSchema } from 'yup';
import { useMutableState } from 'hooks';
import { useForm } from './FormContext';
import { useName } from './NameContext';
import validateYupSchema from './validateYupSchema';
function useField(name, config) {
    var _a;
    const { subscription, validate } = config;
    name = useName(name);
    const form = useForm();
    const [fieldState, setFieldState] = useState();
    useEffect(() => {
        var _a;
        const validateFn = validate instanceof Function
            ? validate
            : isSchema(validate)
                ? (value) => validateYupSchema(validate, value)
                : undefined;
        const { index: indexOfDelimiter } = (_a = /\.|]/.exec(name)) !== null && _a !== void 0 ? _a : {};
        const endIndex = indexOfDelimiter ? indexOfDelimiter + (name[indexOfDelimiter] === ']' ? 1 : 0) : undefined;
        const validateFields = indexOfDelimiter ? [name.slice(0, endIndex)] : [];
        const fieldConfig = {
            getValidator: () => validateFn ? (value) => validateFn(value) : undefined,
            validateFields,
        };
        const unsubscribe = form.registerField(name, setFieldState, subscription, fieldConfig);
        return unsubscribe;
    }, [name, subscription, validate]);
    const current = useMutableState();
    if (current.change === undefined) {
        current.change = value => {
            var _a, _b;
            if (value instanceof Function) {
                const currentValue = (_b = (_a = form.getFieldState(current.name)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : getIn(form.getState().values, current.name);
                const nextValue = value(currentValue);
                form.change(current.name, nextValue);
            }
            else
                form.change(current.name, value);
        };
        current.blur = () => form.blur(current.name);
    }
    current.name = name;
    const { change, blur } = current;
    return Object.assign(Object.assign({}, ((_a = fieldState !== null && fieldState !== void 0 ? fieldState : form.getFieldState(name)) !== null && _a !== void 0 ? _a : { value: getIn(form.getState().values, name) })), { change,
        blur });
}
export default useField;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { socket } from 'app';
import 'app/yupLocale';
import AppProvider from 'app/AppProvider';
import ErrorBoundary from 'components/ErrorBoundary';
import { notify } from 'utils';
import Routing from 'routing';
import PrintTable from 'PrintTable';
import './styles.module.scss';
import 'components/MapElements/renderTooltip';
import { prefetchIndoorSiteListForSelect } from 'pages/beacons/IndoorSiteSelect';
prefetchIndoorSiteListForSelect();
const toastOptions = {
    position: 'bottom-left',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    rtl: false,
    newestOnTop: true,
};
const notifier = (React.createElement(AppProvider, null,
    React.createElement(ToastContainer, Object.assign({}, toastOptions))));
ReactDOM.render(notifier, document.getElementById('notifier-container'));
const main = (React.createElement(AppProvider, null,
    React.createElement(ErrorBoundary, null,
        React.createElement(Routing, null))));
ReactDOM.render(main, document.getElementById('main-container'));
const printTable = (React.createElement(AppProvider, null,
    React.createElement(PrintTable, null)));
ReactDOM.render(printTable, document.getElementById('print-container'));
const checkPermissionsSos = (id) => {
    const host = window.location.host;
    if (host === 'watch.exon-smart.com') {
        return id !== '47a09fd2-c199-4178-8944-22781394ed47';
    }
    return true;
};
const userId = (_a = JSON.parse(localStorage.getItem('user'))) === null || _a === void 0 ? void 0 : _a.id;
if (checkPermissionsSos(userId)) {
    socket.subscribe('ALERT_RECEIVED', () => notify.error('Получена новая тревога'));
}
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield navigator.serviceWorker.register('/sw.js');
            // eslint-disable-next-line no-console
            console.log('Service worker registered');
        }
        catch (error) {
            //
        }
    }));
}
// window.onerror = event => console.log(event);
// window.addEventListener('error', event => console.log(event));

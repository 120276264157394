function deepEqual(value1, value2) {
    if (value1 instanceof Object && value2 instanceof Object) {
        if (value1.constructor !== value2.constructor)
            return false;
        if (value1 instanceof Array) {
            if ((value1 === null || value1 === void 0 ? void 0 : value1.length) !== (value2 === null || value2 === void 0 ? void 0 : value2.length))
                return false;
            for (const index in value1) {
                if (!deepEqual(value1[index], value2[index]))
                    return false;
            }
            return true;
        }
        const keys1 = Object.keys(value1);
        const keys2 = Object.keys(value2);
        if ((keys1 === null || keys1 === void 0 ? void 0 : keys1.length) !== (keys2 === null || keys2 === void 0 ? void 0 : keys2.length))
            return false;
        for (const index in keys1) {
            const key = keys1[index];
            if (!keys1.includes(keys2[index]))
                return false;
            if (!deepEqual(value1[key], value2[key]))
                return false;
        }
        return true;
    }
    return value1 === value2;
}
export default deepEqual;

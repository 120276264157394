var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { http, notify } from 'utils';
const fields = `enterprise[enterprise[id,name]],zoneGroup[name,attributes[addr,status,timeZone,sigurObject]]`;
function fetchPageSiteList() {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/construction-site', { fields }, {
            onError: () => notify.error('Не удалось получить список объектов пользователя'),
        });
        return result;
    });
}
export { fetchPageSiteList };

import { Box } from '@material-ui/core';
import React from 'react';
import styles from './ProgressBar.module.scss';
const Filler = ({ percentage, color }) => (React.createElement(Box, { className: styles.filler, style: {
        width: `${percentage}%`,
        background: color,
    } }));
const ProgressBar = ({ percentage, color }) => (React.createElement(Box, { className: styles.progressBar },
    React.createElement(Filler, { percentage: percentage, color: color })));
export default React.memo(ProgressBar);

export const initialState = {
    selectedDevices: null,
};
const deviceListReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_SELECTED_DEVICES': {
            const { selectedDevices } = state;
            const { device } = action;
            if (device === undefined)
                return Object.assign(Object.assign({}, state), { selectedDevices: selectedDevices === null ? [] : selectedDevices });
            const currentDeviceIndex = selectedDevices.findIndex(({ id }) => id === device.id);
            if (currentDeviceIndex === -1)
                return Object.assign(Object.assign({}, state), { selectedDevices: [...selectedDevices, device] });
            return Object.assign(Object.assign({}, state), { selectedDevices: [
                    ...selectedDevices.slice(0, currentDeviceIndex),
                    ...selectedDevices.slice(currentDeviceIndex + 1),
                ] });
        }
        case 'CLEAR_SELECTED_DEVICES': {
            return Object.assign(Object.assign({}, state), { selectedDevices: null });
        }
        default:
            return state;
    }
};
export default deviceListReducer;

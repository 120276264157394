import { socket } from 'app';
import Layout from 'layout';
import NotFound from 'pages/notfound';
import SignInPage from 'pages/sigin';
import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { lazy } from 'utils';
import { fetchDeviceTypeList } from 'utils/device';
const Devices = lazy(() => import('./pages/devices'));
const Workers = lazy(() => import('./pages/workers'));
const Enterprises = lazy(() => import('./pages/enterprises'));
const Eventlog = lazy(() => import('./pages/event-log'));
const Analytics = lazy(() => import('./pages/analytics'));
const Alerts = lazy(() => import('./pages/alerts'));
const Admin = lazy(() => import('./pages/admin'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const Sites = lazy(() => import('./pages/sites'));
const Beacons = lazy(() => import('./pages/beacons'));
const SiteAnalytics = lazy(() => import('pages/turnstiles/SiteAnalytics'));
const TemperatureReport = lazy(() => import('pages/turnstiles/TemperatureReport'));
const Routing = () => {
    const user = useSelector(({ auth }) => auth, shallowEqual);
    useEffect(() => {
        if (user) {
            fetchDeviceTypeList();
            socket.connect(user.id);
            return socket.close;
        }
    }, [user]);
    if (!user)
        return React.createElement(SignInPage, null);
    return (React.createElement(Layout, null,
        React.createElement(Suspense, { fallback: React.createElement(React.Fragment, null) },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: "/", component: Dashboard }),
                React.createElement(Route, { path: "/enterprises", component: Enterprises }),
                React.createElement(Route, { path: "/sites", component: Sites }),
                React.createElement(Route, { path: "/workers", component: Workers }),
                React.createElement(Route, { path: "/devices", component: Devices }),
                React.createElement(Route, { path: "/beacons", component: Beacons }),
                React.createElement(Route, { exact: true, path: "/turnstiles/site-analytics", component: SiteAnalytics }),
                React.createElement(Route, { exact: true, path: "/turnstiles/temperature-report", component: TemperatureReport }),
                React.createElement(Route, { path: "/analytics", component: Analytics }),
                React.createElement(Route, { path: "/event-log", component: Eventlog }),
                React.createElement(Route, { path: "/admin", component: Admin }),
                React.createElement(Route, { path: "/alerts", component: Alerts }),
                React.createElement(Route, { component: NotFound })))));
};
export default Routing;

import { formSubscriptionItems } from 'final-form';
import { useForm } from './FormContext';
const allFormSubscriptions = formSubscriptionItems.reduce((result, key) => {
    result[key] = true;
    return result;
}, {});
function useFormState(subscription) {
    const form = useForm();
    const [formState, setFormState] = useState();
    useEffect(() => {
        const unsubscribe = form.subscribe(state => setFormState(state), subscription !== null && subscription !== void 0 ? subscription : allFormSubscriptions);
        return unsubscribe;
    }, [subscription]);
    return formState !== null && formState !== void 0 ? formState : form.getState();
}
export default useFormState;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { queryClient } from 'app';
import { Autocomplete } from 'components';
import { useFieldValues } from 'hooks';
import { http } from 'utils';
const toIndoorSiteSelectOption = ({ zoneGroup, enterprise, }) => ({
    id: zoneGroup.id,
    name: zoneGroup.name,
    enterpriseId: enterprise.enterprise.id,
});
const fields = 'enterprise[enterprise[id]],zoneGroup[id,name]';
function getIndoorSiteListForSelect() {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/construction-site', {
            fields,
        });
        const options = result.map(toIndoorSiteSelectOption);
        const indoorZoneList = yield Promise.all(options.map(opt => http.get('/api/indoor/mappictures/getbycsiteid', { сonstructionSiteId: opt.id })));
        return options.filter((opt, index) => indoorZoneList[index].length > 0);
    });
}
const getIndoorSiteOptionValue = ({ id }) => id;
const getIndoorSiteOptionLabel = ({ name }) => name;
function IndoorSiteSelect(_a) {
    var { enterpriseFieldName = 'enterpriseId', name, label, multiple, disabled, filterValues } = _a, props = __rest(_a, ["enterpriseFieldName", "name", "label", "multiple", "disabled", "filterValues"]);
    const enterpriseFieldValue = useFieldValues(enterpriseFieldName);
    const { data: constructionSiteList = [], isFetching } = useQuery('SELECT/INDOOR_SITE', getIndoorSiteListForSelect, { staleTime: 10 * 60 * 1000 });
    const options = useMemo(() => {
        if (enterpriseFieldValue == null || enterpriseFieldValue.length === 0)
            return constructionSiteList;
        return constructionSiteList.filter(({ enterpriseId }) => {
            if (enterpriseFieldValue instanceof Array)
                return enterpriseFieldValue.includes(enterpriseId);
            return enterpriseId === enterpriseFieldValue;
        });
    }, [constructionSiteList, enterpriseFieldValue]);
    return (React.createElement(Autocomplete, Object.assign({ name: name || 'siteId', multiple: multiple, label: label !== null && label !== void 0 ? label : (multiple ? 'Объекты' : 'Объект'), getOptionValue: getIndoorSiteOptionValue, getOptionLabel: getIndoorSiteOptionLabel, options: options, disabled: disabled !== null && disabled !== void 0 ? disabled : isFetching, filterValues: filterValues !== null && filterValues !== void 0 ? filterValues : !isFetching }, props)));
}
export default React.memo(IndoorSiteSelect);
export function prefetchIndoorSiteListForSelect() {
    queryClient.prefetchQuery('SELECT/INDOOR_SITE', getIndoorSiteListForSelect);
}

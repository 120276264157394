var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Select as MuiSelect, FormControl, InputLabel, FormHelperText, MenuItem, } from '@material-ui/core';
import { useField, shouldDisplayFieldError, fieldSubscription } from 'components/Form';
import isReactElement from 'utils/isReactElement';
import SelectContext from './SelectContext';
import toggleValueInArray from '../toggleValueInArray';
import useDerivedFieldProps from '../useDerivedFieldProps';
function renderValue(value, children) {
    const nodeList = React.Children.toArray(children);
    if (value instanceof Array) {
        const selectedItems = nodeList.filter(child => {
            if (!isReactElement(child))
                return false;
            return value.includes(child.props.value);
        });
        const disaplayedValues = selectedItems.map(({ props }) => props.label || props.children || props.value);
        return typeof disaplayedValues[0] === 'string' ? disaplayedValues.join(', ') : disaplayedValues;
    }
    const selectedItem = nodeList.find(child => {
        if (!isReactElement(child))
            return false;
        return child.props.value === value;
    });
    const selectedItemProps = (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.props) || {};
    return selectedItemProps.label || selectedItemProps.children || selectedItemProps.value;
}
function Select(_a) {
    var { name, validate, label, helperText, multiple, children, displayEmpty, style, fullWidth, outOfRange, variant = 'filled', required, margin = 'dense', onChange } = _a, props = __rest(_a, ["name", "validate", "label", "helperText", "multiple", "children", "displayEmpty", "style", "fullWidth", "outOfRange", "variant", "required", "margin", "onChange"]);
    const _b = useDerivedFieldProps(props), { disabled, className } = _b, derivedProps = __rest(_b, ["disabled", "className"]);
    const field = useField(name, { validate, subscription: fieldSubscription });
    const { value, change, blur, error } = field;
    const displayError = shouldDisplayFieldError(field);
    helperText = displayError ? error : helperText;
    const handleChange = useCallback((event, child) => {
        const option = child.props.value;
        let nextValue;
        if (multiple) {
            change((selectedOptions) => {
                nextValue = toggleValueInArray(selectedOptions, option);
                return nextValue;
            });
        }
        else {
            nextValue = option;
            change(nextValue);
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(nextValue);
    }, [multiple, onChange]);
    let displayedValue = renderValue(value, children);
    displayedValue = displayEmpty ? displayedValue : (value != null && value !== '' && displayedValue) || undefined;
    return (React.createElement(SelectContext.Provider, { value: { value, multiple } },
        React.createElement(FormControl, Object.assign({ error: displayError }, { style, fullWidth, margin, className }),
            React.createElement(InputLabel, Object.assign({}, { required, variant }, { shrink: Boolean(displayedValue) }), label),
            React.createElement(MuiSelect, Object.assign({}, Object.assign({ displayEmpty, multiple, variant, label, disabled, margin }, derivedProps), { renderValue: () => displayedValue, value: value !== null && value !== void 0 ? value : (multiple ? [] : ''), onChange: handleChange, onBlur: blur }),
                React.Children.map(children, child => {
                    if (!isReactElement(child))
                        return null;
                    return (React.createElement(MenuItem, { value: child.props.value, disabled: child.props.disabled }, child));
                }),
                outOfRange && React.createElement(MenuItem, { className: "non-displayed", value: value })),
            helperText && React.createElement(FormHelperText, null, helperText))));
}
const MemoSelect = React.memo(Select);
export default MemoSelect;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
class MapElementCollection {
    constructor(container, factory) {
        this.elements = new Map();
        this.container = container;
        this.factory = Object.assign({}, factory);
        if (this.factory.shouldDisplayElement == null)
            this.factory.shouldDisplayElement = () => true;
    }
    get parentLayer() {
        return this.container.layer;
    }
    getElementList() {
        return this.elements.values();
    }
    addElementLayerToMap(element) {
        if (!element.attached) {
            element.attached = true;
            this.parentLayer.addLayer(element.layer);
        }
    }
    removeElementLayerFromMap(element) {
        if (element.attached) {
            element.attached = false;
            this.parentLayer.removeLayer(element.layer);
        }
    }
    createElement(state, tooltip) {
        const { factory } = this;
        const layer = factory.createLayer(state);
        const element = { state, layer, attached: false, tooltip };
        layer.addEventListener('mousemove', (event) => {
            if (element.tooltip)
                this.container.tooltip.open(event.originalEvent, factory.entityName, element.tooltip);
        });
        if (factory.shouldDisplayElement(state))
            this.addElementLayerToMap(element);
        return element;
    }
    updateElement(element, nextState, tooltip) {
        const { factory } = this;
        const { state, layer } = element;
        const displayElement = factory.shouldDisplayElement(nextState);
        if (!displayElement)
            this.removeElementLayerFromMap(element);
        factory.updateLayer(layer, state, nextState);
        if (displayElement)
            this.addElementLayerToMap(element);
        element.state = nextState;
        element.tooltip = tooltip;
    }
    createOrUpdateElement(id, state, tooltip) {
        let element = this.elements.get(id);
        if (element === undefined) {
            element = this.createElement(state, tooltip);
            this.elements.set(id, element);
        }
        else
            this.updateElement(element, state, tooltip);
    }
    updateAll(nextEntries) {
        const { elements } = this;
        const newCollection = new Map();
        for (const entry of nextEntries) {
            const { id, tooltip } = entry, state = __rest(entry, ["id", "tooltip"]);
            let element = elements.get(id) || newCollection.get(id);
            if (element === undefined) {
                element = this.createElement(state, tooltip);
                newCollection.set(id, element);
            }
            else {
                this.updateElement(element, state, tooltip);
                newCollection.set(id, element);
                elements.delete(id);
            }
        }
        for (const element of elements.values()) {
            this.removeElementLayerFromMap(element);
        }
        this.elements = newCollection;
    }
    getElementIdByLayer(layer) {
        for (const [id, element] of this.elements) {
            if (element.layer === layer)
                return id;
        }
    }
}
export default MapElementCollection;

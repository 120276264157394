let currentCallback;
function handleFileInputCallback(input) {
    const files = Array.from(input.files || []);
    currentCallback === null || currentCallback === void 0 ? void 0 : currentCallback(files);
    input.value = '';
}
const fileDialog = document.createElement('input');
fileDialog.type = 'file';
fileDialog.multiple = true;
fileDialog.accept = 'image/png, image/jpeg, application/pdf, application/docx';
fileDialog.addEventListener('change', () => handleFileInputCallback(fileDialog));
export function openFileDialog(callback, options) {
    currentCallback = callback;
    const { multiple = false, accept = ['*'] } = options || {};
    Object.assign(fileDialog, {
        multiple,
        accept: accept.join(', '),
    });
    fileDialog.click();
}
const camera = document.createElement('input');
camera.type = 'file';
// @ts-ignore
camera.capture = true;
camera.accept = 'image/*';
camera.addEventListener('change', () => handleFileInputCallback(camera));
export function openCamera(callback) {
    currentCallback = callback;
    camera.click();
}

class QueryFields {
    constructor() {
        this.exprList = new Map();
    }
    setExpression(name, expression) {
        let expr = this.exprList.get(name);
        if (expr instanceof QueryFields)
            expr = {};
        this.exprList.set(name, Object.assign(Object.assign({}, expr), expression));
    }
    add(name) {
        this.exprList.set(name, undefined);
        return this;
    }
    setEqual(name, value, negate = false) {
        this.setExpression(name, {
            operator: negate ? '!=' : '=',
            value,
        });
        return this;
    }
    setLike(name, value) {
        this.setExpression(name, {
            operator: '=~',
            value,
        });
        return this;
    }
    expand(name, subfields) {
        this.exprList.set(name, subfields);
        return this;
    }
    orderBy(name, order = 'asc') {
        this.setExpression(name, { order });
        return this;
    }
    subfields(name) {
        const subfields = this.exprList.get(name);
        if (subfields instanceof QueryFields)
            return subfields;
        return undefined;
    }
    toString() {
        const { exprList } = this;
        const keys = [...exprList.keys()];
        return keys
            .map((name) => {
            const expr = exprList.get(name);
            if (expr === undefined)
                return name;
            if (expr instanceof QueryFields)
                return name + '[' + expr.toString() + ']';
            const { operator, value, order } = expr;
            return (name +
                (value === undefined
                    ? ''
                    : operator + (value instanceof Array ? value.join('|') : value)) +
                (order ? ':' + order : ''));
        })
            .join(',');
    }
}
export default QueryFields;

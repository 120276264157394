var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ListItem, Collapse, Grid, IconButton } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { store } from 'app';
import ListItemClickAction from './ListItemClickAction';
import styles from './styles.module.scss';
function ExpandableListItem(_a) {
    var { children, collapse, onClick, button, exclusive, expanded, onExpand } = _a, props = __rest(_a, ["children", "collapse", "onClick", "button", "exclusive", "expanded", "onExpand"]);
    const uniqueKey = useMemo(() => new Date().getTime(), []);
    const [open, setOpen] = useState(false);
    const exclusivelyOpenSelector = useCallback(({ expand }) => expand.expandList.get(exclusive) === uniqueKey, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exclusive]);
    const exclusivelyOpen = useSelector(exclusivelyOpenSelector);
    const handleClick = useCallback(event => {
        event.stopPropagation();
        if (onExpand)
            onExpand(event);
        else if (exclusive)
            store.dispatch({ type: 'TOOGLE_EXPANDED', name: exclusive, key: uniqueKey });
        else
            setOpen(_open => !_open);
        // @ts-ignore
        onClick === null || onClick === void 0 ? void 0 : onClick(event);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exclusive, onClick, onExpand]);
    const _open = expanded !== null && expanded !== void 0 ? expanded : (exclusive ? exclusivelyOpen : open);
    const ExpandIcon = _open ? ExpandLessIcon : ExpandMoreIcon;
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, Object.assign({}, props),
            React.createElement(Grid, { container: true, alignItems: "center" },
                React.createElement(ListItem, { component: "span", style: { flexGrow: 1, width: 'unset', padding: '0px', maxWidth: 'calc(100% - 64px)' } }, children),
                React.createElement("span", null,
                    React.createElement(ListItemClickAction, { showChildren: true, onClick: handleClick },
                        React.createElement(IconButton, null,
                            React.createElement(ExpandIcon, { className: styles.cascadingIcon })))))),
        React.createElement(Collapse, { in: _open }, collapse)));
}
export default React.memo(ExpandableListItem);

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useFormProps } from 'components/Form';
import { classes } from 'utils';
function useDerivedFieldProps(_a) {
    var { disabled, readOnly, className } = _a, props = __rest(_a, ["disabled", "readOnly", "className"]);
    const formProps = useFormProps();
    disabled = formProps.disabled || disabled;
    readOnly = formProps.readOnly || readOnly;
    className = classes(readOnly ? 'readonly' : undefined, className);
    return Object.assign(Object.assign({}, props), { disabled: disabled || readOnly, readOnly,
        className });
}
export default useDerivedFieldProps;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useLayout } from 'hooks';
import MapContainer from './MapContainer';
const MoscowCenterLatlng = [55.7558, 37.6173];
const defaultZoom = 13;
function BaseMap(_a) {
    var { markers = [], polygons, polylines, circleMarkers, center = MoscowCenterLatlng, zoom = defaultZoom, bounds, markerCluster, renderTooltip, disabled, drawingOptions, editableElements, onEditableElementsChange, onBlur, distortableImage, onDistortImage, corners, editableImage, editableImageZIndex, onClick, onDrawVertex, onEditVertex, maxZoom = 25, selectedWorker, progressiveTrack } = _a, props = __rest(_a, ["markers", "polygons", "polylines", "circleMarkers", "center", "zoom", "bounds", "markerCluster", "renderTooltip", "disabled", "drawingOptions", "editableElements", "onEditableElementsChange", "onBlur", "distortableImage", "onDistortImage", "corners", "editableImage", "editableImageZIndex", "onClick", "onDrawVertex", "onEditVertex", "maxZoom", "selectedWorker", "progressiveTrack"]);
    const current = useMemo(() => new MapContainer(), []);
    current.tooltip.renderFn = renderTooltip;
    useEffect(() => {
        current.maxZoom = maxZoom;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxZoom]);
    useEffect(() => {
        current.polygons.updateAll(polygons == null ? [] : polygons);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polygons]);
    useEffect(() => {
        const alertMarkers = [];
        const notAlertMarkers = [];
        if (selectedWorker) {
            if (selectedWorker === null || selectedWorker === void 0 ? void 0 : selectedWorker.sos) {
                alertMarkers.push(selectedWorker);
            }
            else {
                notAlertMarkers.push(selectedWorker);
            }
        }
        else {
            const { alertMarkers: aM, notAlertMarkers: notAM } = markers.reduce(({ alertMarkers, notAlertMarkers }, curr) => {
                if (curr.sos) {
                    return { alertMarkers: [...alertMarkers, curr], notAlertMarkers };
                }
                return { alertMarkers, notAlertMarkers: [...notAlertMarkers, curr] };
            }, { alertMarkers: [], notAlertMarkers: [] });
            alertMarkers.push(...aM);
            notAlertMarkers.push(...notAM);
        }
        current.markers.updateAll(markerCluster ? [] : notAlertMarkers);
        current.markerCluster.markers.updateAll(!markerCluster ? [] : notAlertMarkers);
        current.alertMarkers.updateAll(markerCluster ? [] : alertMarkers);
        current.alertMarkerCluster.markers.updateAll(!markerCluster ? [] : alertMarkers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markers, markerCluster, selectedWorker]);
    useEffect(() => {
        current.circleMarkers.updateAll(circleMarkers == null ? [] : circleMarkers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [circleMarkers]);
    useEffect(() => {
        if (!progressiveTrack) {
            current.polylines.updateAll(polylines == null ? [] : polylines);
            current.polylineDecorators.updateAll(polylines == null
                ? []
                : polylines
                    .filter(polyline => polyline.decoratorPatterns)
                    .map(({ id, decoratorPatterns }) => ({
                    id,
                    // @ts-expect-error
                    polylineLayer: current.polylines.elements.get(id).layer,
                    patterns: decoratorPatterns,
                })));
            current.progressivePolylines.updateAll([]);
        }
        else {
            current.polylines.updateAll([]);
            current.polylineDecorators.updateAll([]);
            current.progressivePolylines.updateAll(polylines == null ? [] : polylines);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progressiveTrack, polylines]);
    useEffect(() => {
        const { layer: map } = current;
        map.setView(center || map.getCenter(), zoom || map.getZoom());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [center === null || center === void 0 ? void 0 : center[0], center === null || center === void 0 ? void 0 : center[1], zoom]);
    useEffect(() => {
        var _a, _b;
        const { layer: map } = current;
        const maxZoom = 19;
        const options = { maxZoom };
        if (!selectedWorker) {
            switch (bounds) {
                case 'all': {
                    if ((markers === null || markers === void 0 ? void 0 : markers.length) > 0 || (polygons === null || polygons === void 0 ? void 0 : polygons.length) > 0)
                        map.fitBounds([
                            ...((_a = markers === null || markers === void 0 ? void 0 : markers.map(m => m.latlng)) !== null && _a !== void 0 ? _a : []),
                            ...((_b = polygons === null || polygons === void 0 ? void 0 : polygons.flatMap(m => m.latlngs)) !== null && _b !== void 0 ? _b : []),
                        ], options);
                    break;
                }
                case 'markers': {
                    if ((markers === null || markers === void 0 ? void 0 : markers.length) > 0)
                        map.fitBounds(markers === null || markers === void 0 ? void 0 : markers.map(m => m.latlng), options);
                    break;
                }
                case 'polygons': {
                    if ((polygons === null || polygons === void 0 ? void 0 : polygons.length) > 0)
                        map.fitBounds(polygons === null || polygons === void 0 ? void 0 : polygons.flatMap(m => m.latlngs), options);
                    break;
                }
                default: {
                    if (bounds != null && (bounds === null || bounds === void 0 ? void 0 : bounds.length) > 0)
                        map.fitBounds(bounds, options);
                }
            }
        }
        else {
            map.flyTo(selectedWorker.latlng);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bounds, selectedWorker]);
    useEffect(() => {
        current.disabled = disabled;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);
    useEffect(() => {
        current.drawingOptions = drawingOptions;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawingOptions]);
    current.onEditableElementsChange = onEditableElementsChange;
    useEffect(() => {
        current.editableElements = editableElements;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableElements]);
    const handleBlur = useCallback((event) => {
        if (!current.rootNode.contains(event.relatedTarget))
            onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onBlur]);
    current.onDistortImage = onDistortImage;
    useEffect(() => {
        current.editableImage = editableImage;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableImage]);
    useEffect(() => {
        current.corners = corners;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [corners]);
    useEffect(() => {
        current.distortableImage = distortableImage;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distortableImage]);
    useEffect(() => {
        current.editableImageZIndex = editableImageZIndex;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableImageZIndex]);
    current.onClick = onClick;
    current.onDrawVertex = onDrawVertex;
    current.onEditVertex = onEditVertex;
    const layout = useLayout();
    const style = layout === 'mobile' ? { height: '300px', width: '300px' } : { height: '500px', width: '500px' };
    return (React.createElement("div", Object.assign({ className: "map-container", style: style, onBlur: handleBlur }, props, { ref: current.appendMap })));
}
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.error(error, errorInfo);
        setTimeout(() => this.setState({ hasError: false }), 1000);
    }
    render() {
        if (this.state.hasError)
            return null;
        return this.props.children;
    }
}
function LeafletMap(props) {
    return (React.createElement(ErrorBoundary, null,
        React.createElement(BaseMap, Object.assign({}, props))));
}
export default LeafletMap;

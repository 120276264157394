import { TextField } from 'components';
const phoneNumberMask = [
    '+7 (',
    /\d/,
    /\d/,
    /\d/,
    ') ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
];
const phoneMaskedInputProps = {
    mask: phoneNumberMask,
};
function PhoneNumberField(props) {
    return (React.createElement(TextField, Object.assign({ name: "phoneNumber", label: "\u041D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430", maskedInputProps: phoneMaskedInputProps }, props)));
}
export default React.memo(PhoneNumberField);

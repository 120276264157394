// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (max-width: 960px){.pQIh6kKUhMR5VVAY3gne{margin-top:-4px}}@media only screen and (min-width: 960px){.pQIh6kKUhMR5VVAY3gne{padding-top:calc(var(--topbar-extra-height) - 4px);margin-bottom:calc(-1*var(--topbar-extra-height))}}", "",{"version":3,"sources":["webpack://./src/components/TopbarProgress/styles.module.scss"],"names":[],"mappings":"AAGE,0CADF,sBAEI,eAAA,CAAA,CAEF,0CAJF,sBAKI,kDAAA,CACA,iDAAA,CAAA","sourcesContent":["@import '../../theme.scss';\n\n.root {\n  @media only screen and (max-width: $mobile-width) {\n    margin-top: -4px;\n  }\n  @media only screen and (min-width: $mobile-width) {\n    padding-top: calc(var(--topbar-extra-height) - 4px);\n    margin-bottom: calc(-1 * var(--topbar-extra-height));\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "pQIh6kKUhMR5VVAY3gne"
};
export default ___CSS_LOADER_EXPORT___;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Autocomplete } from 'components';
import { useFieldValues } from 'hooks';
import { http, getActiveQueryData } from 'utils';
const toConstructionSiteSelectOption = ({ zoneGroup, enterprise, }) => ({
    id: zoneGroup.id,
    name: zoneGroup.name,
    enterpriseId: enterprise.enterprise.id,
    sigurObject: zoneGroup.attributes.sigurObject === 'True',
});
const getFields = (onlyWithSigurSystem) => `enterprise[enterprise[id]],zoneGroup[id,name:ASC,attributes[sigurObject${onlyWithSigurSystem == null ? '' : onlyWithSigurSystem ? '=True' : '=null|False'}]`;
// const fields = 'enterprise[enterprise[id]],zoneGroup[id,name]';
function getConstructionSiteListForSelect(onlyWithSigurSystem) {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield http.get('/api/kbi/construction-site', {
            fields: getFields(onlyWithSigurSystem),
        });
        if (!result)
            return [];
        return result.map(toConstructionSiteSelectOption);
    });
}
const getConstructionSiteOptionValue = ({ id }) => id;
const getConstructionSiteOptionLabel = ({ name }) => name;
function ConstructionSiteSelect(_a) {
    var { enterpriseFieldName = 'enterpriseId', name, label, multiple, disabled, filterValues, onlyWithSigurSystem = false, workerFieldName } = _a, props = __rest(_a, ["enterpriseFieldName", "name", "label", "multiple", "disabled", "filterValues", "onlyWithSigurSystem", "workerFieldName"]);
    const [enterpriseFieldValue, workerFieldValue] = useFieldValues(enterpriseFieldName, workerFieldName);
    const { data: constructionSiteList = [], isFetching } = useQuery(['SELECT/CONSTRUCTION_SITE', onlyWithSigurSystem], () => getConstructionSiteListForSelect(onlyWithSigurSystem), { cacheTime: 10 * 60 * 1000 });
    // const options = useMemo(() => {
    //   if (enterpriseFieldValue == null || enterpriseFieldValue.length === 0)
    //     return constructionSiteList;
    //   return constructionSiteList.filter(({ enterpriseId }) => {
    //     if (enterpriseFieldValue instanceof Array) return enterpriseFieldValue.includes(enterpriseId);
    //     return enterpriseId === enterpriseFieldValue;
    //   });
    // }, [constructionSiteList, enterpriseFieldValue]);
    const options = useMemo(() => {
        if ((enterpriseFieldValue == null || enterpriseFieldValue.length === 0) &&
            (workerFieldValue == null || workerFieldValue.length === 0))
            return constructionSiteList;
        if (enterpriseFieldValue) {
            return constructionSiteList.filter(({ enterpriseId }) => {
                if (enterpriseFieldValue instanceof Array)
                    return enterpriseFieldValue.includes(enterpriseId);
                return enterpriseId === enterpriseFieldValue;
            });
        }
        if (workerFieldValue) {
            const workers = getActiveQueryData('SELECT/WORKER');
            return constructionSiteList.filter(site => {
                var _a;
                if (workerFieldValue instanceof Array)
                    return workers
                        .filter(worker => workerFieldValue.includes(worker.id))
                        .some(({ constructionSiteIdList }) => constructionSiteIdList.includes(site.id));
                return (_a = workers
                    .find(worker => worker.id === workerFieldValue)) === null || _a === void 0 ? void 0 : _a.constructionSiteIdList.includes(site.id);
            });
        }
        return constructionSiteList;
    }, [constructionSiteList, enterpriseFieldValue, workerFieldValue]);
    return (React.createElement(Autocomplete, Object.assign({ name: name || 'constructionSiteId', multiple: multiple, label: label !== null && label !== void 0 ? label : (multiple ? 'Объекты' : 'Объект'), getOptionValue: getConstructionSiteOptionValue, getOptionLabel: getConstructionSiteOptionLabel, options: options, disabled: disabled !== null && disabled !== void 0 ? disabled : isFetching, filterValues: filterValues !== null && filterValues !== void 0 ? filterValues : !isFetching }, props)));
}
export default React.memo(ConstructionSiteSelect);

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { LinearProgress as MuiLinearProgress, } from '@material-ui/core';
import { classes } from 'utils';
function LinearProgress(_a) {
    var { display, className } = _a, props = __rest(_a, ["display", "className"]);
    return (React.createElement(MuiLinearProgress, Object.assign({ key: display ? 0 : 1, className: classes(className, display ? undefined : 'invisible') }, props)));
}
export default React.memo(LinearProgress);

import React from 'react';
import { ListItem } from '@material-ui/core';
import { useLayout } from 'hooks';
import styles from './styles.module.scss';
function ListItemClickAction({ onClick, children, showOverlay, showChildren, }) {
    const layout = useLayout();
    let child = children == null ? null : React.Children.only(children);
    if (layout === 'mobile')
        return (React.createElement(ListItem, { component: "span", selected: showOverlay, button: true, onClick: onClick, className: styles.clickAction, style: { alignItems: 'center' }, 
            // eslint-disable-next-line react/no-children-prop
            children: showChildren ? (React.createElement(React.Fragment, null,
                React.createElement("span", { style: { flexGrow: 1 } }),
                child)) : undefined }));
    child = React.isValidElement(child) ? React.cloneElement(child, { onClick }) : null;
    if (!showOverlay)
        return child;
    return (React.createElement(React.Fragment, null,
        child,
        React.createElement(ListItem, { component: "span", selected: true, className: styles.clickAction })));
}
export default ListItemClickAction;

function useDialogState(key) {
    const dialogSelector = useCallback(({ layout: { dialog } }) => (dialog.key === key ? dialog.data : null), [key]);
    const data = useSelector(dialogSelector, shallowEqual);
    if (data == null)
        return { open: false };
    return {
        open: true,
        data,
    };
}
export default useDialogState;

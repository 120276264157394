import { IconButton } from '@material-ui/core';
import OrderIcon from '@material-ui/icons/ArrowUpward';
import { Select } from 'components';
import { useField, fieldSubscription } from 'components/Form';
import styles from './styles.module.scss';
function OrderSelect({ children }) {
    const { value, change } = useField('order', { subscription: fieldSubscription });
    const desc = value === 'desc';
    const handleOrderButtonClick = () => {
        change(desc ? undefined : 'desc');
    };
    return (React.createElement("div", { className: styles.orderSelectRoot },
        React.createElement(Select, { name: "orderBy", label: 'Упорядочить по ' + (desc ? 'убыванию' : 'возрастанию') }, children),
        React.createElement("span", null,
            React.createElement(IconButton, { onClick: handleOrderButtonClick },
                React.createElement(OrderIcon, { className: desc ? styles.orderIconDesc : styles.orderIconAsc })))));
}
export default OrderSelect;
